import React from "react";
import query from "../../../../data-centre/Queries";
import Modal from "../../../common-utility/Modal";
import MessageModal from "../../../common-utility/MessageModal";
import { useState } from "react";
import LoaderUI from "../../../common-utility/LoaderUI";
import { Loader } from "semantic-ui-react";
import axios from "axios";

const DeleteProduct = (props: any) => {
  const [messageModalVisibility, setMessageModalVisibility] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(true);
  const [loader, setLoaderVisibility] = useState(false);

  const [message, setMessage] = useState<any>(
    <p>Sure to delete product {props.item.Variant_Name} ?</p>
  );

  console.log(message);
  const deleteHandler = async () => {
    setModalVisibility(false);
    setLoaderVisibility(true);

    query.Product.remove(props.item.Id)
      .then((response) => {
        if (response.statusText === "OK") {
          setMessage("Deleted successfully!!!");
        } else {
          setMessage("Some error occurred !!");
        }
        setLoaderVisibility(false);
        setMessageModalVisibility(true);
        return;
      })
      .catch((e) => {
        console.log("error message :", e);
      });
  };

  return (
    <>
      {messageModalVisibility && (
        <MessageModal close={props.close} message={message} />
      )}
      {modalVisibility && (
        <Modal>
          <div>
            {message}
            <div>
              <button onClick={deleteHandler}>Delete</button>
            </div>
            <div>
              <button onClick={props.cancel}>Cancel</button>
            </div>
          </div>
        </Modal>
      )}
      {loader && (
        <LoaderUI content="Deleting..." isDimmerActive={true} size="medium" />
      )}
    </>
  );
};

export default DeleteProduct;
