import { forwardRef } from "react";
import style from "./PrintDocument.module.css";
import {
  ItemAvailable,
  CustomerProfile,
  OrderDetail,
  OrderItemSummary,
  GstRules,
} from "../../../../Interface/Purchase";
import DateTime from "../../common-utility/DateTime";
import { isNumber } from "../../../../customer-app/utility/validation";

interface type {
  orderDetail: OrderDetail;
  orderItemList: OrderItemSummary[];
  userProfile: CustomerProfile;
  billingItems: ItemAvailable[];
  gstRules: GstRules[];
}

const PrintDocument = forwardRef(
  (
    { orderDetail, orderItemList, userProfile, billingItems, gstRules }: type,
    ref: any
  ) => {
    const header = [
      { name: "SNo.", width: "5%" },
      { name: "Product", width: "32%" },
      { name: "Qty", width: "11%" },
      { name: "MRP.", width: "7%" },
      { name: "Tax Val.", width: "8%" },
      { name: "Tax", width: "8%" },
      { name: "Rate", width: "10%" },
      { name: "Disc(%)", width: "11%" },
      { name: "Amt.", width: "8%" },
    ];

    return (
      <>
        {orderDetail === undefined ||
        orderItemList === undefined ||
        userProfile === undefined ||
        billingItems === undefined ? (
          ""
        ) : (
          <div ref={ref} className={style["wrapper"]}>
            <p>SS ENT.</p>
            <span>Kota-Rajasthan</span>
            <span>Mob. 7728801850</span>
            <span>INVOICE</span>
            <div className={style["customerinfo-wrapper"]}>
              <div className={style.customerinfo1}>
                <div>
                  <span>Invoice No.</span>
                  <span>{orderDetail.Order_id}</span>
                </div>
                <div>
                  <span>Cust. Id</span>
                  <span>{orderDetail.Customer_id}</span>
                </div>
                <div>
                  <span>Name</span>
                  <span>
                    {userProfile.First_name + " " + userProfile.Last_name}
                  </span>
                </div>
                <div>
                  <span>Mobile</span>
                  <span>{userProfile._mobile}</span>
                </div>
              </div>
              <div className={style.customerinfo2}>
                <div>
                  <span>Cashier</span>
                  <span>855T</span>
                </div>
                <div>
                  <span>Date</span>
                  <span>
                    {DateTime({
                      required: "date",
                      value: orderDetail.DeliveryRequest_date,
                    })}
                  </span>
                </div>
                <div>
                  <span>Time</span>
                  <span>
                    {DateTime({
                      required: "time",
                      value: orderDetail.DeliveryRequest_date,
                    })}
                  </span>
                </div>
              </div>
            </div>

            <div className={style.header}>
              {header.map((item) => (
                <div key={item.name} style={{ width: item.width }}>
                  {item.name}
                </div>
              ))}
            </div>
            {orderItemList.map((item: OrderItemSummary, index: number) => (
              <div className={style.itemsList} key={item.Item_sequence}>
                <div style={{ width: header[0].width }}>
                  {item.Item_sequence}
                </div>
                <div style={{ width: header[1].width }}>
                  {billingItems[index].Variant_name}
                </div>
                <div style={{ width: header[2].width }}>
                  {item.Qty_order + " " + item.Qty_metric}
                </div>
                <div style={{ width: header[3].width }}>{item.Unit_mrp}</div>
                <div style={{ width: header[4].width }}>
                  {item.Taxable_amt === undefined || item.Taxable_amt === null
                    ? "-"
                    : item.Taxable_amt}
                </div>
                <div style={{ width: header[5].width }}>
                  {(
                    gstRules.find(
                      (x) => x.Gst_id === billingItems[index].SndCgst_code
                    ) as any
                  )._tax.split("%").length > 1
                    ? gstRules.find(
                        (x) => x.Gst_id === billingItems[index].SndCgst_code
                      )?._tax
                    : "-"}
                </div>
                <div style={{ width: header[6].width }}>
                  {(item.UnitPrice_manual === null
                    ? item.Unit_price
                    : item.UnitPrice_manual) +
                    "/" +
                    item.Qty_metric}
                </div>
                <div
                  style={{
                    width: header[7].width,
                    textAlign:
                      isNumber(item.Unit_price) &&
                      item.Unit_mrp !== undefined &&
                      item.Unit_mrp !== 0
                        ? "left"
                        : "center",
                  }}
                >
                  {isNumber(item.Unit_price) &&
                  item.Unit_mrp !== undefined &&
                  item.Unit_mrp !== 0
                    ? (
                        (((item.Unit_mrp as number) -
                          Number(
                            item.UnitPrice_manual === null
                              ? item.Unit_price
                              : item.UnitPrice_manual
                          )) *
                          100) /
                        item.Unit_mrp
                      ).toFixed(2) + "%"
                    : "-"}
                </div>
                <div style={{ width: header[8].width }}>
                  {orderDetail.Order_id !== undefined && item.Sub_total}
                </div>
              </div>
            ))}

            <div className={style["parent-amounttdetails"]}>
              <div className={style.amounttdetails}>
                <div>
                  <div>Total</div>
                  <div>
                    {orderDetail.Order_id !== undefined &&
                      (isNumber(
                        orderDetail.OrderTotal_amt - orderDetail.Delivery_charge
                      )
                        ? Number(
                            orderItemList
                              .reduce(
                                (sum, x) =>
                                  (sum = (x.Sub_total as number) + sum),
                                0
                              )
                              .toFixed(2)
                          ) - orderDetail.Delivery_charge
                        : 0)}
                  </div>
                </div>
                <div>
                  <div>Other Discount</div>
                  <div>{orderDetail.Order_id !== undefined && 0}</div>
                </div>
                <div>
                  <div>Delivery Charge</div>
                  <div>
                    {orderDetail.Order_id !== undefined &&
                      orderDetail.Delivery_charge}
                  </div>
                </div>
                <div>
                  <div>Net Payable</div>
                  <div>
                    {orderDetail.Order_id !== undefined &&
                      orderDetail.OrderTotal_amt}
                  </div>
                </div>
              </div>
            </div>

            <div className={style.footer}>****THANK YOU****</div>
          </div>
        )}
      </>
    );
  }
);
export default PrintDocument;
