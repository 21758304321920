import { useState } from "react";
import Button from "../../../../../common-elements/Button";
import LoaderUI from "../../../common-utility/LoaderUI";
import MessageModal from "../../../common-utility/MessageModal";
import Modal from "../../../common-utility/Modal";
import AddForm from "../../upper-compnents/AddForm";
import query from "../../../../data-centre/Queries";
import { purchase } from "../../../../../common-objects/Purchase";
import { vendor } from "../../../../../common-objects/Vendor";
import ModalPopup from "../../../../../customer-app/components/modal/ModalPopup";

function AddPurchase() {
  const [isAddFormVisibile, setAddFormVisibilty] = useState<boolean>(false);
  const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(false);
  const [loaderMessage, setLoaderMessage] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [isMessageModalVisibility, setMessageModalVisibility] =
    useState<boolean>(false);

  const addFormHandler = (check: any) => {
    if (check === "show") setAddFormVisibilty(true);
    else setAddFormVisibilty(false);
  };

  const loaderHandler = (content: string, visibility: boolean) => {
    setLoaderMessage(content);
    setLoaderVisibility(visibility);
  };

  const messageModalHandler = (content: string, visibility: boolean) => {
    setModalMessage(content);
    setMessageModalVisibility(visibility);
    if (visibility === true) {
      setAddFormVisibilty(false);
    } else {
      setAddFormVisibilty(true);
    }
  };
  let rules: any = [];
  const submitHandler = (event: any, selectRules: any) => {
    event.preventDefault();
    purchase.Product_id = event.target["variant_dropdown"].value;
    purchase.Quantity = parseFloat(event.target["quantity"].value);

    purchase.Unit_mrp = parseFloat(event.target["unit_mrp"].value);
    purchase.Unit_cp = parseFloat(event.target["unit_cp"].value);
    purchase.Purchase_amount = parseFloat(event.target["purchase_amt"].value);
    purchase.Remark = event.target["textarea"].value;
    purchase.Expiry = event.target["expiry_date"].value;
    if (purchase.Expiry === "") {
      purchase.Expiry = null;
    }
    purchase.Gst = parseFloat(event.target["gst"].value);
    purchase.Other_tax = parseFloat(event.target["other_tax"].value);
    purchase.Batch_no = event.target["batch_no"].value;
    purchase.TaxOn_status = event.target["tax_on"].value;
    purchase.ItemSearch_mode = event.target["itemsearchmode"].value;
    purchase._Code =
      event.target["itemsearchmode"].value === "Barcode" 
        ? event.target["barcode"].value
        : "";
    vendor.Vendor_name = event.target["vendor_name"].value;
    vendor.Purchase_status = event.target["purchase_status"].value;
    vendor.Payment_status = event.target["payment_status"].value;
    vendor.Bill_no = event.target["bill_no"].value;
    vendor.Bill_date = event.target["purchase_date"].value;
    if (vendor.Bill_date === "") {
      vendor.Bill_date = "1900-01-01";
    }
    vendor.Payment_date = event.target["payment_date"].value;
    if (vendor.Payment_date === "") {
      vendor.Payment_date = "1900-01-01";
    }

    let rulesIndex = selectRules[1].FinalMetric === "" ? 0 : 1;
    purchase.Metric_conversion = selectRules[rulesIndex].Rules;
    purchase.Metric = selectRules[rulesIndex].FinalMetric;
    rules = selectRules[rulesIndex].Rules.split(",");
    if (
      selectRules[rulesIndex].FinalMetric !==
      event.target["metric_dropdown"].value
    ) {
      if (rules[3] === event.target["metric_dropdown"].value) {
        purchase.Quantity = Number(
          (Number(event.target["quantity"].value) / Number(rules[2])).toFixed(3)
        );
        
      } else if (rules[1] === event.target["metric_dropdown"].value) {
        purchase.Quantity =
          Number(event.target["quantity"].value) * Number(rules[2]);
      }
      purchase.Unit_cp = purchase.Purchase_amount / purchase.Quantity;
    }

    loaderHandler("Adding...", true);
    query.Purchase.add({ purchase, vendor })
      .then((response) => {
        loaderHandler("", false);
        if (response.statusText === "OK") {
          messageModalHandler("Purchase Added Successfully..", true);
          purchase.Metric_conversion = "";
        } else {
          messageModalHandler("Some error occurred..", true);
        }
      })
      .catch((e) => {
        loaderHandler("", false);
        messageModalHandler("Error occurred is" + e, true);
      });
  };

  return (
    <>
      <section>
        {isLoaderVisible && (
          <LoaderUI
            isDimmerActive={true}
            size="medium"
            content={loaderMessage}
          />
        )}
        {isMessageModalVisibility && (
          <MessageModal
            close={() => messageModalHandler("", false)}
            message={modalMessage}
          />
        )}
        {isAddFormVisibile && (
          <ModalPopup>
            <AddForm submitHandler={submitHandler} onClick={addFormHandler} />
          </ModalPopup>
        )}
        <div style={{ textAlign: "right" }}>
          <Button
            type="buttonControl1"
            data="show"
            name="Add Purchase"
            onClick={addFormHandler}
          />
        </div>
      </section>
    </>
  );
}

export default AddPurchase;
