import { useEffect, useState } from "react";
import query from "../../../data-centre/Queries";
import LoaderUI from "../../common-utility/LoaderUI";
import MessageModal from "../../common-utility/MessageModal";
import Modal from "../../common-utility/Modal";

function DeletePurchase(props: any) {
  const { Id } = props;
  const [content, setContent] = useState<string>();
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
  const [messageVisibility, setMessageVisibility] = useState<boolean>(false);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [checkpoint, setCheckpoint] = useState<boolean>(false);

  useEffect(() => {
    setModalVisibility(true);
  }, []);

  const deleteHandler = async () => {
    setModalVisibility(false);
    setContent("Deleting...");
    setLoaderVisibility(true);
    await query.Purchase.remove(Id)
      .then((response) => {
        console.log("status " + response.statusText + " data " + response.data);
        if (response.statusText === "OK") {
          if (response.data === "Deleted") {
            setContent(Id + " Deleted Successfully!!!");
            setCheckpoint(true);
          } else if (response.data === "NotFound") {
            setContent("Row not found !!!");
          }
          setLoaderVisibility(false);
          setMessageVisibility(true);
        }
      })
      .catch((error) => {
        setContent("Error occurred " + error);
        setLoaderVisibility(false);
        setMessageVisibility(true);
      });
  };

  return (
    <>
      {loaderVisibility && (
        <LoaderUI content={content} size="medium" isDimmerActive={true} />
      )}

      {modalVisibility && (
        <Modal>
          <p>Are you sure to delete purchase with {Id} ?</p>
          <button onClick={deleteHandler}>Ok</button>
          <button
            onClick={() => {
              props.action("", "");
            }}
          >
            Cancel
          </button>
        </Modal>
      )}

      {messageVisibility && (
        <MessageModal
          message={content}
          close={() => {
            console.log("checkpoint= " + checkpoint);
            if (checkpoint === true) {
              props.action("postDel", "");
            } else {
              props.action("", "");
            }
          }}
        />
      )}
    </>
  );
}

export default DeletePurchase;
