import React, { useContext, useState } from "react";
import {
  Route,
  Routes,
  redirect,
  useNavigate,
  useParams,
} from "react-router-dom";
import Login from "./components/management/Login";
import UserActivity from "./components/management/UserActivity";
import CustomerActivity from "./customer-app/CustomerAcitivity";
import LoginProvider from "./components/data-centre/LoginProvider";


function App() {
  const [isUserActivity, setUserActivity] = useState<boolean>(false);
  const [isLogin, setLogin] = useState<boolean>(false);

  const navigate = useNavigate();
  const usertypeHandler = (isValidUser: boolean) => {
    if (isValidUser) {
      setLogin(isValidUser);
      navigate("/panel");
    }
  };

  return (
    <>
      <LoginProvider>
        <Routes>
          <Route
            path="/login"
            element={<Login usertypeHandler={usertypeHandler} />}
          />
          <Route path="/panel" element={<UserActivity isLogin={isLogin} />} />
          <Route path="/viyan" element={<UserActivity isLogin={true} />} />
          <Route path="/" element={<CustomerActivity />}></Route>
        </Routes>
      </LoginProvider>
      {/* {!isUserActivity && <Login usertypeHandler={usertypeHandler} />} */}
      {/* {isUserActivity && <UserActivity />} */}
    </>
  );
}

export default App;
