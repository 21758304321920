import { purchase } from "../../../../../common-objects/Purchase";

function UpdatePurchase(props: any) {
  const { data } = props;
  console.log("UP_entryAt: " + purchase.Entry_at);
  const submitHandler = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <table>
          <tbody>
            <tr>
              <th>Purchase Id</th>
              <td>{data.Id}</td>
            </tr>
            <tr>
              <th>Product</th>
              <td>{data.Product}</td>
            </tr>
            <tr>
              <th>Variant</th>
              <td>{data.Variant}</td>
            </tr>
            <tr>
              <th>Quantity</th>
              <td>
                <input
                  name="quantity"
                  required={true}
                  defaultValue={data.Quantity}
                />
              </td>
            </tr>
            <tr>
              <th>Metric</th>
              <td>
                <input
                  name="metric"
                  required={true}
                  defaultValue={data.Metric}
                />
              </td>
            </tr>
            <tr>
              <th>Unit MRP</th>
              <td>
                <input
                  name="mrp"
                  required={true}
                  defaultValue={data.Unit_mrp}
                />{" "}
                ₹
              </td>
            </tr>
            <tr>
              <th>Unit CP</th>
              <td>
                <input name="mrp" required={true} defaultValue={data.Unit_cp} />{" "}
                ₹
              </td>
            </tr>
            <tr>
              <th>Purchase Amount</th>
              <td>
                <input required={true} defaultValue={data.Purchase_amount} /> ₹
              </td>
            </tr>
            <tr>
              <th>Entry At</th>
              <td>{data.Entry_at}</td>
            </tr>
            <tr>
              <th>End At</th>
              <td>
                <input name="end_at" defaultValue={data.End_at} />
              </td>
            </tr>
            <tr>
              <th>Expiry</th>
              <td>
                <input name="expiry" defaultValue={data.Expiry} />
              </td>
            </tr>
            <tr>
              <th>Brand</th>
              <td>
                <input name="brand" defaultValue={data.Brand} />
              </td>
            </tr>
            <tr>
              <th>Gst</th>
              <td>
                <input required={true} name="gst" defaultValue={data.Gst} />
              </td>
            </tr>
            <tr>
              <th>Other Tax</th>
              <td>
                <input
                  required={true}
                  name="other_tax"
                  defaultValue={data.Other_tax}
                />
              </td>
            </tr>
            <tr>
              <th>Remark</th>
              <td>
                <textarea name="remark" defaultValue={data.Remark} />
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit">Update</button>
        <button onClick={() => props.action("close", "")} type="button">
          Close
        </button>
      </form>
    </>
  );
}

export default UpdatePurchase;
