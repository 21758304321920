import style from "./ConfirmationModal.module.css";
import { MdOutlineClose } from "react-icons/md";

function Backdrop(props: any) {
  return (
    <>
      <div className={style.backdrop} />
    </>
  );
}

function OverLay({ children, onClose, Ok }: any) {
  return (
    <>
      <div className={style.confirmmodal}>
        <div className={style.content}>
          {children}
          <div className={style["button-wrapper"]}>
            <button type="button" onClick={Ok}>
              Ok
            </button>
          </div>
          <MdOutlineClose
            className={style.closebutton}
            onClick={() => onClose(undefined)}
          />
        </div>
      </div>
    </>
  );
}

function ConfirmationModal({ children, onClose, Ok }: any) {
  return (
    <>
      <Backdrop />
      <OverLay onClose={onClose} Ok={Ok}>
        {children}
      </OverLay>
    </>
  );
}

export default ConfirmationModal;
