import { forwardRef } from "react";
import style from "./LabelInput.module.css";

interface properties {
  labelName: string;
  type: string;
  required: boolean;
  name: string;
  styles?: React.CSSProperties;
  onChange?: (e: any) => any;
  onBlur?: () => void;
  onKeyPress?: (e: any) => void;
  onClick?: () => void;
  onKeyDown?: (e: any) => void;
  max?: number;
  min?: number;
  disabled?: boolean;

  onInvalid?: React.FormEventHandler<HTMLInputElement>;
}

const LabelInput = forwardRef((props: properties, ref: any) => {
  const { labelName, styles, ...prop } = props;
  return (
    <div className={style["labelinput-wrapper"]}>
      <span
        className={style.names}
        // style={styles}
      >
        {labelName}
      </span>
      <input ref={ref} className={style.inputs} {...prop} />
    </div>
  );
});

export default LabelInput;
