import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import SubcategoryProvider from "../../data-centre/SubcategoryProvider";
import Modal from "../common-utility/Modal";
import ManageCategory from "./category-actions/ManageCategory";
import ManageSubCategory from "./category-actions/ManageSubCategory";
import Temp from "./category-actions/Temp";
import style from "./Category.module.css";
import Popup from "./components/Popup";

function Category(props: any) {
  const subMenu: number = props.menuType;
  const [isCategory, setCategory] = useState<boolean>(false);
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [popUpValue, setPopUpValue] = useState<any>();

  useEffect(() => {
    if (subMenu === 1) {
      setCategory(true);
    } else if (subMenu === 2) {
      setCategory(false);
    }
  }, [subMenu]);

  const modalHandler = (x: boolean, y: any) => {
    setPopUpValue(y);
    setModalVisibility(x);
  };

  return (
    <>
      {isModalVisible && <Popup value={popUpValue} visibility={modalHandler} />}
      <div className={style.container}>
        <SubcategoryProvider>
          {isCategory && <ManageCategory />}

          {
            // !isCategory && <Temp />

            !isCategory && <ManageSubCategory visibility={modalHandler} />
          }
        </SubcategoryProvider>
      </div>
    </>
  );
}

export default Category;
