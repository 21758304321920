import { memo, useEffect, useState } from "react";
import style from "./ManageSubCategory.module.css";
import AddSubCategory from "../components/AddSubCategory";
import DeleteSubCategory from "../components/DeleteSubCategory";
import MessageModal from "../../common-utility/MessageModal";
import LoaderUI from "../../common-utility/LoaderUI";
import EditSubCategory from "../components/EditSubCategory";
import { useContext } from "react";
import SubcategoryContext from "../../../data-centre/Subcategory-context";
import query from "../../../data-centre/Queries";

function ManageSubCategory(props: any) {
  const [productCategory, setProductCategory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isDeletePopup, setDeletePopup] = useState<boolean>(false);
  const [isEditPopup, setEditPopup] = useState<boolean>(false);
  const [isMessagePopup, setMessagePopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [object, setObject] = useState<any>();
  const [addcategory, setAddcategory] = useState<boolean>(false);

  //console.log("initial array ", productCategory);

  //const Uri = "http://localhost:5000/ProductSubCategory";

  useEffect(() => {
    getData();
    //setAddcategory(true);
    return function cleanup() {
      console.log("empty");
      setProductCategory([]);
    };
  }, []);

  async function getData() {
    await query.SubCategory.list()
      .then((response) => {
        console.log("subcategory response =", response.statusText);
        setProductCategory(response.data);
        setLoading(true);
      })
      .catch((error) => {
        getData();
      });
  }

  const addHandler = () => {
    console.log("call handler");
    getData();
  };
  const closeEditDeleteHandler = (
    type: string,
    action: string,
    message: string
  ) => {
    if (action === "ok") {
      // console.log("message is: " + message);
      setMessage(message);
      setMessagePopup(true);
    }
    if (type === "deletePopup") {
      setDeletePopup(false);
    } else if (type === "editPopup") {
      setEditPopup(false);
    }
  };

  const closeMessageHandler = () => {
    setMessagePopup(false);
    getData();
    setMessage("");
  };

  const popupVisible = (x: string, y: boolean, rowdata: any) => {
    if (x === "editPopup") {
      setObject(rowdata);
      setEditPopup(y);
    } else if (x === "deletePopup") {
      setObject(rowdata);
      setDeletePopup(y);
    }
  };

  // const passModalValue = (x: any, y: any) => {
  //   props.visibility(x, y);
  // };

  const loaderObj = { content: "loading", type: "dimmer" };

  return (
    <>
      <div>MANAGE SUB CATEGORY</div>
      <AddSubCategory key={"nochange"} value="nochange" add={addHandler} />

      {isDeletePopup && (
        <DeleteSubCategory close={closeEditDeleteHandler} value={object} />
      )}

      {isEditPopup && (
        <EditSubCategory close={closeEditDeleteHandler} value={object} />
      )}

      {isMessagePopup && (
        <MessageModal message={message} close={closeMessageHandler} />
      )}
      <p>Subcategory List</p>
      {isLoading && (
        <table className={style["category-table"]}>
          <thead>
            <tr className={style["thead-row"]}>
              <th className={style["thead-name"]}>Category Name</th>
              <th className={style["thead-Id"]}>Category Id</th>
              <th className={style["thead-Image"]}>Subcategory Id</th>
              <th className={style["thead-name"]}>Subcategory Name</th>
              <th className={style["thead-nameHindi"]}>
                Subcategory Name(Hindi)
              </th>
              <th className={style["thead-Image"]}>Image</th>
              <th className={style["thead-edit"]}></th>
              <th className={style["thead-delete"]}></th>
            </tr>
          </thead>
          <tbody>
            {productCategory.map((rows: any) => (
              <tr className={style["tbody-row"]} key={rows.Sub_Id}>
                <td>{rows.Category_Name}</td>
                <td>{rows.Catg_Id}</td>
                <td>{rows.Sub_Id}</td>
                <td>{rows.Name_English}</td>
                <td>{rows.Name_Hindi}</td>
                <td>{rows.Image}</td>
                <td className={style.edit}>
                  <button
                    onClick={() => {
                      popupVisible("editPopup", true, rows);
                    }}
                  >
                    Edit
                  </button>
                </td>
                <td className={style.delete}>
                  <button
                    onClick={() => {
                      popupVisible("deletePopup", true, rows);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {!isLoading && (
        <LoaderUI content="Loading" isDimmerActive={true} size="large" />
      )}
    </>
  );
}

export default memo(ManageSubCategory);
