import { useEffect, useState, useContext } from "react";
import style from "./CustomerDetail.module.css";
import textboxstyle from "../../../../common-css/Textbox.module.css";
import BillingContext from "../../../data-centre/Billing-context";
import query from "../../../data-centre/Queries";
import StringCaseUI from "../../common-utility/StringCaseUI";
import { CustomerProfile, OrderDetail } from "../../../../Interface/Purchase";
import {
  isMobileNo,
  isNumber,
} from "../../../../customer-app/utility/validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageModal from "../../common-utility/MessageModal";
import LoaderSpinnerKit from "../../common-utility/LoaderSpinnerKit";
import DroplistU from "../../common-utility/DroplistU";
import Invoice from "./Invoice";
import { EMPTY_OBJECT } from "mobx/dist/internal";

const CustomerDetail = (props: any) => {
  const customerObj = {
    User_id: null,
    First_name: "",
    Last_name: "",
    _mobile: null,
    _email: "",
    _role: "",
    Created_at: null,
    Last_login: null,
    Address_id: null,
    _address1: "",
    _address2: "",
    _pincode: null,
    _city: "Kota",
    _state: "Rajasthan",
    _country: "India",
  };
  const [customerDetail, setCustomerDetail] =
    useState<CustomerProfile>(customerObj);
  const [startDate, setStartDate] = useState<any>();
  const [promptMessage, setPromptMessage] = useState<string>("");
  const [searchBox, setSearchBox] = useState<string>("");
  const [promptMessageVisibility, setPromptMessageVisibility] =
    useState<boolean>(false);
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
  const [selectedTab, setSearchTab] = useState<number>(0);
  const [addButtonVisibility, setAddButtonVisibility] = useState<boolean>(true);
  const [resetButtonVisibility, setResetButtonVisibility] =
    useState<boolean>(true);
  const [isDisable, setDisability] = useState<boolean>(false);

  const paidType = [
    { name: "BILL PAID", color: "#36AE7C" },
    { name: "BILL PARTIAL PAID", color: "#36AE7C" },
    { name: "BILLUNPAID", color: "#EB4747" },
  ];

  const roles = [
    { name: "Customer", value: "Customer" },
    { name: "Bulk", value: "Bulk" },
  ]; // PRESENTLY STATIC BUT REQUIRED DYNAMIC IN TABLE

  const billingctx = useContext(BillingContext);

  useEffect(() => {
    // const requestjson = new Request(
    //   "http://localhost:3000/json/DropDownOptions.json",
    //   { method: "GET", mode: "cors" }
    // );
    // fetch(requestjson)
    //   .then((response) => response.json())
    //   .then((response) => setdta((x) => response));
  });

  useEffect(() => {
    if (billingctx.getNewOrder()) {
      setCustomerDetail(customerObj);
      billingctx.setUserProfile(customerObj);
      setSearchTab(0);
      setStartDate(new Date());
      setPromptMessage("");
      setSearchBox("");
      setDisability(false);
      setAddButtonVisibility(true);
    }
  }, [billingctx.getNewOrder()]);

  useEffect(() => {
    if (!billingctx.getInvoice()) {
      let val = billingctx.getOrderDetail();
      billingctx.setOrderDetail({
        ...val,
        Customer_id: customerDetail.User_id,
        Order_status: "Pending",
        OrderInvoice_status: "not framed",
      });
      billingctx.setUserProfile(customerDetail);
    }

    billingctx.setRoleIndex(customerDetail);
  }, [customerDetail.User_id]);

  useEffect(() => {
    if (billingctx.getInvoice()) {
      setCustomerDetail(billingctx.getUserProfile());
      setDisability(true);
      setAddButtonVisibility(false);
      setResetButtonVisibility(false);
      setStartDate(
        new Date(
          Date.parse(
            (billingctx.getOrderDetail() as OrderDetail).OrderCreated_at
          )
        )
      );
    } else {
      setCustomerDetail(customerObj);
      resetHandler();
      setResetButtonVisibility(true);
    }
  }, [billingctx.getInvoice()]);

  //****ADD NEW USERS */
  const submitHandler = async (e: any) => {
    e.preventDefault();
    setLoaderVisibility(true);
    await query.Customer.add(customerDetail)
      .then((response) => {
        if (response.statusText === "OK") {
          setLoaderVisibility(false);
          setCustomerDetail(
            (x: CustomerProfile) => (x = { ...x, User_id: response.data.Data })
          );

          promptMessageHandler(response.data.Message);
          setAddButtonVisibility(false);
          setDisability(true);
        }
      })
      .catch((error) => {
        setLoaderVisibility(false);
        promptMessageHandler(error.response.data);
      });
  };
  //****END */

  const promptMessageHandler = (message: any) => {
    setPromptMessage(message);
    setPromptMessageVisibility(true);
  };

  const resetHandler = () => {
    if (
      billingctx.getOrderItem() &&
      billingctx.getOrderItem().length > 0 &&
      !billingctx.getNewOrder()
    ) {
      promptMessageHandler("First clear the items added in the order!!!");
      return;
    }
    setStartDate(null);
    setCustomerDetail(customerObj);
    setAddButtonVisibility(true);
    setDisability(false);
  };

  //****GET USERS DETAILS */
  const getUserHandler = async (typeDetail: string) => {
    let type =
      selectedTab === 0 ? "mobile" : selectedTab === 1 ? "email" : "userid";
    await query.Customer.list({ type: type, typeDetail: typeDetail })
      .then((response) => {
        if (response.statusText === "OK") {
          setCustomerDetail(
            (x: CustomerProfile) => (x = { ...response.data[0] })
          );
          setStartDate(new Date());
          setSearchBox("");
          setAddButtonVisibility(false);
          setDisability(true);
        }
      })
      .catch((error) => {
        setPromptMessage(error.response.data);
        setPromptMessageVisibility(true);
        setCustomerDetail((x: CustomerProfile) => (x = { ...customerObj }));
      });
  };
  //****END */

  return (
    <>
      {promptMessageVisibility && (
        <MessageModal
          message={promptMessage}
          close={() => setPromptMessageVisibility(false)}
        />
      )}
      {loaderVisibility && (
        <LoaderSpinnerKit
          backColor="#686769"
          size={60}
          // frontColor="#00ff89"
          frontColor="#0dcaf0"
          loading={true}
        />
      )}
      <div className={style["wrapper"]}>
        <div className={style.customerinfo}>
          <div className={style["searchcustomer-wrapper"]}>
            <div className={style.userId}>
              <span>Customer Id: </span>
              {customerDetail.User_id}
            </div>
            <div className={style.searchoption}>
              <div
                onClick={() => setSearchTab(0)}
                className={selectedTab === 0 ? style.backgroundcolor : ""}
              >
                Mobile
              </div>
              <div
                onClick={() => setSearchTab(1)}
                className={selectedTab === 1 ? style.backgroundcolor : ""}
              >
                Email
              </div>
              <div
                onClick={() => setSearchTab(2)}
                className={selectedTab === 2 ? style.backgroundcolor : ""}
              >
                UserId
              </div>
            </div>
            <div className={style.searchcustomer}>
              <input
                className={textboxstyle.textbox}
                // style={{ width: "42%" }}
                type="search"
                name="searchcustomer"
                placeholder="Search customer with userid,mobile,email..."
                value={searchBox}
                disabled={isDisable}
                onChange={(e) => setSearchBox(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    if (e.target.value) {
                      getUserHandler(e.target.value);
                    }
                  }
                }}
              ></input>
            </div>
          </div>
          <form
            onSubmit={submitHandler}
            className={style["name-address-wrapper"]}
          >
            <div className={style["name-wrapper"]}>
              <div className={style.customerName}>
                <span>First Name</span>
                <input
                  className={textboxstyle.textbox}
                  name="firstName"
                  type="text"
                  required={true}
                  value={customerDetail.First_name}
                  maxLength={20}
                  disabled={isDisable}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      return;
                    }
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, First_name: e.target.value })
                    );
                  }}
                  onBlur={() =>
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, First_name: StringCaseUI(x.First_name) })
                    )
                  }
                />
              </div>
              <div className={style.customerName}>
                <span>Last Name</span>
                <input
                  className={textboxstyle.textbox}
                  name="lastName"
                  type="text"
                  required={true}
                  value={customerDetail.Last_name}
                  maxLength={20}
                  disabled={isDisable}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      return;
                    }
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, Last_name: e.target.value })
                    );
                  }}
                  onBlur={() =>
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, Last_name: StringCaseUI(x.Last_name) })
                    )
                  }
                />
              </div>
            </div>
            <div className={style["address-wrapper"]}>
              <div className={style.address1}>
                <span>Address Line1</span>
                <input
                  className={textboxstyle.textbox}
                  name="address1"
                  required={true}
                  maxLength={40}
                  disabled={isDisable}
                  value={customerDetail._address1}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      return;
                    }
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, _address1: e.target.value })
                    );
                  }}
                  onBlur={() =>
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, _address1: StringCaseUI(x._address1) })
                    )
                  }
                />
              </div>
              <div className={style.address2}>
                <span>Address Line2</span>
                <input
                  className={textboxstyle.textbox}
                  name="address2"
                  required={true}
                  maxLength={30}
                  disabled={isDisable}
                  value={customerDetail._address2}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      return;
                    }
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, _address2: e.target.value })
                    );
                  }}
                  onBlur={() =>
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...x, _address2: StringCaseUI(x._address2) })
                    )
                  }
                />
              </div>
            </div>
            <div className={style["mob-email-wrapper"]}>
              <div className={style.mobile}>
                <span>Mobile No.</span>
                <input
                  className={textboxstyle.textbox}
                  name="mobileNo"
                  type="tel"
                  disabled={isDisable}
                  value={
                    customerDetail._mobile === null
                      ? ""
                      : customerDetail._mobile
                  }
                  required={true}
                  onKeyDown={(e: any) =>
                    /^[0-9]$/.test(e.key) ||
                    e.key === "Backspace" ||
                    e.key === "ArrowLeft" ||
                    e.key === "ArrowRight" ||
                    e.key === "Delete" ||
                    e.key === "Tab"
                      ? null
                      : e.preventDefault()
                  }
                  onChange={(e) => {
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = {
                          ...x,
                          _mobile: Number(e.target.value),
                        })
                    );

                    e.target.setCustomValidity("");
                  }}
                  onBlur={(e) => {
                    if (!isMobileNo(customerDetail._mobile?.toString())) {
                      e.target.setCustomValidity("Invalid mobile number!!");
                    }
                  }}
                />
              </div>
              <div className={style.email}>
                <span>Email Id</span>
                <input
                  className={textboxstyle.textbox}
                  name="email"
                  type="email"
                  disabled={isDisable}
                  value={customerDetail._email}
                  onKeyDown={(e: any) =>
                    e.key === " " ? e.preventDefault() : null
                  }
                  onChange={(e) => {
                    setCustomerDetail(
                      (x) => (x = { ...x, _email: e.target.value })
                    );
                    // e.target.setCustomValidity("");
                  }}
                  onBlur={(e) => {
                    // let pattern: RegExp = /[a-zA-Z]@[a-zA-Z]/;
                    // if (!isEmail(pattern)) {
                    //   e.target.setCustomValidity("Invalid email!!");
                    // }
                  }}
                />
              </div>
            </div>
            <div className={style["city-state-wrapper"]}>
              <div className={style.city}>
                <span>City</span>
                <input
                  className={textboxstyle.textbox}
                  name="city"
                  type="text"
                  required={true}
                  defaultValue="Kota"
                  disabled={true}
                />
              </div>
              <div className={style.state}>
                <span>State</span>
                <input
                  className={textboxstyle.textbox}
                  name="state"
                  type="text"
                  required={true}
                  defaultValue={"Rajasthan"}
                  disabled={true}
                />
              </div>
            </div>
            <div className={style["role-wrapper"]}>
              <div className={style.role}>
                <span>Role</span>
                <DroplistU
                  list={roles}
                  name="roles"
                  disabled={isDisable}
                  required={true}
                  className={textboxstyle.textbox + " " + style.rolelist}
                  value={customerDetail._role}
                  onChange={(val: any) => {
                    setCustomerDetail(
                      (x: CustomerProfile) =>
                        (x = { ...customerDetail, _role: val })
                    );
                  }}
                />
              </div>
            </div>
            <div className={style.addbuttonwrapper}>
              {addButtonVisibility && (
                <button
                  className={style.addcustomer}
                  name="addcustomer"
                  type="submit"
                >
                  Add Customer
                </button>
              )}
              {resetButtonVisibility && (
                <button
                  type="button"
                  onClick={resetHandler}
                  className={style.resetCustomer}
                  name="resetCustomer"
                >
                  Reset
                </button>
              )}
            </div>
          </form>
        </div>

        <div className={style.invoiceinfo}>
          <Invoice
            setStartDate={setStartDate}
            startDate={startDate}
            promptMessageHandler={promptMessageHandler}
            setLoaderVisibility={setLoaderVisibility}
          />
        </div>
        <div className={style.otherdetails}>
          <div>
            <div>
              {billingctx.getOrderDetail()
                ? (billingctx.getOrderDetail() as OrderDetail)
                    .OrderInvoice_status !== "frammed"
                  ? "ORDER NO."
                  : "INVOICE NO."
                : ""}
            </div>
            <div>
              {billingctx.getOrderDetail()
                ? (billingctx.getOrderDetail() as OrderDetail).Order_id
                : ""}
            </div>
          </div>
          <div>
            <div>PAID STATUS</div>
            <div
              style={{
                backgroundColor: paidType[0].color,
                color: "#fff",
              }}
            >
              {billingctx.getOrderDetail() &&
              isNumber((billingctx.getOrderDetail() as OrderDetail).Order_id)
                ? ((billingctx.getOrderDetail() as OrderDetail)
                    .TotalAmt_recieved as number) >=
                  (billingctx.getOrderDetail() as OrderDetail).OrderTotal_amt
                  ? "BILL PAID"
                  : "UNPAID"
                : ""}
            </div>
          </div>
          <div>
            <div>PAYMENT MODE</div>
            <div>
              {billingctx.getOrderDetail()
                ? (billingctx.getOrderDetail() as OrderDetail)
                    .PaymentRecieved_mode
                : ""}
            </div>
          </div>
          <div>
            <div>BALANCE TO CUSTOMER</div>
            <div>
              {billingctx.getOrderDetail()
                ? (billingctx.getOrderDetail() as OrderDetail)
                    .Balance_toCustomer
                : ""}
            </div>
          </div>
          <div>
            <div>BALANCE TO COMPANY</div>
            <div>
              {billingctx.getOrderDetail()
                ? (billingctx.getOrderDetail() as OrderDetail).Balance_toCompany
                : ""}
            </div>
          </div>
          <div>
            <div>DELIVERY STATUS</div>
            <div
              style={{
                color: billingctx.getOrderDetail()
                  ? (billingctx.getOrderDetail() as OrderDetail)
                      .Order_status === "Pending"
                    ? "red"
                    : ""
                  : "",
              }}
            >
              {billingctx.getOrderDetail()
                ? (billingctx.getOrderDetail() as OrderDetail).Order_status
                : ""}
            </div>
          </div>
          <div>
            <div>Biller</div>
            <div>
              {billingctx.getOrderDetail()
                ? (billingctx.getOrderDetail() as OrderDetail)._biller
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetail;
