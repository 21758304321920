import style from "./EditPopup.module.css";
import { MdOutlineClose } from "react-icons/md";

function Backdrop(props: any) {
  return (
    <>
      <div className={style.backdrop} />
    </>
  );
}

function OverLay({ children, onClose, reset, update, clsBtnStyle }: any) {
  return (
    <>
      <form onSubmit={update} className={style.editmodal}>
        <div className={style.content}>
          {children}
          <div className={style["button-wrapper"]}>
            <button type="submit">Update</button>
            <button type="button" onClick={reset}>
              Reset
            </button>
          </div>
          <MdOutlineClose
            className={style.closebutton}
            style={clsBtnStyle}
            onClick={() => onClose(undefined)}
          />
        </div>
      </form>
    </>
  );
}

function EditPopup({ children, onClose, reset, update, clsBtnStyle }: any) {
  return (
    <>
      <Backdrop />
      <OverLay
        clsBtnStyle={clsBtnStyle}
        onClose={onClose}
        update={update}
        reset={reset}
      >
        {children}
      </OverLay>
    </>
  );
}

export default EditPopup;
