import {
  TraceSpinner,
  CircleSpinner,
  RotateSpinner,
  ClapSpinner,
} from "react-spinners-kit";
import style from "./LoaderSpinnerKit.module.css";

interface type {
  size: number;
  frontColor: string;
  backColor: string;
  loading: boolean;
}

function LoaderSpinnerKit({ size, frontColor, backColor, loading }: type) {
  return (
    <div className={style.spinnerContainer}>
      <div className={style.spinner}>
        <ClapSpinner
          size={size}
          backColor={backColor}
          frontColor={frontColor}
          loading={loading}
          
        />
      </div>
    </div>
  );
}

export default LoaderSpinnerKit;
