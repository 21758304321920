import { useState } from "react";
import LoginContext from "./Login-context";
import { userDetail, userType } from "../../Interface/Purchase";

const LoginProvider = (props: any) => {
  const usersList = [
    {
      name: "Trilok",
      userId: "triloksingh.prajapat",
      password: "SinghTrilok855$$",
    },
    { name: "Vaibhav", userId: "dixit1.engg", password: "v123456$$" },
    { name: "Bhavna", userId: "bhavnadubey89.bd", password: "b123456$$" },
  ];

  const [user, setUser] = useState<userDetail>({
    userId: "",
    password: "",
    name: "",
  });

  const checkUserHandler = (obj: userType) => {
    let user = usersList.find(
      (x) => x.userId === obj.userId && x.password === obj.password
    );
    console.log(user);
    if (user !== undefined) {
      setUser(user);
      return true;
    }
    return false;
  };

  const getUserHandler = () => {
    return user;
  };

  const valueTransferred = {
    getUser: getUserHandler,
    checkUser: checkUserHandler,
  };

  return (
    <LoginContext.Provider value={valueTransferred}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
