import { veg, fruit } from "./MenuIconsSvg";

export const menuIconStore = [
  {
    icon: fruit,
    name: "Fruits",
  },
  {
    icon: veg,
    name: "Vegetables",
  },
  // { icon: vegIcon, name: "Meat & Fish" },
  // { icon: vegIcon, name: "Snacks" },
  // { icon: vegIcon, name: "Pet Care" },
  // { icon: vegIcon, name: "Home & Cleaning" },
  // { icon: vegIcon, name: "Dairy" },
  // { icon: vegIcon, name: "Cooking" },
  // { icon: vegIcon, name: "BreakFast" },
  // { icon: vegIcon, name: "Beverage" },
  // { icon: vegIcon, name: "Beauty & Health" },
];
