import { useState, useEffect, useContext } from "react";
import {
  ItemAvailable,
  GstRules,
  Store,
  OrderItemSummary,
} from "../../../../../Interface/Purchase";
import MessageModal from "../../../common-utility/MessageModal";
import style from "./PlaceOrder.module.css";
import Qtyinput from "./Qtyinput";
import DroplistU from "../../../common-utility/DroplistU";
import { AiFillDelete } from "react-icons/ai";
import {
  isDecimal,
  isNumber,
} from "../../../../../customer-app/utility/validation";
import BillingContext from "../../../../data-centre/Billing-context";
import { storeQtyInputHandler } from "./AddOrderItemHandlers";

function AddOrderItem({
  index,
  list,
  removeItem,
  gstRules,
  orderItemHandler,
  itemSelectedIndex,
  rowSelected,
  mappedKeys,
  orderItemList,
  invoice,
  billingctx,
}: any) {
  const item: ItemAvailable = list;
  const gstrules: GstRules[] = gstRules;

  let tax: any = gstrules.find((x) => x.Gst_id === item.SndCgst_code)?._tax;
  let metricRulesArray = (item.Metric_rules as string).split(",");

  const [isDeleteIconVisible, setDeleteIconVisibility] =
    useState<boolean>(false);
  const [qtyInput, setQtyInput] = useState<string>("");
  const [subTotal, setSubTotal] = useState<string>("");
  const [taxableValue, setTaxableValue] = useState<string>("");
  const [unitSP, setUnitSP] = useState<string>(item.Avg_sp as string);
  const [unitMRP, setUnitMRP] = useState<number>(item.Unit_mrp as number);
  const [metricChoosen, setMetricChoosen] = useState<string>("");
  const [promptMessage, setPromptMessage] = useState<string>("");
  const [promptVisible, setPromptVisibility] = useState<boolean>(false);
  const [isManualEditable, setManualEditable] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<any>();
  const [disable, setDisable] = useState<boolean>(false);

  let dropMetricList: any = [];
  let initialMetricList: any = [];

  if (item.Metric_rules !== null) {
    (item.Metric_rules as string)
      .split(",")
      .map(
        (x: any, index: number) =>
          (initialMetricList =
            index % 2 !== 0 ? [...initialMetricList, x] : initialMetricList)
      );
    Array.from(new Set(initialMetricList)).map(
      (x: any) => (dropMetricList = [...dropMetricList, { name: x, value: x }])
    );
  }

  const promptHandler = (message: string) => {
    setPromptMessage(message);
    setPromptVisibility(true);
  };

  const inputQtyHandler = (val: any) => {
    if (!isNumber(val) && val != "") {
      promptHandler("Input Qty is not valid!!!");
      setQtyInput("");
      return;
    }
    setQtyInput(val);
  };

  ///**SUBTOTAL HANDLER */
  const subTotalHandler = (val: any) => {
    if (val === "") {
      setSubTotal(val);
      return;
    }

    if (Number(val) === Number(subTotal)) {
      setSubTotal(val);
      return;
    }

    if (!isNumber(val)) {
      promptHandler("Sub-Total Qty is not valid!!!");
      setSubTotal("");
      return;
    }
    if (isNumber(val)) {
      if (
        item.Unit_mrp &&
        Number(val) >
          Number(((unitMRP as number) * Number(qtyInput)).toFixed(2))
      ) {
        promptHandler("Sub-Total Qty should not greater than MRP!!!");
        setSubTotal("");
        setUnitSP("");
        setTaxableValue("");
        return;
      }

      setManualEditable(true);

      let unitSP_l = (Number(val) / Number(qtyInput)).toFixed(2);
      taxHandler(Number(qtyInput), Number(unitSP_l));
      console.log("unitspl_l:", unitSP_l);
      setUnitSP(unitSP_l);
      setSubTotal(val);
    }
  };
  ///**END */

  useEffect(() => {
    storeQtyInputHandler({
      invoice,
      setManualEditable,
      qtyInput,
      metricChoosen,
      qtySizeValidation,
      setSubTotal,
      item,
      disable,
      promptHandler,
      setQtyInput,
      setMetricChoosen,
      mappedKeys,
      orderItemList,
      billingctx,
    });
  }, [qtyInput, metricChoosen]);

  const qtySizeValidation = (qty: number) => {
    if (qty <= 0) {
      promptHandler("Qty Shouldn't be zero or less!!!");
      setQtyInput("");
      return;
    }

    let Stock_available_l = item.Stock_available;
    if (item.IsNegative_stock) {
      Stock_available_l =
        (item.Stock_available as number) < 0
          ? (item.Stock_available as number) * -1
          : item.Stock_available;
    }
    if (item._metric === metricChoosen) {
      if (qty > (Stock_available_l as number) && !item.IsNegative_stock) {
        promptHandler("Item Qty Should less than available!!!");
        setQtyInput("");
        return;
      }
      setUnitSPHandler(3, qty);
    } else {
      if (metricChoosen === metricRulesArray[1]) {
        if (qty * Number(metricRulesArray[2]) > (Stock_available_l as number)) {
          promptHandler("Item Qty Should less than available!!!");
          setQtyInput("");
          setUnitSPHandler(3, qty);
          return;
        } else {
          setUnitSPHandler(1, qty);
          return;
        }
      } else {
        if (qty > (Stock_available_l as number) * Number(metricRulesArray[2])) {
          promptHandler("Item Qty Should less than available1!!!");
          setQtyInput("");
          setUnitSPHandler(3, qty);
          return;
        } else {
          setUnitSPHandler(0, qty);
          return;
        }
      }
    }
  };

  const setUnitSPHandler = (type: number, qty: number) => {
    let unitSP_l;
    switch (type) {
      case 0:
        unitSP_l = Number(item.Avg_sp) / Number(metricRulesArray[2]);
        setUnitMRP(
          Number(
            ((item.Unit_mrp as number) / Number(metricRulesArray[2])).toFixed(2)
          )
        );
        setUnitSP(unitSP_l.toFixed(2));
        setSubTotal((qty * unitSP_l).toFixed(2));
        break;
      case 1:
        unitSP_l = Number(item.Avg_sp) * Number(metricRulesArray[2]);
        setUnitMRP(
          Number(
            ((item.Unit_mrp as number) * Number(metricRulesArray[2])).toFixed(2)
          )
        );
        setUnitSP(unitSP_l.toFixed(2));
        setSubTotal((qty * unitSP_l).toFixed(2));
        break;
      default:
        unitSP_l = Number(item.Avg_sp);
        setUnitSP(item.Avg_sp as string);
        setUnitMRP(item.Unit_mrp as number);
        setSubTotal((qty * Number(item.Avg_sp)).toFixed(2));
        break;
    }
    taxHandler(qty, unitSP_l);
  };

  const taxHandler = (qty: number, unitSP_l: number) => {
    if (
      tax?.split("%")[0] > 1 &&
      isNumber(qty) &&
      isNumber(unitSP_l) &&
      metricChoosen
    ) {
      setTaxableValue(
        ((qty * unitSP_l) / (1 + parseFloat(tax?.split("%")[0]) / 100)).toFixed(
          2
        )
      );
    } else {
      setTaxableValue("");
    }
  };

  useEffect(() => {
    if (qtyInput && metricChoosen && !invoice) {
      orderItemHandler({
        qtyInput: qtyInput,
        metricChoosen: metricChoosen,
        taxableValue: taxableValue,
        unitSP: !isManualEditable ? unitSP : item.Avg_sp,
        subTotal: subTotal,
        unitMRP: unitMRP,
        UnitPriceManual: isManualEditable ? unitSP : null,
        index: index,
      });
    }
  }, [subTotal, unitSP]);

  useEffect(() => {
    if (invoice) {
      let sno = mappedKeys.findIndex((x: any) => x[0] === item.Pricing_id);
      if (sno !== -1) {
        sno = sno + 1;
      } else {
        let check = 0;
        for (let i = 0; i < mappedKeys.length; i++) {
          for (let k = 1; k < mappedKeys[i].length; k++) {
            if (mappedKeys[i][k] === item.Pricing_id) {
              sno = i + 1 + "." + k;
              check = 1;
              break;
            }
          }
          if (check === 1) {
            break;
          }
        }
      }
      setRowIndex(sno);
    } else {
      setRowIndex(index + 1);
    }
  }, [index]);

  useEffect(() => {
    let i = mappedKeys.findIndex((x: any) => x[0] === item.Pricing_id);

    if (invoice && i !== -1) {
      if (billingctx.getInvoicePointerEvents()) {
        setDisable(true);
        // return;
      }
      {
        setDisable(true);
      }
      setSubTotal(
        (orderItemList as OrderItemSummary[])[i].Sub_total?.toString() as string
      );
      setQtyInput(
        (orderItemList as OrderItemSummary[])[i].Qty_order?.toString() as string
      );
      setMetricChoosen(
        (orderItemList as OrderItemSummary[])[i].Qty_metric as string
      );
      setUnitSP(
        (orderItemList as OrderItemSummary[])[i].UnitPrice_manual === null
          ? ((orderItemList as OrderItemSummary[])[i].Unit_price as string)
          : ((orderItemList as OrderItemSummary[])[i]
              .UnitPrice_manual as string)
      );
      if ((orderItemList as OrderItemSummary[])[i].Taxable_amt) {
        setTaxableValue(
          (orderItemList as OrderItemSummary[])[
            i
          ].Taxable_amt?.toString() as string
        );
      }
    }
  });

  return (
    <>
      {promptVisible && (
        <MessageModal
          close={() => {
            setPromptVisibility(false);
          }}
          message={promptMessage}
        />
      )}

      <div
        onMouseEnter={() =>
          !disable ? setDeleteIconVisibility((x) => true) : null
        }
        onMouseLeave={() => setDeleteIconVisibility((x) => false)}
        className={style.productrow}
      >
        <div
          onClick={
            () => {
              if (!billingctx.getInvoicePointerEvents() ? disable : false) {
                itemSelectedIndex(
                  index,
                  item.ArchievePricing_id,
                  item.Pricing_id
                );
              }
            } //when saved order is fetched then item.pricingid=itemorderId, If order newly created then it is pricingid
          }
          style={{
            backgroundColor: rowSelected[0] === index ? rowSelected[1] : "",
            color: rowSelected[0] === index ? rowSelected[2] : "",
            cursor: !disable
              ? "default"
              : billingctx.getInvoicePointerEvents()
              ? "default"
              : "pointer",
            fontSize: !disable ? "12px" : "",
          }}
        >
          {rowIndex}
        </div>
        <div
          style={{
            color: disable ? "black" : "",
            fontSize: !disable ? "12px" : "",
          }}
        >
          {item.Variant_name}
        </div>
        <div>
          {
            <Qtyinput
              name={
                index.toString() +
                "" +
                item.Variant_name +
                " " +
                index.toString()
              }
              type="text"
              value={qtyInput}
              setQtyInput={inputQtyHandler}
              isBlurValue={false}
              disabled={disable}
              color={disable ? "black" : ""}
              fontweight={disable ? 500 : undefined}
            />
          }
        </div>
        <div>
          <DroplistU
            required={true}
            name={"drop" + item}
            style={{
              width: "100%",
              border: "none",
              appearance: "none",
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "transparent",
              color: disable ? "black" : "",
              fontWeight: disable ? 600 : undefined,
            }}
            value={metricChoosen}
            list={dropMetricList}
            onChange={(val: any) => {
              setMetricChoosen(val);
            }}
            disabled={disable}
          />
        </div>
        <div
          style={{
            color: disable ? "black" : "",
          }}
        >
          {disable ? unitMRP : ""}
        </div>
        <div
          style={{
            color: disable ? "black" : "",
          }}
        >
          {disable ? taxableValue : ""}
        </div>
        <div
          style={{
            color: disable ? "black" : "",
          }}
        >
          {(
            gstrules.find((x) => x.Gst_id === item.SndCgst_code) as any
          )._tax.split("%").length > 1
            ? !disable
              ? ""
              : gstrules.find((x) => x.Gst_id === item.SndCgst_code)?._tax
            : ""}
        </div>
        <div
          style={{
            color: disable ? "black" : "",
          }}
        >
          {metricChoosen !== "" && qtyInput
            ? disable || !invoice
              ? "Rs " + unitSP
              : null
            : null}
        </div>
        <div
          style={{
            color: disable ? "black" : "",
          }}
        >
          {qtyInput &&
          metricChoosen &&
          isNumber(unitSP) &&
          unitMRP !== undefined &&
          unitMRP !== 0
            ? (
                (((unitMRP as number) - Number(unitSP)) * 100) /
                unitMRP
              ).toFixed(2) + "%"
            : disable
            ? "-"
            : ""}
        </div>
        <div>
          <Qtyinput
            name={item.Variant_name + " " + index.toString()}
            type="text"
            value={subTotal ? subTotal : ""}
            setQtyInput={subTotalHandler}
            isBlurValue={true}
            disabled={invoice ? true : false}
            fontweight={700}
            isManualEditable={isManualEditable}
          />
        </div>
        {isDeleteIconVisible && (
          <AiFillDelete
            onClick={() => {
              removeItem(index);
            }}
            className={style["rowdeleteicon"]}
          />
        )}
      </div>
    </>
  );
}

export default AddOrderItem;
