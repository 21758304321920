import React from "react";
import { memo } from "react";
import { useState } from "react";

interface properties {
  required?: boolean;
  disable: boolean;
  initialValue?: any;
  name: any;
  clicked?: boolean;
  list: any;
  type?: any;
  isSelected?: boolean;
  placeholder?: string;
  className?: any;
  style?: any;
  onClick: (x: any) => any;
}

const Dropdown = (props: properties) => {
  const {
    required,
    disable,
    initialValue,
    name,
    clicked,
    list,
    type,
    isSelected,
    placeholder,
    className,
    style,
  } = props;
  const [isDisabledMode, setDisabledMode] = useState<boolean>(disable);

  const handler = (e: any) => {
    if (clicked) {
      props.onClick(e.target.value);
    }
  };

  const select = (
    <select
    
      disabled={disable}
      className={className}
      style={style}
      onChange={(e) => handler(e)}
      required={required}
      name={name}
    >
      {!isSelected ? (
        <option key={"001"} value="">
          
          Select
          {placeholder != null || placeholder != undefined
            ? placeholder.length > 0
              ? " " + placeholder
              : ""
            : ""}
        </option>
      ) : (
        <option key={"000"} value={initialValue}>
          {initialValue}
        </option>
      )}
      {list.map((item: any) => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  );

  return <>{select}</>;
};
//&& prevProps.name === nextProps.name

const areEqual = (prevProps: any, nextProps: any) => {
  // console.log("prevname newname", prevProps.name, nextProps.name);
  return (
    prevProps.list === nextProps.list &&
    prevProps.name === nextProps.name &&
    prevProps.disable === nextProps.disable
  );
};

export default memo(Dropdown, areEqual);
