import { useRef, useState } from "react";
import {
  isMobileNo,
  isPincode,
} from "../../../../customer-app/utility/validation";
import query from "../../../data-centre/Queries";
import MessageModal from "../../common-utility/MessageModal";
import style from "./AddSupplier.module.css";
import React from "react";
import LoaderUI from "../../common-utility/LoaderUI";

function AddSupplier({ onClose }: any) {
  const [popupVisibility, setPopupVisibility] = useState<boolean>(false);
  const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [display, setDisplay] = useState<string>("");

  const formRef: any = useRef(null);

  const addObj = [
    {
      heading: "Name",
      inputype: "text",
      required: true,
    },
    {
      heading: "Contact1",
      inputype: "number",
      required: true,
    },
    {
      heading: "Contact2",
      inputype: "number",
      required: false,
    },
    {
      heading: "Payment Mode",
      inputype: "text",
      required: false,
    },
    {
      heading: "Address1",
      inputype: "text",
      required: false,
    },
    {
      heading: "Address2",
      inputype: "text",
      required: false,
    },
    {
      heading: "City",
      inputype: "text",
      required: true,
    },
    {
      heading: "State",
      inputype: "text",
      required: true,
    },
    {
      heading: "Country",
      inputype: "text",
      required: true,
    },
    {
      heading: "Pincode",
      inputype: "number",
      required: false,
    },
    {
      heading: "Remark",
      inputype: "text",
      required: false,
    },
  ];

  const dt: any = { _name: "srihari", _contact1: 1245458745, _contact2: null };

  const submitHandler = (e: any) => {
    e.preventDefault();
    setDisplay("none");
    setLoaderVisibility(true);
    addData(e);
  };

  const addData = async (e: any) => {
    const formData: any = {};
    formData._name = e.target["Name"].value;
    if (e.target["Contact1"].value != "") {
      formData._contact1 = parseInt(e.target["Contact1"].value);
    }
    if (e.target["Contact2"].value != "") {
      formData._contact2 = parseInt(e.target["Contact2"].value);
    }
    if (e.target["Payment Mode"].value != "") {
      formData.Payment_mode = e.target["Payment Mode"].value;
    }

    if (e.target["Address1"].value != "") {
      formData._address1 = e.target["Address1"].value;
    }
    if (e.target["Address2"].value != "") {
      formData._address2 = e.target["Address2"].value;
    }
    if (e.target["City"].value != "") {
      formData._city = e.target["City"].value;
    }
    if (e.target["State"].value != "") {
      formData._state = e.target["State"].value;
    }
    if (e.target["Country"].value != "") {
      formData._country = e.target["Country"].value;
    }
    if (e.target["Pincode"].value != "") {
      formData._pincode = parseInt(e.target["Pincode"].value);
    }
    if (e.target["Remark"].value != "") {
      formData._remark = e.target["Remark"].value;
    }

    await query.Supplier.add(formData)
      .then((response) => {
        if (response.statusText === "OK") {
          setLoaderVisibility(false);
          if (response.data === "duplicate") {
            setPopupMessage("Duplicate data!!!");
            setPopupVisibility(true);
            return;
          }
          setPopupMessage("Supplier Added Successfully!!!");
          setPopupVisibility(true);
          formRef.current.reset();
        }
      })
      .catch((error) => {
        setPopupMessage("Error found " + error);
        setPopupVisibility(true);
      });
  };

  return (
    <>
      {popupVisibility ? (
        <MessageModal
          message={popupMessage}
          close={() => {
            setPopupVisibility(false);
            setDisplay("");
          }}
        />
      ) : null}

      {isLoaderVisible ? (
        <LoaderUI content={"Adding..."} isDimmerActive={true} size={"small"} />
      ) : null}

      <form
        ref={formRef}
        className={style["add-supplier-wrapper"]}
        onSubmit={submitHandler}
        style={{ display: display }}
      >
        {addObj.map((inp: any, index: number) => (
          <div key={inp.heading}>
            <div>{inp.heading}</div>
            <div>
              <Input index={index} inp={inp} />
            </div>
          </div>
        ))}
        <div>
          <div>
            <button
              className={style.close}
              onClick={() => {
                onClose(false);
              }}
              type="button"
            >
              Cancel
            </button>
          </div>
          <div>
            <button className={style.add} type="submit">
              Add
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddSupplier;

const Input = (props: any) => {
  return (
    <input
      className={style["input1"]}
      type={props.inp.inputype}
      required={props.inp.required}
      name={props.inp.heading}
      onChange={(e: any) => {
        e.target.setCustomValidity("");
      }}
      onBlur={(e: any) => {
        if (props.inp.heading === "Contact1") {
          return !isMobileNo(e.target.value) && e.target.value.length > 0
            ? e.target.setCustomValidity("Mobile no is not valid")
            : "";
        }
        if (props.inp.heading === "Contact2") {
          return !isMobileNo(e.target.value) && e.target.value.length > 0
            ? e.target.setCustomValidity("Mobile no is not valid")
            : "";
        }
        if (props.inp.heading === "Pincode") {
          return !isPincode(e.target.value) && e.target.value.length > 0
            ? e.target.setCustomValidity("Pincode no is not valid")
            : "";
        }
      }}
    />
  );
};
