import { createContext } from "react";

const count = {
  setcount: (x: any) => {},
  getcount: () => {
    return 0;
  },
};

const CartItemCountContext = createContext(count);

export default CartItemCountContext;
