export const isEmail = (arg: any) => {
  let result: boolean = false;
  let arr1 = [] as any; //array for @
  let arr2 = [] as any; // array for .
  let count = 0; //to count no of "." after last @
  let arr = arg.split("");
  arr.map((item: any, index: number) =>
    item === "@"
      ? (arr1 = [...arr1, index])
      : item === "."
      ? (arr2 = [...arr2, index])
      : ""
  );
  let arr1Length = arr1.length;
  let arr2Length = arr2.length;

  arr2.map((x: any) => (x > arr1[arr1Length - 1] ? count++ : count));

  if (
    arr1Length === 1 &&
    arr2Length > 0 &&
    arr1[arr1Length - 1] > 1 &&
    arr2[arr2Length - 1] - arr1[arr1Length - 1] > 2 &&
    count === 1
  ) {
    result = true;
  }
  return result;
};

export const isMobileNo = (arg: any) => {
  let result: boolean = false;
  if (isNumber(arg) && !isDecimal(arg) && arg.length === 10) {
    result = true;
    console.log(arg.length);
  }
  return result;
};

export const isNumber = (arg: any) => {
  if (arg === "" || arg === null || arg === "NaN" || arg === undefined) {
    return false;
  }
  return !isNaN(arg);
};

export const isDecimal = (arg: any) => {
  let result: boolean = false;
  if (isNumber(arg)) {
    if (parseInt(arg.toString()).toString() !== arg) {
      result = true;
    }
  }

  return result;
};

export const isPincode = (arg: any) => {
  let result: boolean = false;
  if (isNumber(arg) && !isDecimal(arg) && arg.length === 6) {
    result = true;
  }
  return result;
};
