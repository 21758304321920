import { useContext } from "react";
import {
  ItemAvailable,
  OrderItemDetail,
  OrderItemSummary,
  Store,
} from "../../../../../../Interface/Purchase";
import BillingContext from "../../../../../data-centre/Billing-context";

export function AddItemHandler({
  id,
  rowSelected,
  setSelectedRow,
  searchList,
  setOrderItemList,
  orderItemList,
  setProductList,
  setModifiedSearchList,
  setSearchList,
  setSearchBox,
  setMappedKeys,
  mappedKeys,
  setCheckPoint,
  productList,
  setProductListKey,
  productListKey,
  setPromptMessage,
  setPromptVisibility,
  ctx,
}: any) {
  let item: any;
  let billingCtx = ctx;
  if (rowSelected[0] === -1) {
    item = searchList.find((itm: ItemAvailable) => itm.Pricing_id === id);
    setOrderItemList(
      (x: OrderItemSummary[]) =>
        (x = [
          ...x,
          {
            Stock_id: item.Stock_id as string,
            Pricing_id: item.Pricing_id as number,
            ArchievePricing_id: Number(item.ArchievePricing_id as string),
            Unit_mrp: item.Unit_mrp as number,
            Metric_conversion: item.Metric_rules as string,
            Gst_id: item.SndCgst_code,
            Item_status: "pending",
            Item_sequence: ++orderItemList.length,
          },
        ])
    );
    // setMappedKeys((x: any) => (x = [...x, [id]]));
    setProductList((x: ItemAvailable[]) => (x = [...x, item]));
  } else {
    item = searchList.find((item: Store) => item.Store_id === id); //KEY ID IS STORE ID . HERE SEARCHLIST IS STORE

    let check = mappedKeys.findIndex(
      (x: any) => x.findIndex((x: any) => x === (item as Store).Store_id) > -1
    );
    if (check !== -1) {
      setSelectedRow((x: any) => (x = [-1, "", "", -1, -1]));
      setPromptMessage("Item already added!!!");
      setPromptVisibility(true);
      return;
    } else if (
      (item as Store).Qty_remNet - ((item as Store).Passive_qty as number) <=
      0
    ) {
      setSelectedRow((x: any) => (x = [-1, "", "", -1, -1]));
      setPromptMessage("Item quantity is insufficient!!!");
      setPromptVisibility(true);
      return;
    }

    let orderItemDetailArray: OrderItemDetail[] =
      billingCtx.getOrderItemDetail()
        ? [...billingCtx.getOrderItemDetail()]
        : [];

    let orderItemDetailIndex = orderItemDetailArray.findIndex(
      (x: OrderItemDetail) => x.OrderItem_id === rowSelected[4] //rowselected[4] is orderitemid for fetching saved order items
    );

    if (orderItemDetailIndex !== -1) {
      orderItemDetailArray[orderItemDetailIndex].Store_ids.push(
        (item as Store).Store_id
      );
      orderItemDetailArray[orderItemDetailIndex].Qtys_taken?.push(0);
      orderItemDetailArray[orderItemDetailIndex].Qtys_metric?.push("");
    } else {
      orderItemDetailArray = [
        ...orderItemDetailArray,
        {
          Store_ids: [(item as Store).Store_id],
          Pricing_id: (item as Store).MappedPricing_id,
          Qtys_taken: [0],
          Qtys_metric: [""],
          OrderItem_id: rowSelected[4],
        },
      ];
    }

    billingCtx.setOrderItemDetail([...orderItemDetailArray]);

    let mappedIndex = mappedKeys.findIndex((x: any) => x[0] === rowSelected[4]); //check for 0th position of each tuple in an array to match with orderid of clicked item

    let prevElementsCount = mappedKeys.reduce(
      (sum: number, x: any, index: number) =>
        (sum = index <= mappedIndex ? sum + x.length : sum),
      0
    );
    let maxkeyValue = -1;

    productListKey.map((item: any) => {
      if (item > maxkeyValue) {
        maxkeyValue = item;
      }
    });

    let productListkeyArray = [...productListKey];

    productListkeyArray.splice(prevElementsCount, 0, maxkeyValue + 1);

    setProductListKey([...productListkeyArray]);

    let itemAvailableArray = [...productList];

    let prevElementsMappedIndex = mappedKeys.reduce(
      (sum: number, x: any, index: number) =>
        (sum = index < mappedIndex ? sum + x.length : sum),
      0
    );
    itemAvailableArray.splice(prevElementsCount, 0, {
      Variant_name:
        (item as Store)._Code +
        "-" +
        "{Availability:" +
        (
          (item as Store).Qty_remNet - ((item as Store).Passive_qty as number)
        ).toFixed(3) +
        (item as Store)._metric +
        "}",
      SndCgst_code: itemAvailableArray[prevElementsMappedIndex].SndCgst_code,
      Stock_available:
        (item as Store).Qty_remNet - ((item as Store).Passive_qty as number),
      _metric: (item as Store)._metric,
      ArchievePricing_id: (item as Store).MappedPricing_id.toString(),
      Avg_cp: (item as Store).Unit_cp,
      Avg_sp: "0",
      Igst_code: 0,
      IsNegative_stock: false,
      LiveStock_status: (item as Store).Live_status,
      Metric_rules: (item as Store).Metric_conversion,
      Pricing_id: (item as Store).Store_id,
      Unit_mrp: (item as Store).Unit_mrp,
      Stock_id: "",
    });
    setProductList((x: ItemAvailable[]) => (x = [...itemAvailableArray]));

    let mappedArray = [...mappedKeys];
    mappedArray[mappedIndex].push((item as Store).Store_id);
    setMappedKeys((x: any) => (x = [...mappedArray]));
    setSelectedRow((x: any) => (x = [-1, "", "", -1, -1]));
  }
  setModifiedSearchList([]);
  setSearchList([]);
  setSearchBox("");
}
