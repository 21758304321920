import ConfirmationModal from "../../common-utility/ConfirmationModal";
import { useState } from "react";
import axios from "axios";
import { Console } from "console";
import { Dimmer, Loader } from "semantic-ui-react";
import LoaderUI from "../../common-utility/LoaderUI";
import query from "../../../data-centre/Queries";

function DeleteSubCategory(props: any) {
  const [isLoader, setLoader] = useState<boolean>(false);

  async function deleteRow(action: string, Id: number) {
    try {
      setLoader(true);
      await query.SubCategory.remove(Id).then((response) => {
        if (response.statusText === "OK") {
          //  setLoader(false);
          props.close(
            "deletePopup",
            action,
            "Subcategory Deleted Successfully with Id: " + props.value.Sub_Id
          );
        }
      });
    } catch (error) {
      console.log("error : ", error);
    }
  }

  const deleteHandler = (action: string) => {
    if (action === "ok") {
      // console.log("subid:" + props.value.Sub_Id);
      deleteRow(action, props.value.Sub_Id);
    } else if (action === "cancel") {
      props.close("deletePopup", action, "");
    }
  };

  return (
    <>
      {!isLoader && (
        <ConfirmationModal>
          <div>
            {!isLoader && (
              <p>Are You Sure To Delete {props.value.Name_English}?</p>
            )}
            <div>
              <button onClick={() => deleteHandler("ok")}>Ok</button>
              <button onClick={() => deleteHandler("cancel")}>Cancel</button>
            </div>
          </div>
        </ConfirmationModal>
      )}
      {isLoader && (
        <LoaderUI content="Deleting..." isDimmerActive={true} size="medium" />
      )}
    </>
  );
}

export default DeleteSubCategory;
