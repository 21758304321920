import { useState } from "react";
import CartItemCountContext from "./CartItemCount-Context";

const CartItemCountProvider = (props: any) => {
  const [count, setCount] = useState<number>(0);

  const setCountHandler = (x: number) => {
    setCount(x);
  };

  const getCountHandler = () => {
    return count;
  };

  const valueTransferred = {
    setcount: setCountHandler,
    getcount: getCountHandler,
  };

  return (
    <CartItemCountContext.Provider value={valueTransferred}>
      {props.children}
    </CartItemCountContext.Provider>
  );
};

export default CartItemCountProvider;
