import { useEffect, useState } from "react";
import query from "../../../data-centre/Queries";
import Dropdown from "../../common-utility/Dropdown";
import LoaderUI from "../../common-utility/LoaderUI";
import SignSVG from "../../common-utility/SignSVG";
import style from "./AddFilter.module.css";
import { BiSearch } from "react-icons/bi";

function AddFilter(props: any) {
  const [catgDropdownList, setCatgdropdownList] = useState([]);
  const [subcatgDropdownList, setSubcatgdropdownList] = useState([]);
  const [dateToVisibility, setDateToVisibility] = useState<boolean>(false);
  const [name, setName] = useState<string>("Date");

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    await query.Category.listByName().then((response) => {
      if (response.statusText === "OK") {
        setCatgdropdownList(response.data);
      }
    });
  };

  const catgDropdownHandler = async (value: any) => {
    if (value === "") {
      setSubcatgdropdownList([]);
      return;
    }
    await query.SubCategory.detail(value, "catg").then((response) => {
      if (response.statusText === "OK") {
        setSubcatgdropdownList(response.data);
      }
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    console.log("hello");
    let dateTo;
    if (e.target["range"].checked === true) {
      dateTo =
        e.target["dateTo"].value !== ""
          ? e.target["dateTo"].value
          : "1900-01-01";
    } else {
      dateTo = "1900-01-01";
    }
    props.filter(
      e.target["purchase_catgdropdown"].value !== ""
        ? e.target["purchase_catgdropdown"].value
        : "select",
      e.target["purchase_subcatgdropdown"].value !== ""
        ? e.target["purchase_subcatgdropdown"].value
        : "select",
      e.target["dateFrom"].value !== ""
        ? e.target["dateFrom"].value
        : "1900-01-01",
      dateTo,
      new Date().getTime()
    );
  };

  return (
    <>
      <form className={style["wrapper"]} onSubmit={submitHandler}>
        <div>
          <label>Category</label>
          <Dropdown
            className={style.catg}
            onClick={catgDropdownHandler}
            list={catgDropdownList}
            clicked={true}
            required={false}
            name="purchase_catgdropdown"
            disable={false}
          />
        </div>

        <div>
          <label>Subcategory</label>
          <Dropdown
            className={style.subcatg}
            list={subcatgDropdownList}
            clicked={false}
            required={false}
            name="purchase_subcatgdropdown"
            onClick={() => {}}
            disable={false}
          />
        </div>

        <div>
          <label>Date Range</label>
          <input
            type="checkbox"
            name="range"
            onChange={(e) => {
              if (e.target.checked === true) {
                setName((x) => (x = "DateTo"));
                setDateToVisibility(true);
              } else {
                setName((x) => (x = "Date"));
                setDateToVisibility(false);
              }
            }}
          />
        </div>

        <div>
          <label>{name}</label>
          <input className={style.choice} type="date" name="dateFrom" />
          {dateToVisibility && (
            <>
              <label>Date To</label>
              <input className={style.choice} type="date" name="dateTo" />
            </>
          )}
        </div>

        <button type="submit">
          <BiSearch className={style.button} />
        </button>
      </form>
    </>
  );
}

export default AddFilter;
