import { useState, useEffect, useContext } from "react";
import { Store } from "../../../../../Interface/Purchase";
import query from "../../../../data-centre/Queries";
import StoreContext from "../../../../data-centre/Store-context";
import style from "./AvailableStockList.module.css";
import { VscCircleLargeFilled } from "react-icons/vsc";
import DateTime from "../../../common-utility/DateTime";
import MessageModal from "../../../common-utility/MessageModal";
import ModalPopup from "../../../../../customer-app/components/modal/ModalPopup";
import Modal from "../../../common-utility/Modal";

function AvailableStockList({ e, setTableVisibility }: any) {
  const [message, setMessage] = useState<string>();
  const [list, setList] = useState<any>([]);
  const [rowIndex, setRowIndex] = useState<number>(-1);
  const [isMessageModalVisible, setMessageModalVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    const id = getProductDetailList(e);
    setRowIndex(-1);
  }, [
    e.target["catgList"].value,
    e.target["subCatgList"].value,
    e.target["productList"].value,
    e.target["choice"].value,
  ]);

  const tableHeader = [
    { name: "SNO", width: "2%" },
    { name: "PRODUCT NAME", width: "7.8%" },
    { name: "CODE", width: "6.8%" },
    { name: "STORE ID", width: "5.5%" },
    { name: "PURCHASE ID", width: "6.4%" },
    { name: "QTY-PREV DETAILS", width: "7.9%" },
    { name: "QTY-IN", width: "3.7%" },
    { name: "UNIT MRP", width: "5%" },
    { name: "UNIT CP", width: "4%" },
    { name: "QTY-IN NET", width: "5%" },
    { name: "QTY-REM NET", width: "6.4%" },
    { name: "QTY-SPOIL NET", width: "6.8%" },
    { name: "TAX DETAILS", width: "6%" },
    { name: "OTHER DETAILS", width: "6.8%" },
    { name: "PRICING REQUIRED", width: "8%" },
    { name: "LIVE STATUS", width: "5.5%" },
    { name: "SEARCH MODE", width: "6.4%" },
  ];

  const getProductDetailList = async (e: any) => {
    let catgId = e.target["catgList"].value;
    let subCatgId = e.target["subCatgList"].value;
    let ProductName = e.target["productList"].value;
    let Choice = e.target["choice"].value;
    if (catgId === "") {
      catgId = "select";
    }
    if (subCatgId === "") {
      subCatgId = "select";
    }
    if (ProductName === "") {
      ProductName = "select";
    }
    if (Choice === "") {
      Choice = 0;
    }

    catgId === "select" && subCatgId === "select" && ProductName === "select"
      ? await query.Store.list()
          .then((response) => {
            if (response.statusText === "OK") {
              setList((x: any) => (x = response.data));
              response.data.length > 0
                ? setTableVisibility(true)
                : setTableVisibility(false);
            } else {
              setList([]);
              setTableVisibility(false);
              setMessage("error in fetching detailproduct list");
            }
          })
          .catch((error) => setMessage(error))
      : await query.Store.search(catgId, subCatgId, ProductName, Choice).then(
          (response) => {
            if (response.statusText === "OK") {
              setList((x: any) => (x = response.data));
              response.data.length > 0
                ? setTableVisibility(true)
                : setTableVisibility(false);
            } else {
              setList([]);
              setTableVisibility(false);
            }
          }
        );
  };

  return (
    <>
      {isMessageModalVisible && (
        <Modal>
          <div>Are You Sure To Forward?</div>
          <div>
            <button className={style.modalbutton}>Ok</button>
            <button
              className={style.modalbutton}
              onClick={() => setMessageModalVisibility(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
      <form className={style["main-div-stocklist"]}>
        <div className={style.tableHeader}>
          {list.length > 0
            ? tableHeader.map((x: any) => (
                <div key={x.name} style={{ width: x.width }}>
                  {x.name}
                </div>
              ))
            : null}
        </div>
        {list.length > 0
          ? list.map((x: Store, index: number) => (
              <TableData
                key={++index}
                tableHeader={tableHeader}
                color={rowIndex > -1 && rowIndex === index ? "#FFF6C3" : ""}
                index={++index}
                str={x}
                setRowIndex={setRowIndex}
              />
            ))
          : null}
      </form>
    </>
  );
}
export default AvailableStockList;

const TableData = ({ tableHeader, index, setRowIndex, color, str }: any) => {
  const storeCtx = useContext(StoreContext);
  const handler = (x: any[]) => {
    storeCtx.setValue(true, x);
  };
  let x: Store = str;
  var datetime = x.Created_at;
  const timeString12hr = DateTime({ value: datetime, required: "time" });

  const formattedDate = DateTime({ value: datetime, required: "date" });
  // const formattedDate =
  //   dateString[1] + "-" + dateString[0] + "-" + dateString[2];

  return (
    <div
      className={style.tableData}
      key={index}
      style={{ backgroundColor: color }}
      onClick={() => setRowIndex(--index)}
    >
      <div style={{ width: tableHeader[0].width }}>{index}</div>
      <div style={{ width: tableHeader[1].width, textAlign: "left" }}>
        {x.Variant_name}
      </div>
      <div style={{ width: tableHeader[2].width }}>
        {/* {formattedDate + " " + timeString12hr} */}
        {x._Code}
      </div>
      <div style={{ width: tableHeader[3].width }}>{x.Store_id}</div>
      <div style={{ width: tableHeader[4].width }}>{x.Purchase_id}</div>
      <div
        style={{ width: tableHeader[5].width }}
        onClick={() =>
          handler([
            { name: "heading", value: "QTY PREV DETAILS" },
            { name: "Prev QtyIn", value: x.Qty_inPrev + " " + x.Metric_prev },
            {
              name: "Prev QtyRemain",
              value: x.Qty_remPrev + " " + x.Metric_prev,
            },
            {
              name: "Prev QtySpoil",
              value: x.Qty_spoilPrev + " " + x.Metric_prev,
            },
          ])
        }
      >
        Click Here
      </div>
      <div style={{ width: tableHeader[6].width }}>
        {x.Qty_in}&nbsp;
        {x._metric}
      </div>
      <div style={{ width: tableHeader[7].width }}>{x.Unit_mrp}</div>
      <div style={{ width: tableHeader[8].width }}>{x.Unit_cp}</div>
      <div style={{ width: tableHeader[9].width }}>
        {x.Qty_inNet}&nbsp;
        {x._metric}
      </div>
      <div style={{ width: tableHeader[10].width }}>
        {x.Qty_remNet}&nbsp;
        {x._metric}
      </div>
      <div style={{ width: tableHeader[11].width }}>
        {x.Qty_spoilNet}&nbsp;
        {x._metric}
      </div>
      <div
        style={{ width: tableHeader[12].width }}
        onClick={() =>
          handler([
            { name: "heading", value: "TAX DETAILS" },
            { name: "GST", value: x.Purchase_gst },
            { name: "Other Tax", value: x.Other_tax },
            { name: "Net Tax", value: x.Net_tax },
          ])
        }
      >
        Click Here
      </div>
      <div
        style={{ width: tableHeader[13].width }}
        onClick={() =>
          handler([
            { name: "heading", value: "REMAINING DETAILS" },
            { name: "MappedPricind Id", value: x.MappedPricing_id },
            { name: "BufferEntry Time", value: x.CreatedBuffer_at },
            { name: "Batch No", value: x.Batch_no },
            { name: "Metric Conversion", value: x.Metric_conversion },
            { name: "Brand", value: x._brand },
          ])
        }
      >
        Click Here
      </div>
      <div style={{ width: tableHeader[14].width }}>{x.Pricing_required}</div>
      <div
        style={{
          width: tableHeader[15].width,
          fontWeight: 600,
          color: "green",
        }}
      >
        <VscCircleLargeFilled
          fill={(color = x.Live_status === "None" ? "red" : "Green")}
        />
        <br />
        {x.Live_status === "Live" &&
        x.Pricing_required === "Yes" &&
        x.Passive_qty != null
          ? "Fractional"
          : x.Live_status === "Live" &&
            x.Pricing_required === "Yes" &&
            x.Passive_qty == null
          ? "Exclusion"
          : ""}
      </div>
      <div style={{ width: tableHeader[16].width, textAlign: "left" }}>
        {x.ItemSearch_mode}
      </div>
    </div>
  );
};
