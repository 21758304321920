import { useContext, useState } from "react";
import style from "./Login.module.css";
import sahajj_logo from "../assets/sahajj-name-logo.png";
import { userType } from "../../Interface/Purchase";
import LoginContext from "../data-centre/Login-context";
import { Navigate, useNavigate } from "react-router-dom";

function Login({ usertypeHandler }: any) {
  const [password, setPassword] = useState("password");
  const [isEye, setEyeVisibility] = useState(true);
  const [message, setMessage] = useState("welcome");
  const [showMessage, setShowMessage] = useState(false);
  //   const inputStyle = `${"number"} ${"email"}`;

  const userObj = { userId: "", password: "" };
  const [loginUser, setLoginUser] = useState<userType>(userObj);
  const ctx = useContext(LoginContext);
  const navigate = useNavigate();
  const submitHandler = (event: any) => {
    event.preventDefault();
    let check = ctx.checkUser(loginUser);
    if (check) usertypeHandler(check);
    else navigate("/login");
  };

  const eyeHandler = (type: string) => {
    if (type === "open") {
      setPassword("text");
      setEyeVisibility(false);
    } else if (type === "close") {
      setPassword("password");
      setEyeVisibility(true);
    }
  };
  return (
    <div className={style.container}>
      <div className={style["container-inner"]}>
        <div className={style["h1-div"]}>
          <img
            alt="Sahajj company fruits, vegetable, grocery kota"
            src={sahajj_logo}
          />
        </div>
        <p>Admin Panel</p>
        <form onSubmit={submitHandler}>
          <input
            name="userid"
            type="text"
            required={false}
            placeholder="UserId/Email"
            autoComplete="off"
            onFocus={() => {
              setShowMessage(false);
            }}
            value={loginUser.userId}
            onChange={(e) =>
              setLoginUser((x) => (x = { ...x, userId: e.target.value }))
            }
          />
          <div className={style["eye-div"]}>
            <input
              name="pwd"
              type={password}
              required={false}
              placeholder="Password"
              onFocus={() => {
                setShowMessage(false);
              }}
              value={loginUser.password}
              onChange={(e) =>
                setLoginUser((x) => (x = { ...x, password: e.target.value }))
              }
            />

            {isEye && (
              <svg
                onClick={() => eyeHandler("open")}
                xmlns="http://www.w3.org/2000/svg"
                className={style["open-eye"]}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path
                  fillRule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}

            {!isEye && (
              <svg
                onClick={() => eyeHandler("close")}
                xmlns="http://www.w3.org/2000/svg"
                className={style["close-eye"]}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                  clipRule="evenodd"
                />
                <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
              </svg>
            )}
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>

      <div> {showMessage && message}</div>
    </div>
  );
}

export default Login;
