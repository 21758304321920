import style from "./CommonInterface.module.css";
import { CgCloseO } from "react-icons/cg";
import { useRef, useState } from "react";
import { isEmail, isMobileNo } from "../../utility/validation";

interface Icommon {
  heading: string;
  buttonName: string;
  additionaljsx: JSX.Element;
  close: () => any;
  onDataHandler: (arg: any) => void;
}

function CommonInterface(props: Icommon) {
  const inputEmailPhone: any = useRef();
  const inputPwd: any = useRef();
  const [errorEmail, setEmailError] = useState("");
  const [errorPwd, setPwdError] = useState("");

  //npm i --save-dev @types/validator //for instalation valdator
  const submitHandler = (event: any) => {
    event.preventDefault();
    let input1 = inputEmailPhone.current.value;
    let input2 = inputPwd.current.value;
    if (isEmail(input1)) {
      alert("email id is valid");
    } else if (isMobileNo(input1)) {
      alert("mobile no is valid");
    } else {
      setEmailError("Invalid input");
    }

    if (input2.length < 6) {
      setPwdError("Password should atleast 6 characters");
    }
  };
  return (
    <>
      <form
        onSubmit={submitHandler}
        autoComplete="off"
        className={style["login-wrapper"]}
      >
        <CgCloseO
          onClick={() => {
            props.close();
          }}
          className={style.close}
        />
        <span className={style.logintext}>{props.heading}</span>
        <span className={style.emailtext}>Email id/Mobile no</span>

        <input
          type="text"
          ref={inputEmailPhone}
          onClick={() => {
            setEmailError("");
          }}
          name="mob-email"
          required={true}
        />
        <span className={style.error}>{errorEmail}</span>
        <span className={style.pwdtext}>Password</span>

        <input
          type="password"
          name="pwd"
          onClick={() => {
            setPwdError("");
          }}
          ref={inputPwd}
          required={true}
        />
        <span className={style.error}>{errorPwd}</span>
        <button type="submit">{props.buttonName}</button>
        {props.additionaljsx}
      </form>
    </>
  );
}

export default CommonInterface;
