import Modal from "../../common-utility/Modal";

function Popup(props: any) {
  const clickHandler = (x: Boolean) => {
    props.visibility(x, null);
  };

  return (
    <>
      <Modal>
        <div>category Name: {props.value.Category_Name}</div>
        <div>category Id: {props.value.Catg_Id}</div>
        <div>Subcategory Name: {props.value.Name_English}</div>
        <div>Subcategory Name(Hindi): {props.value.Name_Hindi}</div>
        <div>Subcategory Image: {props.value.Image}</div>
        <div>
          <button>Submit</button>
          <button onClick={() => clickHandler(false)} type="button">
            Close
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Popup;
