import React from "react";
import style from "./Modal.module.css";

function Backdrop() {
  return <div className={style.backdrop} />;
}

function OverLay(props: any) {
  return (
    <div className={style.modal}>
      <div className={style.content}>{props.children}</div>
    </div>
  );
}

function Modal(props: any) {
  return (
    <>
      <Backdrop />
      <OverLay>{props.children}</OverLay>
    </>
  );
}

export default Modal;
