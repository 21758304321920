import { useContext, useEffect, useState } from "react";
import style from "./OrdersDetailFilter.module.css";
import { AiOutlineSearch } from "react-icons/ai";
import LoginContext from "../../../../data-centre/Login-context";
import OrderContext from "../../../../data-centre/Order-context";

interface type {
  loadData: (obj: {}) => Promise<void>;
  setPromptMessage: (
    message: string,
    visibility: boolean,
    status: number
  ) => void;
}

function OrdersDetailFilter({ setPromptMessage, loadData }: type) {
  const [isRange, setIsRange] = useState<boolean>(false);
  const [tab, setTab] = useState<string>("Today");
  const loginCtx = useContext(LoginContext);
  const orderCtx = useContext(OrderContext);

  const [searchCriteria, setSearchCriteria] = useState<{
    fromDate: any;
    endDate: any;
  }>({ fromDate: "", endDate: "" });

  const searchHandler = () => {
    if (isRange) {
      if (
        new Date(searchCriteria.fromDate) &&
        new Date(searchCriteria.endDate)
      ) {
        setPromptMessage("Both date should be selected", true, 0);
        return;
      } else if (searchCriteria.fromDate < searchCriteria.endDate) {
        setPromptMessage("Start date should be earlier then end date", true, 0);
        return;
      }
    }
    setTab("");
    orderCtx.setOrderDate(searchCriteria);
    loadData(searchCriteria);
  };

  const tabHandler = (type: string) => {
    setSearchCriteria({ fromDate: "", endDate: "" });
    setTab(type);
    var dt = new Date();
    if (type === "Yesterday") {
      dt.setDate(dt.getDate() - 1);
    } else if (type === "Tommorrow") {
      dt.setDate(dt.getDate() + 1);
    }
    let fromDate: any =
      type === "All Orders" ? { fromDate: null } : { fromDate: dt };

    orderCtx.setOrderDate({ fromDate: fromDate.fromDate, endDate: null });
    loadData(fromDate);
  };

  const tabArray = ["All Orders", "Today", "Yesterday", "Tommorrow"];

  const endDateOnChangeHandler = () => {};

  return (
    <div className={style["orderdetail-filter-wrapper"]}>
      {orderCtx
        .getOrderModuleAuthorization()
        .div.findIndex((x) => x === "orderSearch") <= -1 && (
        <div className={style["date-wrapper"]}>
          <div className={style.range}>
            Range{" "}
            <input
              type="checkbox"
              checked={isRange}
              onChange={(e: any) => {
                console.log(e.target.checked);
                setIsRange(e.target.checked);
              }}
            />
          </div>
          <div>
            <div className={style.from}>
              <div>Delivery Date</div>
              <div>
                <div>
                  {isRange && <span>From</span>}
                  <input
                    style={{
                      backgroundColor:
                        !tab && searchCriteria.fromDate ? "#9b59b6" : "",
                      color: !tab && searchCriteria.fromDate ? "white" : "",
                    }}
                    type="date"
                    value={searchCriteria.fromDate}
                    onChange={(e) => {
                      setSearchCriteria(
                        (x) =>
                          (x = { ...x, fromDate: e.target.value, endDate: "" })
                      );
                    }}
                  />
                </div>
                {isRange && (
                  <div>
                    <span>To</span>
                    <input
                      type="date"
                      style={{
                        backgroundColor:
                          !tab && searchCriteria.endDate ? "#9b59b6" : "",
                        color: !tab && searchCriteria.endDate ? "white" : "",
                      }}
                      value={searchCriteria.endDate}
                      onChange={(e) => {
                        if (!searchCriteria.fromDate) {
                          setPromptMessage(
                            "Start Date should be selected",
                            true,
                            0
                          );
                          setSearchCriteria((x) => (x = { ...x, endDate: "" }));
                          return;
                        }
                        setSearchCriteria(
                          (x) => (x = { ...x, endDate: e.target.value })
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={style.searchdiv}>
            <AiOutlineSearch
              className={style.searchButton}
              onClick={searchHandler}
            />
          </div>
        </div>
      )}
      <div
        className={style.tabs}
        style={{
          marginTop:
            orderCtx
              .getOrderModuleAuthorization()
              .div.findIndex((x) => x === "orderSearch") > -1
              ? "5%"
              : "",
        }}
      >
        {tabArray.map((item: any) => {
          return orderCtx
            .getOrderModuleAuthorization()
            .tabs.findIndex((x) => x === item) <= -1 ? (
            <div
              key={item}
              onClick={() => tabHandler(item)}
              style={{
                backgroundColor: tab === item ? "#9b59b6" : "",
                color: tab === item ? "white" : "black",
              }}
            >
              {item}
            </div>
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
}

export default OrdersDetailFilter;
