interface type {
  list: { name: any; value: any }[];
  onChange?: any;
  disabled?: boolean;
  required?: boolean;
  name: string | undefined;
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
  value?: string | undefined;
  placeholder?: string | undefined;
}
function DroplistU({ list, onChange, placeholder, ...props }: type) {
  return (
    <>
      <select
        {...props}
        onChange={(e: any) =>
          onChange === undefined ? "" : onChange(e.target.value)
        }
      >
        <option key="select" value="">
          {placeholder !== undefined ? placeholder : "Select"}
        </option>
        {list.map((item: any, index: number) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
}

export default DroplistU;
