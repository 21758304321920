import { useState } from "react";
import { useRef } from "react";
import { Pricing } from "../../../../../Interface/Purchase";
import DateTime from "../../../common-utility/DateTime";
import style from "../SetItemPrice.module.css";

const Accordian = ({ data }: any) => {
  let x: Pricing = data;

  const indices = x.ArchieveStore_id.map((x, index) => [x, index])
    .sort((a, b) => a[0] - b[0])
    .map((y) => y[1]);
  const sortedX = [] as any;
  indices.map((num) =>
    sortedX.push({
      ArchieveStore_id: x.ArchieveStore_id[num],
      Purchase_date: x.Purchase_date[num],
      Store_id: x.Store_id[num],
      Qty_avail: x.Qty_avail[num],
      _metric: x._metric,
      Unit_cp: x.Unit_cp[num],
      Pricing_required: x.Pricing_required[num],
      Live_status: x.Live_status[num],
    })
  );

  const header = [
    { name: "SNO", width: "4%" },
    { name: "ARCH. STOREID", width: "16%" },
    { name: "STORE ID", width: "10%" },
    { name: "PURCHASE DATE", width: "16%" },
    { name: "QTY. AVAILABLE", width: "14%" },
    { name: "UNIT CP", width: "15%" },
    { name: "NEED PRICING", width: "14%" },
    { name: "LIVE STATUS", width: "11%" },
  ];

  return (
    <>
      {sortedX.map((item: any, index: any) => (
        <div key={item.ArchieveStore_id} className={style.accrodiandiv}>
          <div className={style.header}>
            {index == 0
              ? header.map((item: any) => (
                  <div key={Math.random()} style={{ width: item.width }}>
                    {item.name}
                  </div>
                ))
              : ""}
          </div>

          {
            <div className={style.data}>
              <div style={{ width: header[0].width }}>{index + 1}</div>
              <div style={{ width: header[1].width }}>
                {item.ArchieveStore_id}
              </div>
              <div style={{ width: header[2].width }}>{item.Store_id}</div>
              <div style={{ width: header[3].width }}>
                {DateTime({
                  value: item.Purchase_date,
                  required: "date",
                })}
              </div>
              <div style={{ width: header[4].width }}>
                {item.Qty_avail}
                {""} {item._metric}
              </div>
              <div style={{ width: header[5].width }}>Rs {item.Unit_cp}</div>
              <div style={{ width: header[6].width }}>
                {item.Pricing_required}
              </div>
              <div style={{ width: header[7].width }}>{item.Live_status}</div>
            </div>
          }
        </div>
      ))}
    </>
  );
};

export default Accordian;
