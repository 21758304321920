import { useState, useEffect } from "react";
import ModalPopup from "../../../../customer-app/components/modal/ModalPopup";
import AddSupplier from "./AddSupplier";
import style from "./SearchCriteria.module.css";
import { BiSearch } from "react-icons/bi";
import query from "../../../data-centre/Queries";
import Dropdown from "../../common-utility/Dropdown";
import { useRef } from "react";
import { isObjectLiteralExpression } from "typescript";

function SearchCriteria({ listHandler }: any) {
  const [isAddingSupplierVisible, setAddingSupplier] = useState<boolean>(false);
  const [vendorNameList, setVendorNameList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [contactList, setContactList] = useState<any>([]);
  const [Message, setMessage] = useState<string>("");
  const [isCheckedCheckBox1, setCheckBox1] = useState<boolean | undefined>(
    false
  );
  const [isCheckedCheckBox2, setCheckBox2] = useState<boolean | undefined>(
    false
  );
  const [isCheckedCheckBox3, setCheckBox3] = useState<boolean | undefined>(
    false
  );

  useEffect(() => {
    getListDropdown();
  }, []);

  const getListDropdown = async () => {
    let fetchData: any = {};
    let name: any = [];
    let city: any = [];
    let contact: any = [];

    await query.Supplier.listColumn("all").then((response) => {
      fetchData = response.data;
    });

    fetchData.name.map((item: any, index: number) => {
      if (item !== "" || item != null) {
        name = [...name, { name: item, value: item }];
      }
    });
    fetchData.city.map((item: any, index: number) => {
      if (item !== "" && item != null) {
        city = [...city, { name: item, value: item }];
      }
    });
    fetchData.contact.map((item: any, index: number) => {
      if (item != null) {
        contact = [...contact, { name: item, value: item }];
      }
    });

    setVendorNameList((x: any) => (x = [...name]));
    setCityList((x: any) => (x = [...city]));
    setContactList((x: any) => (x = [...contact]));
  };

  const vendorNameDropdownHandler = (id: any) => {
    console.log();
  };

  const contactDropdownHandler = (id: any) => {
    console.log();
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const obj: any = {};
    if (e.target["vendorNameCheckbox"].checked === true) {
      obj.Name = e.target["vendorName"].value;
    }
    if (e.target["contactCheckbox"].checked === true) {
      obj.Contact = parseInt(e.target["contact"].value);
    }
    if (e.target["cityCheckbox"].checked === true) {
      obj.City = e.target["city"].value;
    }

    listHandler(obj);
  };

  return (
    <>
      {isAddingSupplierVisible && (
        <ModalPopup>
          <AddSupplier onClose={setAddingSupplier} />
        </ModalPopup>
      )}
      <div className={style["supplier-criteria-wrapper"]}>
        <div>
          <button
            onClick={() => {
              setAddingSupplier(true);
            }}
          >
            Add Supplier
          </button>
        </div>

        <form className={style["wrapper"]} onSubmit={submitHandler}>
          <div>
            <span>VendorName</span>
            <div>
              <Dropdown
                className={style.dropdown}
                list={vendorNameList}
                onClick={() => {}}
                name="vendorName"
                disable={false}
              />
              <input
                className={style.checkbox}
                type="checkbox"
                name="vendorNameCheckbox"
              />
            </div>
          </div>
          <div>
            <span>Contact</span>
            <div>
              <Dropdown
                className={style.dropdown}
                onClick={() => {}}
                list={contactList}
                name="contact"
                disable={false}
              />
              <input
                className={style.checkbox}
                type="checkbox"
                name="contactCheckbox"
              />
            </div>
          </div>

          <div>
            <span> City</span>
            <div>
              <Dropdown
                className={style.dropdown}
                name="city"
                list={cityList}
                onClick={() => {}}
                disable={false}
              />
              <input
                className={style.checkbox}
                type="checkbox"
                name="cityCheckbox"
              />
            </div>
          </div>

          <button type="submit">
            <BiSearch className={style.button} />
          </button>
        </form>
      </div>
    </>
  );
}

export default SearchCriteria;
