import {
  ItemAvailable,
  OrderItemDetail,
  OrderItemSummary,
  Store,
} from "../../../../../Interface/Purchase";
import { isNumber } from "../../../../../customer-app/utility/validation";
import valueCoversionHandler from "./valueConversionHandler";

export const storeQtyInputHandler = ({
  invoice,
  setManualEditable,
  qtyInput,
  metricChoosen,
  qtySizeValidation,
  setSubTotal,
  item,
  disable,
  promptHandler,
  setQtyInput,
  setMetricChoosen,
  mappedKeys,
  orderItemList,
  billingctx,
}: any) => {
  if (!invoice) {
    setManualEditable(false);
    if (isNumber(qtyInput) && metricChoosen) {
      qtySizeValidation(Number(qtyInput));
    } else {
      setSubTotal("");
    }
  } else {
    let message;
    if (Number(qtyInput) && metricChoosen && !disable) {
      let item_l: ItemAvailable = item;
      if (
        Number((item.Stock_available as number).toFixed(3)) <
        valueCoversionHandler(
          item_l._metric as string,
          item_l.Metric_rules as string,
          qtyInput,
          metricChoosen
        )
      ) {
        message = `Item availability is only  ${
          item.Stock_available as number
        } ${item._metric}`;
        promptMessage(
          { promptHandler, setQtyInput, setMetricChoosen },
          message
        );
        return;
      }
      let mappedIndex = mappedKeys.findIndex(
        (x: any) => x.findIndex((y: any) => y === item.Pricing_id) > -1
      );
      let orderItem = (orderItemList as OrderItemSummary[]).find(
        (x) => x.OrderItem_id === mappedKeys[mappedIndex][0]
      ) as OrderItemSummary;

      console.log(
        `orderitem qtyorder metric ${orderItem.Qty_order} ${orderItem.Qty_metric}
      \n metric rules is ${item_l.Metric_rules} qtyinput metrichoosed is ${qtyInput} ${metricChoosen} `,
        "orderitem is ",
        orderItem
      );
      if (
        (orderItem.Qty_order as number) <
        valueCoversionHandler(
          orderItem.Qty_metric as string,
          item_l.Metric_rules as string,
          qtyInput,
          metricChoosen
        )
      ) {
        message = `Quantity should be less than   ${orderItem.Qty_order}${orderItem.Qty_metric}`;
        promptMessage(
          { promptHandler, setQtyInput, setMetricChoosen },
          message
        );
        return;
      }

      let totalQty = 0;

      let OrderItemDetail_l = (
        billingctx.getOrderItemDetail() as OrderItemDetail[]
      ).find(
        (x) => x.OrderItem_id === mappedKeys[mappedIndex][0]
      ) as OrderItemDetail;

      for (
        let i = 0;
        i < (OrderItemDetail_l.Qtys_taken?.length as number);
        ++i
      ) {
        if (OrderItemDetail_l.Store_ids[i] !== item_l.Pricing_id) {
          totalQty =
            totalQty +
            Number(
              valueCoversionHandler(
                orderItem.Qty_metric as string,
                item_l.Metric_rules as string,
                (OrderItemDetail_l.Qtys_taken as [number])[i],
                (OrderItemDetail_l.Qtys_metric as [string])[i]
              )
            );
        } else {
          totalQty =
            totalQty +
            Number(
              valueCoversionHandler(
                orderItem.Qty_metric as string,
                item_l.Metric_rules as string,
                qtyInput,
                metricChoosen
              )
            );
        }
        console.log("totalqty: ", i, "=", totalQty);
      }

      if (
        Number(totalQty.toFixed(3)) >
        Number((orderItem.Qty_order as number).toFixed(3))
      ) {
        message = `Total Quantity should be less than ${orderItem.Qty_order} ${orderItem.Qty_metric}`;
        promptMessage(
          { promptHandler, setQtyInput, setMetricChoosen },
          message
        );
        return;
      }

      //item_l.Pricing_id is storeid for items add

      let orderItemDetailListArray: OrderItemDetail[] = [
        ...billingctx.getOrderItemDetail(),
      ];

      for (let item of orderItemDetailListArray) {
        for (let k = 0; k < item.Store_ids.length; k++) {
          if (item_l.Pricing_id === item.Store_ids[k]) {
            (item.Qtys_taken as number[])[k] = Number(qtyInput);
            (item.Qtys_metric as string[])[k] = metricChoosen;
          }
        }
      }

      billingctx.setOrderItemDetail([...orderItemDetailListArray]);
      orderItemDetailListArray = [];
    }
  }
};

const promptMessage = (
  { promptHandler, setQtyInput, setMetricChoosen }: any,
  message: string
) => {
  promptHandler(message);
  setQtyInput("");
  setMetricChoosen("");
};
