import { useState } from "react";
import OrderContext from "./Order-context";
import { userDetail } from "../../Interface/Purchase";

const OrderProvider = (props: any) => {
  const [nonAuthorizedSection, setNonAuthorizedSection] = useState<{
    tabs: string[];
    columns: string[];
    div: string[];
  }>({ tabs: [""], columns: [""], div: [""] });

  const [orderDates, setOrderDate] = useState<{ fromDate: any; endDate: any }>({
    fromDate: null,
    endDate: null,
  });

  const getOrderModuleAuthorizationHandler = () => {
    return nonAuthorizedSection;
  };

  const setOrderModuleAuthorizationHandler = (user: userDetail) => {
    if (
      user.userId === "triloksingh.prajapat" &&
      user.password === "SinghTrilok855$$"
    ) {
      setNonAuthorizedSection(
        (x) =>
          (x = {
            ...x,
            tabs: ["All Orders", "Yesterday"],
            columns: ["Edit", "Delete"],
            div: ["orderSearch", "hide"],
          })
      );
    }
  };

  const setOrderDateHandler = (orderDates: { fromDate: any; endDate: any }) => {
    setOrderDate(
      (x) =>
        (x = {
          ...x,
          fromDate: orderDates.fromDate,
          endDate: orderDates.endDate,
        })
    );
  };

  const getOrderDateHandler = () => {
    return orderDates;
  };

  const valueHandler = {
    getOrderModuleAuthorization: getOrderModuleAuthorizationHandler,
    setOrderModuleAuthorization: setOrderModuleAuthorizationHandler,
    setOrderDate: setOrderDateHandler,
    getOrderDate: getOrderDateHandler,
  };

  return (
    <OrderContext.Provider value={valueHandler}>
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
