import React from "react";
import { useState } from "react";
import LoaderUI from "../../../common-utility/LoaderUI";
import MessageModal from "../../../common-utility/MessageModal";
import Modal from "../../../common-utility/Modal";
import SubmitForm from "../basic/SubmitForm";

const EditProduct = ({item,cancel}: any) => {
  const [submitFormVisibility, setubmitFormVisibility] =
    useState<boolean>(true);
  const [messageVisibility, setMessageVisibility] = useState<boolean>(false);
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();

  const product: any = item;
  console.log("in edit" + product.Image);
  const messageHandler = (message: string) => {
    setMessage(message);
    setMessageVisibility(true);
  };

  const closeMessageHandler = () => {
    setMessageVisibility(false);
    cancel();
  };

  const loaderHandler = (visibility: boolean) => {
    if (visibility === true) {
      setubmitFormVisibility(!visibility);
    }
    setLoaderVisibility(visibility);
  };

  return (
    <>
      {submitFormVisibility && (
        <Modal>
          <SubmitForm
            cancel={cancel}
            product={product}
            message={messageHandler}
            loader={loaderHandler}
            type="edit"
          />
        </Modal>
      )}
      {console.log("message modal: ", messageVisibility)}
      {messageVisibility && (
        <MessageModal close={closeMessageHandler} message={message} />
      )}

      {loaderVisibility && (
        <LoaderUI content="Updating..." isDimmerActive={true} size="medium" />
      )}
    </>
  );
};

export default EditProduct;
