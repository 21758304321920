import { useState } from "react";
import LowerSection from "./lower-section/LowerSection";
import UpperSection from "./upper-compnents/UpperSection";

function ManagePurchase(props: any) {
  const [searchIds, setSearchId] = useState({});
  const [showListCheck, setShowList] = useState<boolean>(false);

  const filterHandler = (
    catgid: string,
    subcatgid: string,
    dateFrom: any,
    dateTo: any,
    time: any
  ) => {
    // if (catgid === "select") {
    //   setShowList(false);
    //   return;
    // }
    setShowList(true);
    setSearchId({
      catgid: catgid,
      subcatgid: subcatgid,
      dateFrom: dateFrom,
      dateTo: dateTo,
      time: time,
    });
    console.log("mp_catgid: " + catgid + " mp_subcatgid: " + subcatgid);
  };
  return (
    <>
      MANAGE PURCHASE
      <UpperSection filter={filterHandler} />
      {showListCheck ? (
        <LowerSection filterList={searchIds} />
      ) : (
        "NO LIST TO SHOW"
      )}
    </>
  );
}

export default ManagePurchase;
