// import { useState } from "react";
import style from "./MenuIconCard.module.css";

function MenuIconCard(props: any) {
  // const [bordercolor, setbordrcolor] = useState(style["wrapper-color-none"]);
  // const handler = () => {
  //   bordercolor === style["wrapper-color-none"]
  //     ? setbordrcolor(style["wrapper-color"])
  //     : setbordrcolor(style["wrapper-color-none"]);
  // };

  return (
    <div className={style["icon-name-wrapper"]}>
      <div className={style["icon"]}>{props.icon}</div>
      <div className={style["name"]}>{props.name}</div>
    </div>
  );
}

export default MenuIconCard;
