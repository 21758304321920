import { useState } from "react";
import { LiveStockMapperExtended } from "../../../../../Interface/Purchase";
import EditPopup from "../../../common-utility/EditPopup";
import query from "../../../../data-centre/Queries";
import style from "./Edit.module.css";
import DroplistU from "../../../common-utility/DroplistU";
import StringCaseUI from "../../../common-utility/StringCaseUI";

interface type {
  row: LiveStockMapperExtended;
  onClose: (x: any) => any;
  setLoader: any;
  setPromptMessage: any;
  loadData: (userType: string) => Promise<void>;
}

function Edit({ row, onClose, setLoader, setPromptMessage, loadData }: type) {
  const [rowValues, setRowValues] = useState(row);

  const resetHandler = () => {
    setRowValues(row);
  };

  const IsNegativeList = [
    { name: "true", value: true },
    { name: "false", value: false },
  ];

  const updateHandler = async (e: any) => {
    e.preventDefault();
    setLoader(true);
    await query.Livestock.edit(rowValues)
      .then((response) => {
        setLoader(false);
        if (response.statusText === "OK" && response.data.status === 1) {
          loadData(rowValues.UserType);
          setPromptMessage("Updated Successfully", true);
          onClose(undefined);
        } else {
          console.log("inside");
        }
      })
      .catch((error) => {
        console.log(error);
        setPromptMessage("there is some error", true);
        // onClose(undefined);
      });
  };

  return (
    <div className={style.editwrapper}>
      <EditPopup onClose={onClose} update={updateHandler} reset={resetHandler}>
        <div>
          <span>Product</span>
          <span>{rowValues?.Variant_name}</span>
        </div>
        <div>
          <span>Stock Id</span>
          <span>{rowValues?.Id}</span>
        </div>
        <div>
          <span>IsNegative Stock</span>
          <DroplistU
            style={{ color: "black" }}
            list={IsNegativeList}
            name="isnegativelist"
            value={rowValues.IsNegative_stock.toString()}
            required={true}
            onChange={(val: any) => {
              setRowValues(
                (x: LiveStockMapperExtended) =>
                  (x = {
                    ...x,
                    IsNegative_stock: val === "true" ? true : false,
                  })
              );
              console.log(typeof val);
            }}
          />
        </div>
        <div>
          <span>Status</span>
          <input
            type="text"
            name="product"
            value={rowValues?.LiveStock_status}
            onChange={(e) =>
              setRowValues(
                (x: LiveStockMapperExtended) =>
                  (x = { ...x, LiveStock_status: e.target.value })
              )
            }
            onBlur={() => {
              setRowValues(
                (x: LiveStockMapperExtended) =>
                  (x = {
                    ...x,
                    LiveStock_status: StringCaseUI(x.LiveStock_status),
                  })
              );
            }}
          />
        </div>
      </EditPopup>
    </div>
  );
}

export default Edit;
