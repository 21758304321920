import { useState } from "react";
import { menuIconStore } from "../../Data-Store/MenuIconCardData";

import MenuIconCard from "./MenuIconCard";
import style from "./ItemMenuIcons.module.css";

function MenuIcons(props: any) {
  const [checkIndex, setIndex] = useState(0);

  return (
    <div className={style["MenuIconCard-wrapper"]}>
      {menuIconStore.map((icon, index) => (
        <div
          onClick={() => {
            setIndex(index);
            props.onClick(icon.name);
          }}
          key={index}
          className={
            style["MenuIconCard"] +
            " " +
            (index === checkIndex ? style["MenuIconCard-border"] : null)
          }
        >
          <MenuIconCard key={index} name={icon.name} icon={icon.icon} />
        </div>
      ))}
    </div>
  );
}

export default MenuIcons;
