import { vendor } from "../../../../../../../common-objects/Vendor";
import query from "../../../../../../data-centre/Queries";
const p: any = vendor;
export function PurchaseItemDetailedStatusUpdate(event: any, id: number) {
  let result: string;
  vendor.Id = id;
  vendor.Vendor_name = event.target["vendor_name"].value;
  vendor.Bill_no = event.target["bill_no"].value;
  vendor.Bill_date = event.target["purchase_date"].value;
  if (event.target["purchase_date"].value !== "") {
    vendor.Bill_date = new Date(
      event.target["purchase_date"].value
    ).toISOString();
  }
  vendor.Purchase_status = event.target["purchase_status"].value;
  vendor.Payment_status = event.target["payment_status"].value;
  vendor.Payment_date = event.target["payment_date"].value;
  if (event.target["payment_date"].value !== "") {
    vendor.Payment_date = new Date(
      event.target["payment_date"].value
    ).toISOString();
  }
  vendor.Action_required = event.target["action_required"].value;
  vendor.Action_status = event.target["action_status"].value;
  vendor.Action_amount = parseFloat(event.target["action_amount"].value);
  if (event.target["action_amount_date"].value !== "") {
    vendor.AA_date = new Date(
      event.target["action_amount_date"].value
    ).toISOString();
  }
  vendor.Action_quantity = parseFloat(event.target["action_quantity"].value);
  vendor.Metric = event.target["metricList"].value;
  if (event.target["action_quantity_date"].value !== "") {
    vendor.AQ_date = new Date(
      event.target["action_quantity_date"].value
    ).toISOString();
  }
  // const b: any = query.VendorPurchase.edit(vendor).then((response) => {
  //   result = response.statusText;
  //   return result;
  // });
  return "done";
  // console.log("b: " + b);
}

export const Update = {
  up: () => {
    return query.VendorPurchase.edit(vendor);
  },
};
