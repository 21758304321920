import { createContext } from "react";
import {
  ItemAvailable,
  CustomerProfile,
  OrderDetail,
  OrderItemSummary,
  GstRules,
  OrderItemDetail,
} from "../../Interface/Purchase";

let orderDetail: any;
let orderItem: any;
let orderItemDetail: any;
let user: any;
let billingItems: any;
let newOrder: boolean;
let clearItems: boolean;
let pointerEvents: boolean;
let roleIndex: number;
let gstrules: GstRules[];
let invoiceNo: number | undefined;
let invoiceGenerate: string | undefined;

const context = {
  setOrderDetail: (obj: OrderDetail) => {},
  getOrderDetail: () => orderDetail,
  setOrderItem: (obj: OrderItemSummary[]) => {},
  getOrderItem: () => orderItem,
  setOrderItemDetail: (obj: OrderItemDetail[]) => {},
  getOrderItemDetail: () => orderItemDetail,
  setUserProfile: (obj: CustomerProfile) => {},
  getUserProfile: () => user,
  setBillinItems: (obj: ItemAvailable[]) => {},
  getBillingItems: () => billingItems,
  setNewOrder: (isNewOrder: boolean) => {},
  getNewOrder: () => newOrder,
  setClearItems: (isClearItems: boolean) => {},
  getClearItems: () => clearItems,
  setOrderPointerEvents: (event: boolean) => {},
  getOrderPointerEvents: () => pointerEvents,
  setInvoicePointerEvents: (event: boolean) => {},
  getInvoicePointerEvents: () => pointerEvents,
  setRoleIndex: (user: CustomerProfile) => {},
  getRoleIndex: () => roleIndex,
  setGstRules: () => {},
  getGstRules: () => gstrules,
  getInvoice: () => invoiceNo,
  setInvoice: (no: number | undefined) => {},
  getInvoiceGenerateType: () => invoiceGenerate,
  setInvoiceGenerateType: (invoiceGenerate: string | undefined) => {},
};
const BillingContext = createContext(context);

export default BillingContext;
