import { useEffect } from "react";
import { useState } from "react";
import { FaCircle } from "react-icons/fa";
import style from "./IconNotificationCount.module.css";

function IconNotificationCount({ itemsCount }: any) {
  const [value, setValue] = useState<number>(itemsCount);
  

  useEffect(() => {
    setValue(itemsCount);
  }, [itemsCount]);
  return (
    <>
      <div className={style["icon-circle-wrapper"]}>
        <FaCircle className={style["icon-circle"]} />
        <span>{value}</span>
      </div>
    </>
  );
}

export default IconNotificationCount;
