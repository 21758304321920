import { useState } from "react";
import style from "./ItemCard.module.css";

function ItemCard(props: any) {
  const [valueInKg, setKg] = useState<number>(props.kgValue);
  const [valueInGm, setGm] = useState<number>(props.gmValue);
  const [isVisibleAddInKg, setVisibilityForAddInKg] = useState<boolean>(
    props.kgValue > 0 ? false : true
  );
  const [isVisibleAddInGm, setVisibilityForAddInGm] = useState<boolean>(
    props.gmValue > 0 ? false : true
  );

  const minusHandlerKg = () => {
    let x = valueInKg;
    x = valueInKg > 0 ? x - 1 : x;
    setKg(x);
    if (x === 0) setVisibilityForAddInKg(true);
    cartValueHandler(x, "kg");
  };

  const plusHandlerKg = () => {
    let x = valueInKg;
    x = valueInKg < 8 ? x + 1 : x;
    setKg(x);
    cartValueHandler(x, "kg");
  };

  const minusHandlerGm = () => {
    let x = valueInGm;
    x = valueInGm > 0 ? x - 50 : x;
    setGm(x);
    if (x === 0) setVisibilityForAddInGm(true);
    cartValueHandler(x, "gm");
  };

  const plusHandlerGm = () => {
    let x = valueInGm;
    x = valueInGm < 900 ? x + 50 : x;
    setGm(x);
    cartValueHandler(x, "gm");
  };

  const cartValueHandler = (x: number, unitType: string) => {
    props.onClick({
      id: props.id,
      name: props.name,
      imagePath: props.image,
      kgValue: unitType === "kg" ? x : valueInKg,
      gmValue: unitType === "gm" ? x : valueInGm,
      totalPrice: 0,
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
    });
  };

  return (
    <div className={style["itemcard-wrapper"]}>
      <span className={style["item-discount"]}>10% OFF</span>
      <div className={style["image-div"]}>
        <img src={props.image} alt={"tomato"} />
      </div>
      <div className={style["itemProperties-combined-wrapper"]}>
        <div className={style["itemName-wrapper"]}>
          <p className={style.name}>{props.name}</p>
          <p className={style.hindiName}> {props.nameHindi} </p>
        </div>

        <div className={style["price-wrapper"]}>
          <span className={style["mrp-text"]}>MRP:&nbsp;&nbsp;</span>
          <span className={style["mrp-value"]}>Rs 25</span>
        </div>
        <div className={style["unit-wrapper"]}>
          <div className={style["unit-inner-wrapper"]}>
            <span className={style.primaryUnit}>
              {!isVisibleAddInKg ? (
                <>
                  <span className={style.minus} onClick={minusHandlerKg}>
                    -
                  </span>
                  {valueInKg}&nbsp;&nbsp;kg
                  <span className={style.plus} onClick={plusHandlerKg}>
                    +
                  </span>
                </>
              ) : (
                <span
                  className={style.add}
                  onClick={() => {
                    plusHandlerKg();
                    setVisibilityForAddInKg(false);
                  }}
                >
                  Add In kg
                </span>
              )}
            </span>
            <span className={style.secondaryUnit}>
              {!isVisibleAddInGm ? (
                <>
                  <span className={style.minus} onClick={minusHandlerGm}>
                    -
                  </span>
                  {valueInGm} &nbsp;&nbsp;gm
                  <span className={style.plus} onClick={plusHandlerGm}>
                    +
                  </span>
                </>
              ) : (
                <span
                  className={style.add}
                  onClick={() => {
                    plusHandlerGm();
                    setVisibilityForAddInGm(false);
                  }}
                >
                  Add In gm
                </span>
              )}
            </span>
          </div>

          <div
            className={
              valueInGm > 0 || valueInKg > 0
                ? style["quantity-selected-wrapper"]
                : style.dummystyle
            }
          >
            <div>
              {valueInKg > 0 ? valueInKg + "kg" : ""}
              {valueInGm > 0 ? valueInGm + "gm" : ""}
            </div>
            <span>{valueInGm > 0 || valueInKg > 0 ? "In Cart" : ""}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemCard;
