import style from "./Search.module.css";
import { BsSearch } from "react-icons/bs";
function Search(props: any) {
  return (
    <>
      <form
        autoComplete="off"
        className={style["search-form"]}
        onSubmit={(e) => {
          e.preventDefault();
          props.submitHandler(e);
        }}
      >
        <div className={style["search-button-wrapper"]}>
          <input
            placeholder="Search products here..."
            type="search"
            name="search"
            className={style.input}
          />
          <BsSearch className={style.searchbutton} />
        </div>
      </form>
    </>
  );
}

export default Search;
