import React from "react";
export default function StringCaseUI(inputstring: any) {
  let titleCase: string;

  if (inputstring.trim() === "") {
    titleCase = inputstring.trim();
  } else {
    titleCase = inputstring
      .trim()
      .split(" ")
      .map(
        (word: any) => word[0].toUpperCase() + word.substring(1).toLowerCase()
      )
      .join(" ");
  }

  return titleCase;
}
