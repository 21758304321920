import { useState, useEffect } from "react";
import SearchCriteria from "./components/SearchCriteria";
import SupplierListing from "./components/SupplierListing";
import style from "./Supplier.module.css";

function Supplier({ menuType }: any) {
  const [isMenuVisible, setMenu] = useState<boolean>(false);
  const [listingVisibility, setListingVisibility] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<any>();

  useEffect(() => {
    if (menuType === 1) {
      setMenu(true);
    } else {
      setMenu(false);
    }
  }, [menuType]);

  const listHandler = (e: any) => {
    setSearchOptions(e);
    console.log(e.Name);
    setListingVisibility(true);
  };

  return (
    <div className={style["wrapper"]}>
      {isMenuVisible ? (
        <>
          <SearchCriteria listHandler={listHandler} />
          {listingVisibility ? (
            <SupplierListing
              setTableVisibility={(x: any) => setListingVisibility(x)}
              e={searchOptions}
            />
          ) : (
            <h2 style={{ color: "black", textAlign: "center" }}>
              Do Some Valid Search For Data
            </h2>
          )}
        </>
      ) : null}
    </div>
  );
}

export default Supplier;
