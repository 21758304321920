import style from "./AddCategory.module.css";
import axios from "axios";
import { useState } from "react";
import { useContext } from "react";
import SubcategoryContext from "../../../data-centre/Subcategory-context";
import query from "../../../data-centre/Queries";

function AddCategory() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitted, setSubmitMessage] = useState<boolean>(false);

  const product = {
    Catg_Id: "",
    Name_English: "",
    Name_Hindi: "",
    Image: "",
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    product.Catg_Id = event.target["priority"].value;
    product.Name_English = event.target["category_name_eng"].value;
    product.Name_Hindi = event.target["category_name_hindi"].value;
    product.Image = event.target["upload_image"].value;
    addData(product);
  };

  async function addData(product: any) {
    if (isSubmitted === true) {
      setSubmitMessage(false);
    }
    setLoading(true);
    await query.Category.add(product).then((response) =>
      console.log("status:", response.statusText)
    );
    console.log("done");
    setLoading(false);
    setSubmitMessage(true);
  }

  return (
    <>
      <form onSubmit={submitHandler} className={style.form}>
        <div className={style.innerform}>
          <div className={style["label-input"]}>
            <label>Category (In English)</label>

            <input
              name="category_name_eng"
              required={true}
              placeholder="Category Name"
            />
          </div>
          <div className={style["label-input"]}>
            <label>Category (In Hindi)</label>
            <input
              name="category_name_hindi"
              required={true}
              placeholder="Category Name Hindi"
            />
          </div>
          <div className={style["label-input"]}>
            <label>Priority</label>
            <input
              name="priority"
              required={true}
              placeholder="EX: 1,2 etc..."
            />
          </div>
          <div className={style["label-input"]}>
            <label>Upload Image(If Any) </label>
            <input name="upload_image" />
          </div>
        </div>
        <div className={style.buttondiv}>
          <button type="submit">Add</button>
        </div>
      </form>
      {isLoading && <p>SENDING......</p>}
      {isSubmitted && <p>Added Successfully!!</p>}
      add component
    </>
  );
}

export default AddCategory;
