import { useState } from "react";
import { useEffect } from "react";
import query from "../../../../data-centre/Queries";
import LoaderUI from "../../../common-utility/LoaderUI";
import MessageModal from "../../../common-utility/MessageModal";
import Modal from "../../../common-utility/Modal";
import PurchaseItemDetailedStatus from "./listpurchase-component/PurchaseItemDetailedStatus";
import style from "./ListPurchase.module.css";
import { Item } from "semantic-ui-react";

function ListPurchase(props: any) {
  const { catgid, subcatgid, dateFrom, dateTo, time } = props.filterList;
  // console.log("mp_catgid: " + catgid + " mp_subcatgid: " + subcatgid);
  const [filterList, setFilterList] = useState<any>([]);
  const [isPurchaseVendorVisible, setPurchaseVendorVisibilty] =
    useState<boolean>(false);
  const [vendorPurchaseData, setPurchaseVendorData] = useState();
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
  const [messageModalVisibility, setMessageModalVisibility] =
    useState<boolean>(false);
  const [content, setContent] = useState<string>();

  const header = [
    { name: "Purchase Id", width: "7%" },
    { name: "Product", width: "10%" },
    { name: "Variant", width: "10%" },
    { name: "Quantity", width: "5%" },
    { name: "Unit MRP", width: "5%" },
    { name: "Unit CP", width: "5%" },
    { name: "Purchase Amount", width: "5%" },
    { name: "Purchase Date", width: "8%" },
    { name: "Entry Date", width: "8%" },
    { name: "Expiry", width: "4%" },
    { name: "Conversion Metric", width: "4%" },
    { name: "Brand", width: "4%" },
    { name: "Gst", width: "3%" },
    { name: "Other Tax", width: "3%" },
    { name: "UnitNetTax", width: "4%" },
    { name: "TaxType", width: "4%" },
    { name: "Action Status/More", width: "8%" },
    { name: "Remark", width: "3%" },
  ];

  const [purchaseInfo, setPurchseInfo] = useState<{
    totalItems: number;
    totalamt: number;
    purchaseincash: number;
    paymentdue: number;
  }>({ totalItems: 0, totalamt: 0, purchaseincash: 0, paymentdue: 0 });

  useEffect(() => {
    getList(catgid, subcatgid, dateFrom, dateTo, time);
  }, [time]);

  const getList = async (
    catgid: string,
    subcatgid: string,
    dateFrom: any,
    dateTo: any,
    time: any
  ) => {
    setLoaderVisibility(true);
    await query.Purchase.search(catgid, subcatgid, dateFrom, dateTo).then(
      (response) => {
        setContent("Loading...");
        if (response.statusText === "OK") {
          setFilterList(response.data);
          setLoaderVisibility(false);
        }
      }
    );
  };

  const handler = (data: any) => {
    props.action("edit", data);
  };

  const vendorPurchaseHandler = (id: any, visibility: boolean) => {
    setPurchaseVendorVisibilty(visibility);
    setPurchaseVendorData(id);
  };

  useEffect(() => {
    totalAmtHandler();
  }, [filterList]);

  const totalDueAmtHandler = (totalamt: number) => {
    const totalDueAmt = filterList.reduce(
      (sum: number, item: any) =>
        (sum = sum + (!item._isPaid ? Number(item.Purchase_amount) : 0)),
      0
    );
    setPurchseInfo(
      (x) =>
        (x = {
          ...x,
          paymentdue: totalDueAmt,
          purchaseincash: totalamt - totalDueAmt,
        })
    );
  };

  const totalAmtHandler = () => {
    const totalamt = filterList.reduce(
      (sum: number, item: any, index: number) =>
        (sum = sum + item.Purchase_amount),
      0
    );
    setPurchseInfo(
      (x) => (x = { ...x, totalamt: totalamt, totalItems: filterList.length })
    );
    totalDueAmtHandler(totalamt);
  };

  return (
    <>
      {loaderVisibility && (
        <LoaderUI content={content} isDimmerActive={true} size="small" />
      )}
      {messageModalVisibility && (
        <MessageModal
          message={content}
          close={() => {
            getList(catgid, subcatgid, dateFrom, dateTo, time);
            setMessageModalVisibility(false);
          }}
        />
      )}

      {isPurchaseVendorVisible && (
        <Modal>
          <PurchaseItemDetailedStatus
            loader={(visibility: boolean, content: string) => {
              setLoaderVisibility(visibility);
              setContent(content);
            }}
            modal={(visibility: boolean, content: string) => {
              setMessageModalVisibility(visibility);
              setContent(content);
              setPurchaseVendorVisibilty(!visibility);
            }}
            close={vendorPurchaseHandler}
            data={vendorPurchaseData}
          />
        </Modal>
      )}
      <div className={style.purchaseinfo}>
        <div>
          <div>
            <span>No. Of Items Purchase</span>
            <span>Rs. {purchaseInfo.totalItems}</span>
          </div>
          <div>
            <span>Total Amt Purchase</span>
            <span>Rs. {purchaseInfo.totalamt}</span>
          </div>
        </div>
        <div>
          <div>
            <span>Total Amt Purchase Cash</span>
            <span>Rs. {purchaseInfo.purchaseincash}</span>
          </div>
          <div>
            <span>Due For Payment</span>
            <span>Rs. {purchaseInfo.paymentdue}</span>
          </div>
        </div>
      </div>
      <table className={style["main-div-stocklist"]}>
        <thead>
          <tr>
            {header.map((head: any, index: any) => (
              <th style={{ width: head.width }} key={index}>
                {head.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filterList.map((row: any, index: any) => (
            <tr className={style.tableData} key={index}>
              <td style={{ width: header[0].width }}>{row.Id}</td>
              <td style={{ width: header[1].width }}>{row.Product}</td>
              <td style={{ width: header[2].width }}>{row.Variant}</td>
              <td style={{ width: header[3].width }}>
                {row.Quantity} {row.Metric}
              </td>
              <td style={{ width: header[4].width }}>₹ {row.Unit_mrp}</td>
              <td style={{ width: header[5].width }}>₹ {row.Unit_cp}</td>
              <td style={{ width: header[6].width }}>
                ₹ {row.Purchase_amount}
              </td>
              <td style={{ width: header[7].width }}>
                {new Date(row.Purchase_date).getDate() +
                  "-" +
                  (new Date(row.Purchase_date).getMonth() + 1) +
                  "-" +
                  new Date(row.Purchase_date).getFullYear()}
              </td>
              <td style={{ width: header[8].width }}>
                {new Date(row.Entry_at).getDate() +
                  "-" +
                  (new Date(row.Entry_at).getMonth() + 1) +
                  "-" +
                  new Date(row.Entry_at).getFullYear()}
              </td>
              <td style={{ width: header[9].width }}>{row.Expiry}</td>
              <td style={{ width: header[10].width }}>
                {row.Metric_conversion}
              </td>
              <td style={{ width: header[11].width }}>{row.Brand}</td>
              <td style={{ width: header[12].width }}>₹ {row.Gst}</td>
              <td style={{ width: header[13].width }}>₹ {row.Other_tax}</td>
              <td style={{ width: header[14].width }}>₹ {row.Net_tax}</td>
              <td style={{ width: header[15].width }}>{row.TaxOn_status}</td>
              <td style={{ width: header[16].width }}>
                {row.Action_status === "Pending" ? (
                  <p className={style.pending}>{row.Action_status}</p>
                ) : row.Action_status === "Completed" ? (
                  <p className={style.complete}>{row.Action_status}</p>
                ) : (
                  <p className={style.none}>{row.Action_status}</p>
                )}
                <button
                  className={style.status}
                  onClick={() => vendorPurchaseHandler(row.Id, true)}
                >
                  View Status
                </button>
              </td>
              <td style={{ width: header[17].width }}>{row.Remark}</td>
              <td>
                <button
                  className={style.edit}
                  onClick={() => props.action("edit", row)}
                  name="edit"
                  type="submit"
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className={style.delete}
                  onClick={() => props.action("delete", row.Id)}
                  name="delete"
                  type="submit"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
export default ListPurchase;
