import { createContext } from "react";

const context = {
  setValue: (x: boolean, y: any) => {},
  getValue: () => {
    return [];
  },
};

const StoreContext = createContext(context);

export default StoreContext;
