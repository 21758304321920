import { CustomerProfile } from "../../../../../../Interface/Purchase";
import ResultPopup from "../../../../common-components/ResultPopup";
import style from "../PlaceOrder.module.css";
import textboxstyle from "../../../../../../common-css/Textbox.module.css";

const InputSearch = ({
  searchBox,
  ctx,
  setPromptMessage,
  setPromptVisibility,
  // roleIndexHandler,
  setIndex,
  itemSelectedIndexHandler,
  setSearchBox,
  selectedTab,
  modifiedSearchList,
  index,
  searchList,
  addItemHandler,
  inputRef,
  elementsDisability,
}: any) => {
  return (
    <>
      <input
        disabled={elementsDisability}
        autoComplete="off"
        value={searchBox}
        onChange={(e) => {
          if (ctx.getRoleIndex() === -1) {
            setPromptMessage("Require User information with role !!!");
            setPromptVisibility(true);
            return;
          }
          // roleIndexHandler();
          setIndex(-1);
          itemSelectedIndexHandler(-1, -1);
          setSearchBox((x: any) => (x = e.target.value));
        }}
        className={textboxstyle.textbox}
        type="search"
        name="invoicesearch"
        placeholder={
          "Search product" +
          (selectedTab === 0
            ? " by name"
            : selectedTab === 1
            ? " by barcode"
            : selectedTab === 2
            ? " by code"
            : " ")
        }
        onKeyDown={(e: any) => {
          if (modifiedSearchList.length > 0) {
            if (e.key === "ArrowDown") {
              let ind = index;
              ind = ind === searchList.length - 1 ? 0 : ind + 1;
              setIndex((x: any) => (x = ind));
              if (index > -1 && ind != index) {
                inputRef.current[index].style.backgroundColor = "transparent";
              }
            } else if (e.key === "ArrowUp") {
              let ind = index;
              ind = ind === 0 || ind === -1 ? searchList.length - 1 : ind - 1;
              setIndex((x: any) => (x = ind));

              if (index > -1 && ind != index) {
                inputRef.current[index].style.backgroundColor = "transparent";
              }
            } else if (e.key === "Enter") {
              if (
                inputRef.current[index] !== undefined &&
                inputRef.current[index] !== null
              ) {
                addItemHandler(parseInt(inputRef.current[index].id));
              }
            }
          }
        }}
      />

      {modifiedSearchList.length > 0 && (
        <div className={style.searchresult}>
          <ResultPopup
            ref={inputRef}
            value={addItemHandler}
            list={modifiedSearchList}
          />
        </div>
      )}
    </>
  );
};

export default InputSearch;
