export const header = [
  "Purchase Id",
  "Product",
  "Variant",
  "Quantity",
  "Unit MRP",
  "Unit CP",
  "Purchase Amount",
  "Entry At",
  "End At",
  "Expiry",
  "Conversion Metric",
  "Brand",
  "Gst",
  "Other Tax",
  "UnitNetTax",
  "TaxType",
  "Action Status/More",
  "Remark",
];

export const purchase = {
  Id: 0,
  Product_id: "",
  Product: "",
  Variant: "",
  Quantity: 0,
  Metric: "",
  Unit_mrp: 0,
  Unit_cp: 0,
  Purchase_amount: 0,
  Remark: "",
  Entry_at: new Date("1 january 1900 00:00 UTC").toISOString(),
  End_at: null,
  Expiry: null,
  Metric_conversion: "",
  Brand: "",
  Gst: 0,
  Other_tax: 0,
  Batch_no: "",
  TaxOn_status: "",
  Net_tax: 0,
  Action_status: "None",
  ItemSearch_mode: "",
  Company_id: "",
  _Code: "",
};

export const purchaseStatusList = [
  { value: "Item Received", name: "Item Received" },
  { value: "Item Pending", name: "Item Pending" },
];

export const paymentStatusList = [
  { value: "Paid", name: "Paid" },
  { value: "Unpaid", name: "Unpaid" },
  { value: "Received", name: "Received" },
  { value: "Doutfull", name: "Doutfull" },
];

// export const purchaseStatusList_edit = [
//   { value: "Item Pending", name: "Item Pending" },
//   { value: "Item Received", name: "Item Received" },
//   { value: "Item Returned", name: "Item Returned" },
//   { value: "Item Exchanged", name: "Item Exchanged" },
//   { value: "Item Cancel", name: "Item Cancel" },
// ];

export const action_required_drop = [
  { value: "Return", name: "Return" },
  { value: "Partial Return", name: "Partial Return" },
  { value: "Exchange", name: "Exchange" },
  { value: "Partial Exchange", name: "Partial Exchange" },
  { value: "Cancel", name: "Cancel" },
];

export const action_status_drop = [
  { value: "None", name: "None" },
  { value: "Pending", name: "Pending" },
  { value: "Completed", name: "Completed" },
];

export const payment__afterAction_drop = [
  { value: "Pending", name: "Pending" },
  { value: "Paid", name: "Paid" },
  { value: "Recieved", name: "Recieved" },
];

export const taxOn_list = [
  { value: "Individual", name: "Individual" },
  { value: "Combined", name: "Combined" },
];

export const metricList = [
  { value: "kg", name: "kg" },
  { value: "gm", name: "gm" },
  { value: "pkt", name: "pkt" },
  { value: "carton", name: "carton" },
  { value: "pc", name: "pc" },
  { value: "sack", name: "sack" },
  { value: "mtr", name: "mtr" },
  { value: "mg", name: "mg" },
  { value: "crates", name: "crates" },
];
