import Slider from "../utility/Slider";
import { image1, image2, image3 } from "./SliderImages.js";

function SliderTemp() {
  const arr = [image1, image2];

  return (
    <>
      <Slider arr={arr} />
    </>
  );
}

export default SliderTemp;
