import axios from "axios";
import { useState } from "react";
import query from "../../../data-centre/Queries";
import ConfirmationModal from "../../common-utility/ConfirmationModal";
import LoaderUI from "../../common-utility/LoaderUI";
import StringCaseUI from "../../common-utility/StringCaseUI";

function EditSubCategory(props: any) {
  //const Uri = "http://localhost:5000/ProductSubCategory";
  const [isLoader, setLoader] = useState<boolean>(false);

  const { Category_Name, Catg_Id, Sub_Id, Name_English, Name_Hindi, Image } =
    props.value;

  const test = (e: any) => {
    e.preventDefault();
    const rowEdited = {
      Catg_Id: e.target["catgId"].value,
      Name_English: StringCaseUI(e.target["subcatgname"].value),
      Name_Hindi: e.target["subcatgnamehin"].value,
      Image: e.target["image"].value,
    };
    updateRow("editPopup", "ok", rowEdited, Sub_Id);
  };

  async function updateRow(
    type: string,
    action: string,
    rowEdited: any,
    Id: number
  ) {
    try {
      setLoader(true);
      await query.SubCategory.edit(Id, rowEdited).then((response) => {
        if (response.statusText === "OK") {
          const message = "Subcategory Updated Successfully with Id: " + Sub_Id;
          props.close(type, action, message);
        }
      });
    } catch (error) {
      console.log("error : ", error);
    }
  }

  const editHandler = (type: string, action: string) => {
    console.log("type:" + type, "action:" + action);
    props.close(type, action, "");
  };

  return (
    <>
      {!isLoader && (
        <ConfirmationModal>
          <form onSubmit={test}>
            <div>
              <table>
                <tr>
                  <th>Category Name</th>
                  <td>
                    <input
                      name="catgname"
                      defaultValue={Category_Name}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Category Id</th>
                  <td>
                    <input
                      name="catgId"
                      defaultValue={Catg_Id}
                      required={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Subcategory Id</th>
                  <td>
                    <input
                      name="subcatgId"
                      defaultValue={Sub_Id}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Subcategory Name</th>
                  <td>
                    <input
                      name="subcatgname"
                      defaultValue={Name_English}
                      required={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Subcategory Name(Hindi)</th>
                  <td>
                    <input
                      name="subcatgnamehin"
                      defaultValue={Name_Hindi}
                      required={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Image</th>
                  <td>
                    <input name="image" defaultValue={Image} required={true} />
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <button type="submit">Update</button>
              <button
                onClick={() => editHandler("editPopup", "cancel")}
                type="button"
              >
                Cancel
              </button>
            </div>
          </form>
        </ConfirmationModal>
      )}
      {isLoader && (
        <LoaderUI content="Updating..." isDimmerActive={true} size="medium" />
      )}
    </>
  );
}

export default EditSubCategory;
