import { ENETDOWN } from "constants";
import {
  CustomerProfile,
  GstRules,
  ItemAvailable,
  OrderDetail,
  OrderItemDetail,
  OrderItemSummary,
  // wrapper_order_detail_summary_itemdetail_user,
  wrapper_order_detail_summary_user,
} from "../../../../Interface/Purchase";
import BillingContext from "../../../data-centre/Billing-context";
import LoginContext from "../../../data-centre/Login-context";
import query from "../../../data-centre/Queries";
import PrintDocument from "../../billing-system/components/PrintDocument";
import LoaderSpinnerKit from "../../common-utility/LoaderSpinnerKit";
import MessageModal from "../../common-utility/MessageModal";
import OrderInformation from "./OrderDetailComponents/OrderInformation";
import OrdersDetailFilter from "./OrderDetailComponents/OrdersDetailFilter";
import OrdersDetailList from "./OrderDetailComponents/OrdersDetailList";
import style from "./OrdersDetail.module.css";
import { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import valueCoversionHandler from "../../billing-system/components/PlaceOrder/valueConversionHandler";
import moneyValueConversion from "./OrderDetailComponents/moneyValueConversion";
import OrderContext from "../../../data-centre/Order-context";

function OrdersDetail() {
  const [orderDetail, setOrderDetail] = useState<
    wrapper_order_detail_summary_user[]
  >([]);
  const [orderItemDetail, setOrderItemDetail] = useState<OrderItemDetail[]>([]);
  const [promptMessage, setPromptMessage] = useState<string>("");
  const [promptMessageVisibility, setPromptMessageVisibility] =
    useState<boolean>(false);
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
  const printingRef: any = useRef(null);
  const billingctx = useContext(BillingContext);
  const [orderToPrint, setOrderToPrint] = useState<{
    orderItemSummary: OrderItemSummary[];
    users: CustomerProfile;
    billingItems: ItemAvailable[];
    orderDetail: OrderDetail;
    gstRules: GstRules[];
  }>();

  const [loadIndicator, setLoadIndicator] = useState<number>(-2);
  const [loadIndicatorChecker, setLoadIndicatorChecker] =
    useState<boolean>(false);

  const info = {
    totalOrders: 0,
    notDelivered: 0,
    paynotrecieved: 0,
    totalRecievedAmt: 0,
    cashPaymentAmt: 0,
    OnlinePaymentAmt: 0,
    totalOrderAmt: 0,
    totalProfit: 0,
    totalLossAmt: 0,
    totalDueAmt: 0,
  };

  const [orderInfo, setOrderInfo] = useState<{
    totalOrders: number;
    notDelivered: number;
    paynotrecieved: number;
    totalRecievedAmt: number;
    cashPaymentAmt: number;
    OnlinePaymentAmt: number;
    totalOrderAmt: number;
    totalProfit: number;
    totalLossAmt: number;
    totalDueAmt: number;
  }>(info);

  const loginCtx = useContext(LoginContext);
  const orderCtx = useContext(OrderContext);

  const [nonAuthorizedSection, setNonAuthorizedSection] = useState<{
    initialLoad: boolean;
  }>({ initialLoad: false });

  const header = [
    { name: "SNo", width: "2%" },
    { name: "Order Id", width: "5%" },
    { name: "Customer Name", width: "10%" },
    { name: "CreatedAt", width: "9%" },
    {
      name: "Request Date",
      width: nonAuthorizedSection.initialLoad ? "6%" : "11%",
    },
    { name: "Delivery Date", width: "8%" },
    { name: "Invoice Status", width: "8%" },
    { name: "Offer Id", width: "5%" },
    { name: "Delivery Charge", width: "7%" },
    {
      name: "Order Amt.",
      width: nonAuthorizedSection.initialLoad ? "5%" : "10%",
    },
    { name: "Amt. Recieved", width: "7%" },
    { name: "Order Status", width: "5%" },
    { name: "Remark", width: "8%" },
    { name: "Print", width: "5%" },
    { name: "Edit", width: "5%" },
    { name: "Delete", width: "5%" },
  ];

  // useEffect(() => {
  //   if (
  //     loginCtx.getUser().userId === "triloksingh.prajapat" &&
  //     loginCtx.getUser().password === "SinghTrilok855$$"
  //   ) {
  //     setNonAuthorizedSection((x: any) => (x = { ...x, initialLoad: false }));
  //   } else {
  //     setNonAuthorizedSection((x: any) => (x = { ...x, initialLoad: true }));
  //   }
  // }, []);

  useEffect(() => {
    setRules();
    //orderListHandler({ fromdate: new Date() });
    orderListHandler({ fromDate: new Date() });
    orderCtx.setOrderDate({ fromDate: new Date(), endDate: null });
    return () => {
      setOrderDetail([]);
      setOrderInfo({} as any);
      setOrderToPrint({} as any);
    };
  }, []);

  const setRules = async () => {
    await billingctx.setGstRules();
  };

  const orderListHandler = async (obj: {}) => {
    setLoaderVisibility(true);
    //await query.Order.list(obj)
    await query.Order.listOrderItemComplete(obj)
      .then((response) => {
        if (response.statusText === "OK") {
          setLoaderVisibility(false);
          filterHandler(new Date().toUTCString(), response.data);
        } else {
          setLoaderVisibility(false);
          setPromptMessage("NO DATA FOUND!!!");
          setPromptMessageVisibility(true);
        }
      })
      .catch((error) => {
        setLoaderVisibility(false);
        setPromptMessage(error);
        setPromptMessageVisibility(true);
      });
    if (loadIndicator === 1) {
      setLoadIndicator(-2);
    }
  };

  const promptMessageHandler = (
    message: string,
    visibility: boolean,
    status: number
  ) => {
    setPromptMessage(message);
    setPromptMessageVisibility(visibility);
    setLoadIndicatorChecker(status === 1);
  };

  const filterHandler = (UserType: string, data: any) => {
    let arr = [...data];

    //setOrderDetail([...arr.filter((x) => x.UserType === UserType)]);

    setOrderInfo(info);
    if (data.length > 0) {
      data.map((item: wrapper_order_detail_summary_user) => {
        if (item.Order_status === "Pending") {
          setOrderInfo((x) => (x = { ...x, notDelivered: x.notDelivered + 1 }));
        }
        if (
          item.TotalAmt_recieved === null ||
          item.TotalAmt_recieved === undefined ||
          (item.TotalAmt_recieved as number) < item.OrderTotal_amt
        ) {
          setOrderInfo(
            (x) => (x = { ...x, paynotrecieved: x.paynotrecieved + 1 })
          );
        }
      });
      setOrderInfo((x) => (x = { ...x, totalOrders: data.length }));
    }
    setOrderDetail(data);

    arr = data = [];
  };

  const printHandler = (item: wrapper_order_detail_summary_user) => {
    const { orderItemSummaryMappers, users, _products, ...orderDetail } = item;
    setOrderToPrint({
      orderItemSummary: orderItemSummaryMappers,
      users: users,
      billingItems: _products,
      orderDetail: orderDetail,
      gstRules: billingctx.getGstRules(),
    });
  };

  useEffect(() => {
    return print;
  }, [orderToPrint]);

  const print = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Invoice",
    onBeforePrint: () => setLoaderVisibility(false),
  });

  const totalOrderAmtHandler = (
    orderDetailSummaryList: wrapper_order_detail_summary_user[]
  ) => {
    var totalOrderAmt = orderDetailSummaryList.reduce(
      (sum, item, index: number) => (sum = sum + item.OrderTotal_amt),
      0
    );
    setOrderInfo((x) => (x = { ...x, totalOrderAmt: totalOrderAmt }));
  };

  const totalProfitHandler = (
    orderDetailSummaryList: wrapper_order_detail_summary_user[]
  ) => {
    let profit: number = 0;
    let sum: number;
    let totalcp: number = 0;
    let netprofit: number = 0;
    for (let entity of orderDetailSummaryList) {
      profit = 0;
      for (let i = 0; i < entity.orderItemSummaryMappers.length; i++) {
        sum = 0;
        for (
          let j = 0;
          j <
          (entity.orderItemSummaryMappers[i].orderItemDetailMapperExtended
            ?.Qtys_taken?.length as number);
          j++
        ) {
          let valuemetric = Number(
            valueCoversionHandler(
              entity.orderItemSummaryMappers[i].Qty_metric as string,
              entity._products[i].Metric_rules as string,
              (
                entity.orderItemSummaryMappers[i].orderItemDetailMapperExtended
                  ?.Qtys_taken as number[]
              )[j],
              (
                entity.orderItemSummaryMappers[i].orderItemDetailMapperExtended
                  ?.Qtys_metric as string[]
              )[j]
            )
          );
          totalcp =
            valuemetric *
            moneyValueConversion(
              entity.orderItemSummaryMappers[i].Qty_metric as string,
              entity._products[i].Metric_rules as string,
              Number(
                entity.orderItemSummaryMappers[i].orderItemDetailMapperExtended
                  ?.Unit_cp[j]
              ),
              (
                entity.orderItemSummaryMappers[i].orderItemDetailMapperExtended
                  ?.Unit_cp_metric as string[]
              )[j]
            );
          sum = sum + totalcp;
        }
        profit =
          profit +
          (entity.orderItemSummaryMappers[i].Sub_total as number) -
          sum;
      }

      netprofit =
        netprofit +
        (entity.OrderInvoice_status.trim() === "frammed" ? profit : 0);
    }

    setOrderInfo(
      (x) => (x = { ...x, totalProfit: Number(netprofit.toFixed(2)) })
    );
  };

  useEffect(() => {
    totalProfitHandler(orderDetail);
    totalOrderAmtHandler(orderDetail);
  }, [orderDetail]);

  useEffect(() => {
    let user = loginCtx.getUser();
    orderCtx.setOrderModuleAuthorization(user);
  }, []);

  return (
    <>
      <div style={{ visibility: "hidden" }}>
        <PrintDocument
          orderDetail={orderToPrint?.orderDetail as OrderDetail}
          orderItemList={orderToPrint?.orderItemSummary as OrderItemSummary[]}
          userProfile={orderToPrint?.users as CustomerProfile}
          billingItems={orderToPrint?.billingItems as ItemAvailable[]}
          gstRules={orderToPrint?.gstRules as GstRules[]}
          ref={printingRef}
        />
      </div>
      <div className={style["order-detail-wrapper"]}>
        {promptMessageVisibility && (
          <MessageModal
            message={promptMessage}
            close={() => {
              setPromptMessageVisibility(false);
              if (loadIndicatorChecker) {
                setLoadIndicator(1);
              }
            }}
          />
        )}
        {loaderVisibility && (
          <LoaderSpinnerKit
            backColor="#686769"
            size={60}
            frontColor="#0dcaf0"
            loading={true}
          />
        )}
        { (
          <OrdersDetailFilter
            loadData={orderListHandler}
            setPromptMessage={promptMessageHandler}
          />
        )}
        <OrdersDetailList
          list={orderDetail}
          header={header}
          setLoader={setLoaderVisibility}
          setPromptMessage={promptMessageHandler}
          loadData={orderListHandler}
          loadIndicator={loadIndicator}
          print={printHandler}
        />

        <OrderInformation orderInfo={orderInfo} />
      </div>
    </>
  );
}

export default OrdersDetail;
