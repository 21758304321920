import { useState, useEffect } from "react";
import query from "../../../../data-centre/Queries";
import Dropdown from "../../../common-utility/Dropdown";
import SignSVG from "../../../common-utility/SignSVG";
import { BiSearch } from "react-icons/bi";
import style from "./Search.module.css";

function Search({ listHandler }: any) {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [productDetailList, setProductDetailList] = useState<any>([]);
  const [Message, setMessage] = useState<string>("");

  useEffect(() => {
    getCatgListDropdown();
  }, []);

  const getCatgListDropdown = async () => {
    await query.Category.listByName().then((response) => {
      if (response.statusText === "OK") {
        setCategoryList(response.data);
      } else {
        setMessage("there is some error in fetching category List Data");
      }
    });
  };

  const getSubCatgListDropdown = async (id: any, type: string) => {
    await query.SubCategory.detail(id, type).then((response) => {
      if (response.statusText === "OK") {
        setSubCategoryList(response.data);
      } else {
        setMessage("there is some error in fetching Subcategory List Data");
      }
    });
  };

  const getProductDropdown = async (type: string, id: any) => {
    await query.Product.droplist(type, id).then((response) => {
      if (response.statusText === "OK") {
        setProductList(response.data);
      } else {
        setMessage("there is some error in fetching product List Data");
      }
    });
  };

  const categoryDropdownHandler = (id: any) => {
    if (id === "") {
      setSubCategoryList([]);
      setProductList([]);
    } else getSubCatgListDropdown(id, "catg");
  };

  const subCategoryDropdownHandler = (id: any) => {
    if (id === "") {
      setProductList([]);
    } else getProductDropdown("subcategory", id);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    listHandler(e);
  };

  return (
    <>
      <form className={style["wrapper"]} onSubmit={submitHandler}>
        <div>
          <span>Category</span>
          <Dropdown
            list={categoryList}
            className={style.catg}
            onClick={categoryDropdownHandler}
            clicked={true}
            name="catgList"
            disable={false}
          />
        </div>
        <div>
          <span>SubCategory</span>
          <Dropdown
            className={style.subcatg}
            onClick={subCategoryDropdownHandler}
            list={subCategoryList}
            name="subCatgList"
            clicked={true}
            disable={false}
          />
        </div>

        <div>
          <span> Product</span>
          <Dropdown
            className={style.product}
            name="productList"
            list={productList}
            onClick={() => {}}
            disable={false}
          />
        </div>

        <div>
          <span>Choice</span>
          <Dropdown
            className={style.choice}
            name="choice"
            list={[
              { value: 1, name: "Non Bulk" },
              { value: 2, name: "Bulk" },
            ]}
            onClick={() => {}}
            disable={false}
          />
        </div>

        <button type="submit">
          <BiSearch className={style.button} />
        </button>
      </form>
    </>
  );
}

export default Search;
