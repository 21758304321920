import axios, { AxiosResponse } from "axios";
import { request } from "https";

//axios.defaults.baseURL = "http://localhost:5000/";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

//axios.defaults.baseURL = "https://www.sahajj.com/";

const responsebody = (response: AxiosResponse) => response;

const requests = {
  get: async (url: any) => await axios.get(url).then(responsebody),
  getFromBody: async (url: any, params: {}) =>
    await axios.get(url, { params }).then(responsebody),
  post: async (url: any, body: {}) =>
    await axios.post(url, body).then(responsebody),
  put: async (url: any, body: {}) =>
    await axios.put(url, body).then(responsebody),
  delete: async (url: any) => await axios.delete(url).then(responsebody),
};

const JsonRequest = {
  itemSearchModeList: () => requests.get("Json/ItemSearchModeList"),
};
const Category = {
  list: () => requests.get("ProductCategory"),
  detail: (id: any) => requests.get("ProductCategory/" + id),
  listByName: () => requests.get("ProductCategory/catgListbyName"),
  add: (body: {}) => requests.post("ProductCategory", body),
  edit: (id: any, body: {}) => requests.put("ProductCategory/" + id, body),
  remove: (id: any) => requests.delete("ProductCategory/" + id),
};

const SubCategory = {
  list: () => requests.get("ProductSubCategory"),
  detail: (id: any, type: string) =>
    requests.get("ProductSubCategory/" + id + "/" + type),
  add: (body: {}) => requests.post("ProductSubCategory", body),
  edit: (id: any, body: {}) => requests.put("ProductSubCategory/" + id, body),
  remove: (id: any) => requests.delete("ProductSubCategory/" + id),
};

const Product = {
  list: () => requests.get("Product"),
  detail: (id: any) => requests.get("Product/" + id),
  droplist: (searchType: any, id: any) =>
    requests.get("Product/list/" + searchType + "/" + id),
  search: (catgId: any, subcatg: any, productname: any) =>
    requests.get(
      "Product/search/" + catgId + "/" + subcatg + "/" + productname
    ),
  add: (body: {}) => requests.post("Product", body),
  edit: (id: any, body: {}) => requests.put("Product/" + id, body),
  remove: (id: any) => requests.delete("Product/" + id),
};

const Purchase = {
  add: (body: {}) => requests.post("Purchase", body),
  search: (catgId: any, subcatgId: any, dateFrom: any, dateTo: any) =>
    requests.get(
      "Purchase/search/" +
        catgId +
        "/" +
        subcatgId +
        "/" +
        dateFrom +
        "/" +
        dateTo
    ),
  edit: (id: any, body: {}) => requests.put("Purchase/" + id, body),
  remove: (id: any) => requests.delete("Purchase/" + id),
};

const VendorPurchase = {
  search: (id: any) => requests.get("VendorProductsStatus/search/" + id),
  edit: (body: {}) => requests.put("VendorProductsStatus", body),
  remove: (id: any) => requests.delete("VendorProductsStatus/" + id),
};

const BufferStock = {
  list: () => requests.get("BufferStore"),
  edit: (body: {}) => requests.put("BufferStore", body),
};

const Store = {
  list: () => requests.get("Store"),
  search: (catgId: any, subcatgId: any, productname: any, choice: number) =>
    requests.get(
      "Store/" + catgId + "/" + subcatgId + "/" + productname + "/" + choice
    ),
  edit: (type: string, body: {}) => requests.put("Store/" + type, body),
};

const ArchieveStore = {
  list: () => requests.get("ArchieveStore/Check"),
};

const Supplier = {
  list: () => requests.get("Supplier"),
  listColumn: (name: string) => requests.get("Supplier/SearchColumn/" + name),
  Search: (params: {}) => requests.getFromBody("Supplier/Search", params),
  add: (body: {}) => requests.post("Supplier", body),
  edit: (body: {}) => requests.put("Supplier", body),
  remove: (Id: number) => requests.delete("Supplier/" + Id),
};
const Pricing = {
  list: (params: {}) => requests.getFromBody("Pricing", params),
  add: (body: {}) => requests.post("Pricing", body),
  edit: (body: {}) => requests.put("Pricing", body),
};

const Billing = {
  Search: (params: {}) => requests.getFromBody("Billing/Search", params),
};
const MetricRules = {
  list: () => requests.get("MetricRules"),
};
const GstRules = {
  list: () => requests.get("GstRules"),
};
const Customer = {
  list: (params: {}) => requests.getFromBody("Customer", params),
  add: (body: {}) => requests.post("Customer", body),
};

const Order = {
  list: (params: {}) => requests.getFromBody("Orders", params),
  listOrderItemComplete: (params: {}) =>
    requests.getFromBody("Orders/orderlist/item/detail", params),
  listItemDetail: (params: {}) =>
    requests.getFromBody("Orders/get/order/itemDetail", params),
  addItemDetail: (params: {}) =>
    requests.post("Orders/add/order/itemDetail", params),
  add: (body: {}) => requests.post("Orders", body),
  edit: (body: {}) => requests.put("Orders/orderDetail", body),
};

const Livestock = {
  list: (params: {}) => requests.getFromBody("LiveStock", params),
  edit: (body: {}) => requests.put("LiveStock", body),
};

const query = {
  Category,
  SubCategory,
  Product,
  Purchase,
  VendorPurchase,
  BufferStock,
  Store,
  JsonRequest,
  Supplier,
  ArchieveStore,
  Pricing,
  Billing,
  MetricRules,
  GstRules,
  Customer,
  Order,
  Livestock,
};

export default query;
