import SubcategoryContext from "./Subcategory-context";
import { useReducer } from "react";
import { useState } from "react";

const inititalstate = [] as any;

const reducer = (state: any, action: any) => {
  if (action.type === "update") {
    return [...state, action.item];
  }
};

const SubcategoryProvider = (props: any) => {
  const [state, dispatcher] = useReducer(reducer, inititalstate);

  const updatehandler = (item: any) => {
    dispatcher({ type: "update", item: item });
  };

  // const SubcategoryProvider = (props: any) => {
  //   const [Item, setItem] = useState<any>([]);
  //   const arr: any[] = [];
  //   const updatehandler = (item: any) => {
  //     arr.push(item);
  //     setItem(arr);
  //   };

  const valueTransferred = { items: state, update: updatehandler };
  //const valueTransferred = { items:  Item, update: updatehandler };

  return (
    <SubcategoryContext.Provider value={valueTransferred}>
      {props.children}
    </SubcategoryContext.Provider>
  );
};

export default SubcategoryProvider;
