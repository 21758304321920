import { useState, useEffect } from "react";
import Address from "../../../customer-app/components/Customers/address/Address";
import Registration from "../../../customer-app/components/Customers/registration/Registration";
import ManageCustomers from "./customer-actions/ManageCustomers";

function Customer(props: any) {
  const subMenu: number = props.menuType;
  const [menuNo, setMenu] = useState<number>(subMenu);

  useEffect(() => {}, [subMenu]);

  return (
    <>
      {subMenu === 1 ? (
        <ManageCustomers />
      ) : subMenu === 2 ? (
        <Registration />
      ) : subMenu === 3 ? (
        <Address />
      ) : null}
    </>
  );
}

export default Customer;
