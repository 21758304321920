import { useState, useEffect } from "react";
import { header } from "../../../../../common-objects/BufferStore";
import { metricList } from "../../../../../common-objects/Purchase";
import { BufferStore, purchase } from "../../../../../Interface/Purchase";
import query from "../../../../data-centre/Queries";
import DateTime from "../../../common-utility/DateTime";
import Dropdown from "../../../common-utility/Dropdown";
import Errror from "../../../common-utility/Error";
import MessageModal from "../../../common-utility/MessageModal";
import ClickableHeader from "./ClickableHeader";
import style from "./LowerSection.module.css";

function LowerSection() {
  const [bufferList, setBufferList] = useState<any>([]);
  const [modalState, setModalState] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [messageVisibility, setMessageVisibility] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<any>([]);
  const [checkBoxState, setCheckBoxState] = useState<boolean>(false);

  const [rowObj, setRowObject] = useState<any>();
  const obj = {
    bufferId: [] as any,
    batchNo: [] as any,
    quantityOut: [] as any,
    metric: [] as any,
    ItemSearchMode: [] as any,
    Product_id: [] as any,
    ind: [] as any,
  };

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    await query.BufferStock.list()
      .then((response) => {
        if (response.statusText === "OK") {
          setBufferList(response.data);
        }
      })
      .catch((error) => console.log("error" + error));
  };

  // HeaderFunction("");

  const BufferStoreForwardHandler = (e: any) => {
    e.preventDefault();
    // const p = {
    //   Id: obj.bufferId,
    //   Batch_no: obj.batchNo,
    //   Quantity_out: obj.quantityOut,
    //   Metric_out: obj.metric,
    // };

    // p.Id.map((id: any) => console.log("id: " + id));
    // p.Batch_no.map((id: any) => console.log("id: " + id));
    // p.Quantity_out.map((id: any) => console.log("id: " + id));

    query.BufferStock.edit({
      Id: obj.bufferId,
      Batch_no: obj.batchNo,
      Quantity_out: obj.quantityOut,
      Metric_out: obj.metric,
      ItemSearch_mode: obj.ItemSearchMode,
      Product_id: obj.Product_id,
    })
      .then((response) => {
        if (response.statusText === "OK") {
          setMessage("Forwarded Successfully !!!");
          getList();
        } else {
          setMessage("There is some error !!!");
        }
        setModalState(true);
      })
      .catch((error) => {
        setMessage(error);
        setModalState(true);
      });
  };

  return (
    <>
      {modalState && (
        <MessageModal message={message} close={() => setModalState(false)} />
      )}
      {messageVisibility && <Errror message={message} />}
      <form
        className={style["table-wrapper"]}
        onSubmit={(e: any) => BufferStoreForwardHandler(e)}
      >
        <table className={style.table}>
          <thead>
            <tr>
              {header.map((head: any, index: number) => (
                <th key={index}>{head}</th>
              ))}
              <th>
                <button type="submit">Forward</button>
                <input
                  onChange={(e) => {
                    var x: any = document.getElementsByName("checkbox");
                    var z: any = document.getElementsByName("quantity_input");
                    var k: any = document.getElementsByName("metric_input");

                    if (e.target.checked) {
                      for (let i = 0; i < bufferList.length; i++) {
                        x[i].checked = true;
                        z[i].value = bufferList[i].Quantity_avail;
                        z[i].disabled = true;
                        k[i].value = bufferList[i].Metric;
                        k[i].disabled = true;
                        obj.bufferId = [...obj.bufferId, bufferList[i].Id];
                        obj.batchNo = [...obj.batchNo, bufferList[i].Batch_no];
                        obj.ItemSearchMode = [
                          ...obj.ItemSearchMode,
                          bufferList[i].ItemSearch_mode,
                        ];
                        obj.quantityOut = [
                          ...obj.quantityOut,
                          bufferList[i].Quantity_avail,
                        ];
                        obj.metric = [...obj.metric, bufferList[i].Metric];
                        obj.Product_id = [
                          ...obj.Product_id,
                          bufferList[i].Product_id,
                        ];
                        obj.ind = [...obj.ind, i];
                      }
                      // setRowObject(obj);
                    } else {
                      for (let i = 0; i < bufferList.length; i++) {
                        x[i].checked = false;
                        z[i].value = 0;
                        z[i].disabled = false;
                        k[i].value = "";
                        k[i].disabled = false;
                        obj.bufferId.splice(i, 1);
                        obj.batchNo.splice(i, 1);
                        obj.quantityOut.splice(i, 1);
                        obj.metric.splice(i, 1);
                        obj.ItemSearchMode.splice(i, 1);
                        obj.Product_id.splice(i, 1);
                        obj.ind.splice(i, 1);
                      }
                      //setRowObject(obj);
                    }
                  }}
                  type="checkbox"
                  name="checkboxHead"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {bufferList.map((row: BufferStore, index: number) => {
              return (
                <tr key={index}>
                  <td>{row.Id}</td>
                  <td>{row.Product_name}</td>
                  <td>{row.Variant_name}</td>
                  <td>{row.Batch_no}</td>
                  {/* <td>{row.Created_at}</td> */}
                  <td>
                    {row.Quantity_in}
                    {row.Metric}
                  </td>
                  <td>₹ {row.Unit_mrp}</td>
                  <td>₹ {row.Unit_cp}</td>
                  <td>₹ {row.Purchase_amt}</td>
                  <td>
                    {DateTime({
                      value: new Date(row.Purchase_date),
                      required: "date",
                    })}
                  </td>
                  <td>
                    {parseInt(
                      DateTime({
                        value: new Date(row.Expiry_date),
                        required: "date",
                      }).split("-")[3]
                    )}
                  </td>
                  <td>{row.Metric_conversion}</td>
                  <td>{row.Brand}</td>
                  <td>{row.Gst}</td>
                  <td>{row.ItemSearch_mode}</td>
                  {/* <td>{row.Other_tax}</td> */}
                  <td>{row.Net_tax}</td>
                  {/* <td>{row.TaxOn_status}</td> */}
                  <td>
                    {row.Quantity_avail === 0 || row.Quantity_avail === null
                      ? ""
                      : row.Quantity_avail + row.Metric_avail}
                  </td>
                  <td>
                    <input
                      style={{ width: "3em", textAlign: "center" }}
                      name="quantity_input"
                      type="number"
                      step={0.001}
                      min={0}
                      required={true}
                      defaultValue={0}
                      disabled={false}
                      onFocus={(e) => {
                        e.currentTarget.style.backgroundColor = "";
                      }}
                      onChange={(e) => {
                        if (parseFloat(e.target.value) > row.Quantity_avail) {
                          {
                            setMessage(
                              "value should be less than or equal to " +
                                row.Quantity_avail
                            );
                            e.target.style.backgroundColor = "yellow";
                            setModalState(true);
                          }
                          e.target.value = "0";
                        }
                      }}
                    />

                    <Dropdown
                      style={{ width: "3em" }}
                      name={"metric_input"}
                      list={metricList}
                      disable={false}
                      onClick={() => {}}
                    />
                  </td>
                  <td>
                    <input
                      name={"checkbox"}
                      type="checkbox"
                      onChange={(e) => {
                        var x: any = document.getElementsByName("checkboxHead");
                        var y: any = document.getElementsByName("checkbox");
                        var z: any =
                          document.getElementsByName("quantity_input");
                        var i: any = document.getElementsByName("metric_input");

                        if (x[0].checked === true) {
                          y[index].checked = !y[index].checked;
                          return;
                        }
                        if (e.target.checked) {
                          if (z[index].value === "0") {
                            z[index].value = row.Quantity_avail;
                          }
                          i[index].value = row.Metric;
                          i[index].disabled = true;
                          z[index].disabled = true;
                          obj.bufferId.push(row.Id);
                          obj.batchNo.push(row.Batch_no);
                          obj.ItemSearchMode.push(row.ItemSearch_mode);
                          obj.quantityOut.push(parseFloat(z[index].value));
                          obj.metric.push(row.Metric);
                          obj.Product_id.push(row.Product_id);
                          obj.ind.push(index);

                          //setRowObject(obj);
                        } else {
                          let _index = obj.ind.indexOf(index);
                          z[index].value = 0;
                          z[index].disabled = false;
                          i[index].disabled = false;
                          i[index].value = "";
                          obj.bufferId.splice(_index, 1);
                          obj.batchNo.splice(_index, 1);
                          obj.quantityOut.splice(_index, 1);
                          obj.metric.splice(_index, 1);
                          obj.ItemSearchMode.splice(_index, 1);
                          obj.Product_id.splice(_index, 1);
                          obj.ind.splice(_index, 1);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </form>
    </>
  );
}

export default LowerSection;
