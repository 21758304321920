import { useRef, useState, useEffect } from "react";
import style from "./SearchableDropdown.module.css";
import textboxstyle from "../../common-css/Textbox.module.css";
export default function SearchableDropdown(props: any) {
  const { id, listArray, labelName, styles, ...inputprop } = props;

  const [displayList, setDisplayList] = useState<any[]>([]);
  const [focusIndex, setFocusIndex] = useState<number>(-1);
  const inputref: any = useRef(null);
  const spanRef = useRef([] as any);

  useEffect(() => {
    if (inputprop.name === "country" || inputprop.name === "state") {
      inputref.current.value = listArray[0].name1;
      inputref.current.disabled = true;
    }
  });

  useEffect(() => {
    setDisplayList((item) =>
      item.map((x) => {
        return x.sno === focusIndex
          ? { ...x, color: "lightgray" }
          : { ...x, color: "" };
      })
    );
    return focusIndex > -1
      ? spanRef.current[focusIndex].scrollIntoView({ behavior: "smooth" })
      : "";
  }, [focusIndex]);

  // const invalidHandler = (e: any) => {
  //   return e.target.name === "city"
  //     ? e.target.setCustomValidity("please enter valid data")
  //     : null;
  // };

  const onmousedownHandler = (x: any) => {
    inputref.current.value = x.name1;
    setDisplayList([]);
  };

  //#region ONCHANGE HANDLER
  const changeHandler = (e: any) => {
    e.target.setCustomValidity("");
    let val = e.target.value;
    inputref.current.value = val;
    setFocusIndex(-1);
    let count = val.length;
    setDisplayList([]);
    let filterArray = [] as any;
    if (count > 0) {
      filterArray = listArray.filter((x: any) =>
        // x.name.toLowerCase().includes(inputRef.current.value.toLowerCase()) ?(x.state.toLowerCase().includes("rajasthan")?true:false):false)
        x.name1.toLowerCase().includes(val.toLowerCase())
      );
    }
    filterArray
      .sort((x: any, y: any) => x.name1.localeCompare(y.name1))
      .map((x: any, index: any) => {
        setDisplayList((p) => [
          ...p,
          {
            sno: index,
            name1: x.name1,
            name2: x.name2,
            color: styles.color,
          },
        ]);
      });
  };
  //#endregion

  const keyDownHandler = (e: any) => {
    return e.key === "ArrowDown" && displayList.length > 0
      ? arrowDown()
      : e.key === "ArrowUp" && displayList.length > 0
      ? arrowUp()
      : null;
  };

  const arrowDown = () => {
    let index = focusIndex;
    index = index >= -1 && index < displayList.length - 1 ? index + 1 : 0;
    setFocusIndex((x) => (x = index));
  };
  const arrowUp = () => {
    let index = focusIndex;
    index = index === -1 || index === 0 ? displayList.length - 1 : index - 1;
    setFocusIndex((x) => (x = index));
  };

  const keyPressHandler = (e: any) => {
    inputref.current.value =
      e.key === "Enter"
        ? focusIndex > -1
          ? displayList[focusIndex].name1
          : ""
        : inputref.current.value;
    setDisplayList((x) => []);
  };

  return (
    <div key={id} className={style["city-wrapper"]}>
      <span className={style.names}>{labelName}</span>

      <input
        className={textboxstyle.textbox}
        onChange={changeHandler}
        {...inputprop}
        ref={inputref}
        onKeyDown={keyDownHandler}
        onKeyPress={keyPressHandler}
        onBlur={(e: any) => {
          setDisplayList((x) => []);
          let countFlag = 0;
          if (e.target.value.length > 0 && e.target.name === "city") {
            for (let city of listArray) {
              if (city.name1.toLowerCase() === e.target.value.toLowerCase()) {
                countFlag = 1;
                e.target.value = city.name1;
                return;
              }
            }
            if (countFlag === 0) {
              e.target.setCustomValidity("please enter valid data");
            }
          }
        }}
      />

      {displayList.length > 0 ? (
        <div className={style.citylist}>
          {displayList.map((list: any, index: any) => {
            return (
              <span
                key={list.sno}
                className={style.span}
                style={{ backgroundColor: list.color }}
                onMouseDown={() => {
                  onmousedownHandler(list);
                }}
                ref={(t) => (spanRef.current[index] = t)}
              >
                {list.sno +
                  1 +
                  ". " +
                  list.name1 +
                  "" +
                  (list.name2 !== "" ? "-" + list.name2 : "")}
              </span>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
