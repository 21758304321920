
const valueCoversionHandler = (
  referenceMetric: string,
  metricRule: string,
  qty: number,
  qtyMetric: string
) => {
  let rules = metricRule.split(",");

  return qtyMetric === referenceMetric
    ? qty
    : qtyMetric === rules[1]
    ? Number((Number(rules[2]) * qty).toFixed(3))
    : Number((qty / Number(rules[2])).toFixed(3));
};

export default valueCoversionHandler


