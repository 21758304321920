import { useEffect, useState } from "react";
import {
  paymentStatusList,
  purchaseStatusList,
  taxOn_list,
} from "../../../../common-objects/Purchase";
import Button from "../../../../common-elements/Button";
import query from "../../../data-centre/Queries";
import Currency from "../../common-utility/Currency";
import Dropdown from "../../common-utility/Dropdown";
import SignSVG from "../../common-utility/SignSVG";
import style from "./AddForm.module.css";
import DateTime from "../../common-utility/DateTime";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import Droplist from "../../common-utility/Droplist";
import MessageModal from "../../common-utility/MessageModal";
import DroplistU from "../../common-utility/DroplistU";
import { isNumber } from "../../../../customer-app/utility/validation";
import { MdRsvp } from "react-icons/md";

function AddForm(props: any) {
  const [catgDropdownList, setCatgDropdownList] = useState<any>([]);
  const [subcatgDropdownList, setSubcatgDropdownList] = useState<any>([]);
  const [productDropdownList, setProductDropdownList] = useState<any>([]);
  const [variantDropdownList, setVariantDropdownList] = useState<any>([]);
  const [isSubcatgDropdownVisible, setSubcatgDropdownVisibility] =
    useState<boolean>(false);
  const [isProductDropdownVisible, setProductDropdownVisibility] =
    useState<boolean>(false);
  const [isVariantDropdownVisible, setVariantDropdownVisibility] =
    useState<boolean>(false);
  const [isMetricConversionRowVisible, setMetricConversionRowVisibility] =
    useState<boolean>(false);
  const [metricConversionRow, setMetricConversionRow] = useState<any>([]);
  const [cp_State, setUnitCP] = useState<any>(0);
  const [mrp_State, setUnitMRP] = useState<any>("");
  const [quantity_State, setQuantity] = useState<any>();
  const [itemSearchModeValues, setItemSearchModeList] = useState<[]>([]);
  const [searchModeSelected, setSearchModeSelected] = useState<string>("");
  const [vendorList, setVendorList] = useState<any>([]);
  const [defaultBarcode, setDefaultBarcode] = useState<string>("");
  const [selectedProductVariant, setProductVariant] = useState<string>("");
  const [selectedPurchaseDate, setPurchaseDate] = useState<string>("");
  const [dropdownMetricList, setDropdownMetricList] = useState<any>([]);
  const [metricList, setMetricList] = useState<any>([]);
  const [selectRules, setSelectRules] = useState<any>([]);
  const [rulesAddVisibility, setRulesAddVisibility] = useState<boolean>(false);
  const [destinput, setDestinput] = useState<string>("");
  const [MessagemodalVisibile, setMessageModalVisibility] =
    useState<boolean>(false);
  const [messageModal, setMessageModal] = useState<any>("");
  const [purchaseAmt, setPurchaseAmt] = useState<number>(0);

  useEffect(() => {
    getCatgList();
    itemSeacrhHandler();
    getMetricHandler();
    return function cleanup() {
      setCatgDropdownList([]);
      setItemSearchModeList([]);
      setMetricList([]);
      setDropdownMetricList([]);
      setSelectRules([]);
      setDestinput("");
    };
  }, []);

  ///** BARCODE CODE SETTING */
  // useEffect(() => {
  //   if (searchModeSelected === "Other Code") {
  //     setDefaultBarcode(
  //       (x) =>
  //         (x =
  //           selectedProductVariant.trim() +
  //           "::" +
  //           selectedPurchaseDate.split("-").join(":"))
  //     );
  //   }
  // }, [selectedProductVariant, selectedPurchaseDate]);

  const itemSeacrhHandler = async () => {
    await query.JsonRequest.itemSearchModeList().then((response) =>
      response.statusText === "OK" ? setItemSearchModeList(response.data) : ""
    );
  };
  let list: any = [];
  let uniqueList: any = [];
  let mlist: any = [];
  const getMetricHandler = async () => {
    await query.MetricRules.list()
      .then((response) => {
        if (response.statusText === "OK") {
          setMetricList(response.data);
          response.data.map((item: any, index: number) => {
            list = [...list, item.SourceMetric];
          });
          uniqueList = Array.from(new Set(list));
          uniqueList.map(
            (x: any) => (mlist = [...mlist, { name: x, value: x }])
          );
          setDropdownMetricList(mlist);
          uniqueList = [];
        } else {
          messageModalHandler("not found");
        }
      })
      .catch((error) => messageModalHandler(error));
  };

  const getCatgList = async () => {
    await query.Category.listByName().then((response) =>
      setCatgDropdownList(response.data)
    );
    let vendrls = [] as any;
    await query.Supplier.Search({}).then((response) => {
      response.data.map(
        (item: any) =>
          (vendrls = [
            ...vendrls,
            { value: item.Id, name: item._name + " - " + item._contact1 },
          ])
      );
    });
    setVendorList((x: any) => (x = [...vendrls]));
  };

  let obj;
  const catgDropdownHandler = (value: any) => {
    if (value !== "") {
      query.SubCategory.detail(value, "catg").then((response) =>
        setSubcatgDropdownList(response.data)
      );
      setSubcatgDropdownVisibility(true);
      setProductDropdownVisibility(false);
    } else {
      setSubcatgDropdownVisibility(false);
      setProductDropdownVisibility(false);
    }
  };

  const subcatgDropdownHandler = (value: any) => {
    if (value !== "") {
      query.Product.droplist("subcategory", value).then((response) =>
        setProductDropdownList(response.data)
      );
      setProductDropdownVisibility(true);
    } else {
      setProductDropdownVisibility(false);
    }
  };

  const productDropdownHandler = (value: any) => {
    if (value !== "") {
      query.Product.droplist("product", value).then((response) =>
        setVariantDropdownList(response.data)
      );
      setVariantDropdownVisibility(true);
    } else {
      setVariantDropdownVisibility(false);
    }
  };

  const messageModalHandler = (message: any) => {
    setMessageModal(message);
    setMessageModalVisibility(true);
  };

  return (
    <>
      {MessagemodalVisibile && (
        <MessageModal
          close={() => setMessageModalVisibility(false)}
          message={messageModal}
        />
      )}
      <form
        className={style["addform-container"]}
        onSubmit={(e) => {
          e.preventDefault();
          if (selectRules[1].FinalMetric !== "") {
            let rules = selectRules[1].Rules.split(",");
            if (
              selectRules[0].SourceMetric !== rules[1] &&
              selectRules[0].SourceMetric !== rules[3]
            ) {
              messageModalHandler(
                "PLEASE FRAME CORRECT RULES WITH CORRECT METRICS"
              );
              return;
            }
          }
          props.submitHandler(e, selectRules);
        }}
      >
        <div className={style.leftwrapper}>
          <div className={style.itemdetails}>
            <div>ITEM DETAILS</div>
            <div>
              <div>
                Category<sup className={style.super}>*</sup>
              </div>
              <div>
                <Dropdown
                  list={catgDropdownList}
                  onClick={catgDropdownHandler}
                  required={true}
                  name="catg_dropdown"
                  clicked={true}
                  disable={false}
                  className={style.dropdown}
                />
              </div>
            </div>
            {isSubcatgDropdownVisible && (
              <div>
                <div>
                  Subcategory<sup className={style.super}>*</sup>
                </div>
                <div>
                  <Dropdown
                    list={subcatgDropdownList}
                    onClick={subcatgDropdownHandler}
                    required={true}
                    name="subcatg_dropdown"
                    clicked={true}
                    disable={false}
                    className={style.dropdown}
                  />
                </div>
              </div>
            )}
            {isProductDropdownVisible && (
              <div>
                <div>
                  Product<sup className={style.super}>*</sup>
                </div>
                <div>
                  <Dropdown
                    list={productDropdownList}
                    onClick={productDropdownHandler}
                    required={true}
                    name="product_dropdown"
                    clicked={true}
                    disable={false}
                    className={style.dropdown}
                  />
                </div>
              </div>
            )}
            {isVariantDropdownVisible && (
              <div>
                <div>
                  Product Variant<sup className={style.super}>*</sup>
                </div>
                <div>
                  <Dropdown
                    list={variantDropdownList}
                    required={true}
                    name="variant_dropdown"
                    disable={false}
                    clicked={true}
                    onClick={(val) => {
                      let result = variantDropdownList.find(
                        ({ value }: any) => value === val
                      );

                      setProductVariant(result.name);
                    }}
                    className={style.dropdown}
                  />
                </div>
              </div>
            )}
            <div>
              <div>
                Item Search Mode<sup className={style.super}>*</sup>
              </div>
              <div>
                <Dropdown
                  required={true}
                  disable={false}
                  list={itemSearchModeValues}
                  name="itemsearchmode"
                  clicked={true}
                  onClick={setSearchModeSelected}
                  className={style.dropdown}
                />
              </div>
            </div>
            {searchModeSelected === "Barcode" ? (
              <div>
                <div>
                  Barcode<sup className={style.super}>*</sup>
                </div>
                <div>
                  <input
                    required={true}
                    name="barcode"
                    type="text"
                    value={defaultBarcode}
                    onChange={(e) =>
                      searchModeSelected === "Barcode"
                        ? setDefaultBarcode(e.target.value)
                        : ""
                    }
                  />
                </div>
              </div>
            ) : null}
            <div>
              <div>
                Quantity<sup className={style.super}>*</sup>
              </div>
              <div>
                <input
                  onChange={(e) => {
                    if (!isNumber(e.target.value) && e.target.value !== "") {
                      return;
                    }
                    setQuantity(e.target.value);
                    if (purchaseAmt > 0 && Number(e.target.value) > 0) {
                      setUnitCP(
                        (purchaseAmt / Number(e.target.value)).toFixed(2)
                      );
                    } else {
                      setUnitCP(0);
                    }
                  }}
                  value={
                    quantity_State !== undefined && quantity_State !== 0
                      ? quantity_State
                      : ""
                  }
                  required={true}
                  name="quantity"
                  type="text"
                  // step={"0.001"}
                  // min="0"
                />
              </div>
            </div>
            <div>
              <div>
                Metric<sup className={style.super}>*</sup>
              </div>
              <div>
                <Dropdown
                  list={dropdownMetricList}
                  required={true}
                  clicked={true}
                  name="metric_dropdown"
                  disable={false}
                  onClick={(val) => {
                    setUnitMRP("");
                    if (val === "") {
                      setSelectRules((x: any) => (x = []));
                    } else {
                      let record = metricList.find(
                        (item: any) => item.SourceMetric === val
                      );
                      setSelectRules(
                        (x: any) =>
                          (x = [
                            record,
                            {
                              Id: 0,
                              SourceMetric: val,
                              Rules: "",
                              FinalMetric: "",
                            },
                          ])
                      );
                    }
                    setDestinput("");
                    setRulesAddVisibility(false);
                  }}
                  className={style.dropdown}
                />
              </div>
            </div>
            <div>
              <div>
                Purchase Amt(Total)<sup className={style.super}>*</sup>
              </div>
              <div>
                <input
                  onChange={(e) => {
                    setPurchaseAmt(Number(e.target.value));
                    if (Number(quantity_State) > 0) {
                      setUnitCP(
                        (Number(e.target.value) / quantity_State).toFixed(2)
                      );
                    }
                    if (Number(e.target.value) === 0) {
                      setUnitCP(0);
                    }
                  }}
                  value={purchaseAmt === 0 ? "" : purchaseAmt}
                  name="purchase_amt"
                  type="number"
                  required={true}
                  step={"0.01"}
                  min="0"
                />
                <span>Rs</span>
              </div>
            </div>
            <div>
              <div>
                MRP (per{" "}
                {selectRules.length > 0
                  ? selectRules[1].FinalMetric === ""
                    ? selectRules[1].SourceMetric
                    : selectRules[1].FinalMetric +
                      "(" +
                      selectRules[1].Rules.split(",").splice(0, 2).join("") +
                      ":" +
                      selectRules[1].Rules.split(",").splice(2, 2).join("") +
                      ")"
                  : "unit"}
                )<sup className={style.super}>*</sup>
              </div>
              <div>
                <input
                  value={!isNumber(mrp_State) ? "" : mrp_State}
                  onBlur={(e) => {
                    if (
                      Number(e.target.value) < Number(cp_State) &&
                      Number(e.target.value) !== 0
                    ) {
                      if (
                        selectRules.length < 1 ||
                        (selectRules.length > 1 &&
                          (selectRules[1].FinalMetric === "" ||
                            selectRules[1].SourceMetric ===
                              selectRules[1].FinalMetric))
                      ) {
                        setUnitMRP("");
                        setMessageModal("MRP cant be less then CP");
                        setMessageModalVisibility(true);
                        return;
                      }
                    }

                    setUnitMRP(
                      (x: any) =>
                        (x = Number(x) === 0 ? x : Number(x).toFixed(2))
                    );
                  }}
                  onChange={(e: any) => setUnitMRP(e.target.value)}
                  name="unit_mrp"
                  type="text"
                  required={true}
                />
                <span>Rs</span>
              </div>
            </div>
            <div>
              <div>
                CP(per unit)<sup className={style.super}>*</sup>
              </div>
              <div>
                <input
                  disabled={true}
                  name="unit_cp"
                  type="number"
                  required={true}
                  step={"0.01"}
                  min="0"
                  value={cp_State}
                />
                <span>Rs</span>
              </div>
            </div>
          </div>
          <div className={style.purchasestatus}>
            <div>PURCHASE STATUS</div>
            <div>
              <div>
                Purchase Status<sup className={style.super}>*</sup>
              </div>
              <div>
                <Dropdown
                  name="purchase_status"
                  required={true}
                  list={purchaseStatusList}
                  disable={false}
                  onClick={() => {}}
                  className={style.dropdown}
                />
              </div>
            </div>
            <div>
              <div>
                Purchase Date<sup className={style.super}>*</sup>
              </div>
              <div>
                <input
                  onChange={(e: any) => {
                    let dateString = DateTime({
                      value: e.target.value,
                      required: "date",
                    });
                    setPurchaseDate((x) => (x = dateString));
                  }}
                  type="date"
                  name="purchase_date"
                  required={true}
                />
              </div>
            </div>

            <div>
              <div>
                Payment Status<sup className={style.super}>*</sup>
              </div>
              <div>
                <Dropdown
                  name="payment_status"
                  required={true}
                  list={paymentStatusList}
                  disable={false}
                  onClick={() => {}}
                  className={style.dropdown}
                />
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT WRAPPER */}
        <div className={style.rightwrapper}>
          <div className={style.taxdetails}>
            <div>TAX DETAILS</div>
            <div>
              <div>GST</div>
              <div>
                <input
                  type="number"
                  name="gst"
                  min="0"
                  step="0.001"
                  defaultValue={0}
                />
              </div>
            </div>
            <div>
              <div>Other GST</div>
              <div>
                <input
                  type="number"
                  name="other_tax"
                  min="0"
                  step="0.001"
                  defaultValue={0}
                />
              </div>
            </div>
            <div>
              <div>Tax On</div>
              <div>
                <Dropdown
                  name="tax_on"
                  required={false}
                  list={taxOn_list}
                  disable={false}
                  onClick={() => {}}
                  className={style.dropdown}
                />
              </div>
            </div>
          </div>
          <div className={style.vendorpayment}>
            <div>VENDOR DETAILS</div>
            <div>
              <div>Vendor Name</div>
              <div>
                {/* <input name="vendor_name" type="text" /> */}
                <Dropdown
                  list={vendorList}
                  name="vendor_name"
                  onClick={(x: any) => {
                    console.log(x);
                  }}
                  className={style.dropdown}
                  clicked={true}
                  disable={false}
                  required={false}
                />
              </div>
            </div>
            <div>
              <div>Bill No</div>
              <div>
                <input type="text" name="bill_no" required={false} />
              </div>
            </div>
            <div>
              <div>Payment Date</div>
              <div>
                <input type="date" name="payment_date" required={false} />
              </div>
            </div>
            <div>
              <div>Comment</div>
              <div>
                <input type="text" name="textarea" required={false} />
              </div>
            </div>
          </div>
          <div className={style.otherdetails}>
            <div>OTHER DETAILS</div>
            <div>
              <div>Expiry Date</div>
              <div>
                <input name="expiry_date" type="date" />
              </div>
            </div>
            <div>
              <div>Batch No</div>
              <div>
                <input name="batch_no" type="text" />
              </div>
            </div>
          </div>
        </div>

        {/* BOTTOM WRAPPER */}
        <div className={style.bottomwrapper}>
          <div>
            METRIC RULES
            {!rulesAddVisibility && (
              <AiFillPlusCircle
                onClick={() => {
                  if (selectRules.length === 2) {
                    setRulesAddVisibility((x) => !x);
                  } else {
                    messageModalHandler(
                      "PLEASE SELECT METRIC FOR QUANTITY PURCHASED!!"
                    );
                  }
                }}
                className={style.plusminusicons}
              />
            )}
            {rulesAddVisibility && (
              <AiFillMinusCircle
                onClick={() => {
                  setSelectRules(
                    (x: any) =>
                      (x = [x[0], { ...x[1], Rules: "", FinalMetric: "" }])
                  );
                  setRulesAddVisibility((x) => !x);
                }}
                className={style.plusminusicons}
              />
            )}
          </div>

          {rulesAddVisibility && (
            <div

            //style={{ display: rulesAddVisibility ? "" : "none" }}
            >
              <div>
                <input
                  disabled={true}
                  name="sourceinput"
                  value="1"
                  type="text"
                />

                <DroplistU
                  list={dropdownMetricList}
                  onChange={(val: any) => {
                    if (val !== "" || val !== "Select") {
                      setSelectRules(
                        (x: any) => (x = [x[0], { ...x[1], Rules: "1," + val }])
                      );
                    }
                  }}
                  name="sourcemetric"
                  disabled={false}
                  required={true}
                  style={{ marginRight: "2%" }}
                />

                <input
                  name="destinput"
                  required={true}
                  value={destinput}
                  onChange={(e: any) => {
                    if (selectRules[1].Rules.split(",").length >= 2) {
                      setDestinput((x: any) => (x = e.target.value));
                    } else {
                      messageModalHandler(
                        "PLEASE SELECT METRIC OF PART1 OF RULES !!"
                      );
                    }
                  }}
                />
                <DroplistU
                  list={dropdownMetricList}
                  value={
                    selectRules[1].Rules.split(",").length < 2 ||
                    destinput === ""
                      ? ""
                      : selectRules[1].Rules.split(",")[3]
                  }
                  onChange={(val: any) => {
                    if (
                      selectRules[1].Rules.split(",").length >= 2 &&
                      destinput !== ""
                    ) {
                      let arr = selectRules[1].Rules.split(",");
                      setSelectRules(
                        (x: any) =>
                          (x = [
                            x[0],
                            {
                              ...x[1],
                              Rules:
                                arr[0] +
                                "," +
                                arr[1] +
                                "," +
                                destinput +
                                "," +
                                val,
                            },
                          ])
                      );
                    } else {
                      messageModalHandler(
                        "Rules Part1 or Part2 input not filled!!"
                      );
                      //console.log("destinput or source metric not selected");
                    }
                  }}
                  name="destmetric"
                  disabled={false}
                  required={true}
                />
              </div>
              <div className={style.finalmetric}>
                Final Metric
                <DroplistU
                  name="finalmetric"
                  required={true}
                  disabled={
                    selectRules[1].Rules.split(",").length === 4 ? false : true
                  }
                  onChange={(val: any) => {
                    if (selectRules[1].SourceMetric !== val) {
                      setMessageModal(
                        "Please Re-Enter MRP value per unit " +
                          val +
                          "(" +
                          selectRules[1].Rules.split(",")
                            .splice(0, 2)
                            .join("") +
                          ":" +
                          selectRules[1].Rules.split(",")
                            .splice(2, 2)
                            .join("") +
                          ")"
                      );
                      setMessageModalVisibility(true);
                    }
                    setUnitMRP("");
                    setSelectRules(
                      (x: any) => (x = [x[0], { ...x[1], FinalMetric: val }])
                    );
                  }}
                  list={[
                    {
                      name: selectRules[1].Rules.split(",")[1],
                      value: selectRules[1].Rules.split(",")[1],
                    },
                    {
                      name: selectRules[1].Rules.split(",")[3],
                      value: selectRules[1].Rules.split(",")[3],
                    },
                  ]}
                />
              </div>
            </div>
          )}

          <div className={style.buttonswrap}>
            <div>
              <button type="submit">Submit</button>
            </div>
            <div>
              <button
                type="button"
                name="Close"
                onClick={() => props.onClick("hide")}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddForm;
