import style from "./Login.module.css";
import CommonInterface from "./CommonInterface";

function Login(props: any) {
  const dataHandler = (arg: any) => {};

  const additionaljsx = (
    <>
      <span className={style.forgetpwdtext}>Forgot Password?</span>
      <span className={style.signuptext}>
        Don't have any account?{" "}
        <u onClick={() => props.signupClick()}>Sign Up</u>
      </span>
    </>
  );

  return (
    <div>
      <CommonInterface
        heading="Login"
        buttonName="Login"
        additionaljsx={additionaljsx}
        close={() => props.close()}
        onDataHandler={dataHandler}
      />
    </div>
  );
}

export default Login;
