import { useEffect } from "react";
import { useState } from "react";
import Billing from "./billing-system/Billing";
import Category from "./category-management/Category";
import Navigation from "./common-utility/Navigation";
import SideMenu from "./common-utility/SideMenu";
import Customer from "./customer-management/Customer";
import DashBoard from "./dashboard_management/DashBoard";
import Order from "./order-management/Order";
import Price from "./price-management/Price";
import Product from "./product-management/product";
import Purchase from "./purchase-management/Purchase";
import Reports from "./reports/Reports";
import Setting from "./settings/Setting";
import Store from "./store-management/Store";
import style from "./UserActivity.module.css";
import { AiFillRightSquare } from "react-icons/ai";
import Registration from "../../customer-app/components/Customers/registration/Registration";
import Address from "../../customer-app/components/Customers/address/Address";
import ActualCart from "../../customer-app/components/Customers/cart/ActualCart";
import BillCreation from "./billing-system/BillCreation";
import BillingProvider from "../data-centre/BillingProvider";
import StoreProvider from "../data-centre/StoreProvider";
import Supplier from "./supplier-management/Supplier";
import { Route, Routes, redirect, useNavigate } from "react-router-dom";
import OrderProvider from "../data-centre/OrderProvider";

function UserActivity({ isLogin }: any) {
  const [isDashboard, setDashBoard] = useState<boolean>(true);
  const [isCategory, setCategory] = useState<boolean>(false);
  const [isProduct, setProduct] = useState<boolean>(false);
  const [isPurchase, setPurchase] = useState<boolean>(false);
  const [isPrice, setPrice] = useState<boolean>(false);
  const [isCustomer, setCustomer] = useState<boolean>(false);
  const [isStore, setStore] = useState<boolean>(false);
  const [isReports, setReports] = useState<boolean>(false);
  const [isSupplier, setSupplier] = useState<boolean>(false);
  const [isOrder, setOrder] = useState<boolean>(false);
  const [isBilling, setBilling] = useState<boolean>(false);
  const [isSetting, setSetting] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, []);

  const [subMenu, setSubMenu] = useState<number>(0);
  const [sideMenuVisibility, setSideMenuVisibility] = useState<boolean>(true);

  const mainSectionHandler = (sectionType: any) => {
    if (sectionType.previousMenuClicked === "dashboard") {
      setDashBoard(false);
    } else if (sectionType.previousMenuClicked === "category") {
      setCategory(false);
    } else if (sectionType.previousMenuClicked === "product") {
      setProduct(false);
    } else if (sectionType.previousMenuClicked === "purchase") {
      setPurchase(false);
    } else if (sectionType.previousMenuClicked === "supplier") {
      setSupplier(false);
    } else if (sectionType.previousMenuClicked === "price") {
      setPrice(false);
    } else if (sectionType.previousMenuClicked === "customer") {
      setCustomer(false);
    } else if (sectionType.previousMenuClicked === "store") {
      setStore(false);
    } else if (sectionType.previousMenuClicked === "reports") {
      setReports(false);
    } else if (sectionType.previousMenuClicked === "order") {
      setOrder(false);
    } else if (sectionType.previousMenuClicked === "billing") {
      setBilling(false);
    } else if (sectionType.previousMenuClicked === "setting") {
      setSetting(false);
    }

    if (sectionType.sectionClicked === "dashboard") {
      setDashBoard(true);
    } else if (sectionType.sectionClicked === "category") {
      setCategory(true);
    } else if (sectionType.sectionClicked === "product") {
      setProduct(true);
    } else if (sectionType.sectionClicked === "purchase") {
      setPurchase(true);
    } else if (sectionType.sectionClicked === "supplier") {
      setSupplier(true);
    } else if (sectionType.sectionClicked === "price") {
      setPrice(true);
    } else if (sectionType.sectionClicked === "customer") {
      setCustomer(true);
    } else if (sectionType.sectionClicked === "store") {
      setStore(true);
    } else if (sectionType.sectionClicked === "reports") {
      setReports(true);
    } else if (sectionType.sectionClicked === "order") {
      setOrder(true);
    } else if (sectionType.sectionClicked === "billing") {
      setBilling(true);
    } else if (sectionType.sectionClicked === "setting") {
      setSetting(true);
    }

    setSubMenu(sectionType.subSectionClicked);
  };

  return (
    <>
      <section className={style.container}>
        <nav className={style.nav}>
          <Navigation />
        </nav>

        <aside
          className={
            style.aside +
            " " +
            (sideMenuVisibility === false ? style["aside-position"] : "")
          }
        >
          <AiFillRightSquare
            onClick={() => {
              sideMenuVisibility === true
                ? setSideMenuVisibility(false)
                : setSideMenuVisibility(true);
            }}
            className={style["sidemenu-icon"]}
          />
          <div
            className={
              style["side-menu"] +
              " " +
              (sideMenuVisibility === true
                ? style["side-menu-open"]
                : style["side-menu-close"])
            }
          >
            <SideMenu mainSectionHandler={mainSectionHandler} />
          </div>
        </aside>

        <main
          className={
            style.main +
            " " +
            (sideMenuVisibility === false ? style["main-fullwidth"] : "")
          }
        >
          {/* {isDashboard && <ActualCart />}   // TEMPORARY HIDE */}
          {isCategory && <Category menuType={subMenu} />}
          {isProduct && <Product menuType={subMenu} />}
          {isPurchase && <Purchase menuType={subMenu} />}
          {isStore && (
            <StoreProvider>
              <Store menuType={subMenu} />
            </StoreProvider>
          )}
          {isSupplier && <Supplier menuType={subMenu} />}
          {isPrice && <Price menuType={subMenu} />}
          {isCustomer && <Customer menuType={subMenu} />}

          {isReports && <Reports />}
          {isOrder && (
            <BillingProvider>
              <OrderProvider>
                <Order menuType={subMenu} />
              </OrderProvider>
            </BillingProvider>
          )}
          {isBilling && (
            <BillingProvider>
              <Billing menuType={subMenu} />
            </BillingProvider>
          )}
          {isSetting && <Setting />}
        </main>
      </section>
    </>
  );
}

export default UserActivity;
