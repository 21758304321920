import { useEffect, useState } from "react";
import style from "./ModalPopup.module.css";

function Overlay(props: any) {
  return <div className={style.modal}>{props.children}</div>;
}

function Background() {
  return <div className={style.background} />;
}

function ModalPopup(props: any) {
  const [positinValue, setPosition] = useState(true);

  useEffect(() => {
    if (positinValue) document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [positinValue]);

  return (
    <>
      <Background />
      <Overlay>{props.children}</Overlay>;
    </>
  );
}

export default ModalPopup;
