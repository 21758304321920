import style from "./Footer.module.css";
import { IoLocation } from "react-icons/io5";
import { MdCall, MdEmail } from "react-icons/md";

function Footer() {
  return (
    <div className={style["footer-wrapper"]}>
      <h4>STORE INFORMATION</h4>

      <ul className={style.ull}>
        <li>
          <IoLocation className={style["icon-contact-information"]} />
          Kota
        </li>
        <li>
          <MdCall className={style["icon-contact-information"]} />
          Call us: 7728801850
        </li>
        <li>
          <MdEmail className={style["icon-contact-information"]} />
          Email us: info@sahajj.com
        </li>
      </ul>
      <div className={style["copyright"]}>
        <p>Plussahajj Services @2022. All Right Reserved</p>
      </div>
    </div>
  );
}

export default Footer;
