import { useEffect, useState } from "react";
import BufferStock from "./bufferstock/BufferStock";
import AvailableStock from "./availablestock/AvailableStock";
import LiveStock from "./livestock/LiveStock";
import ArchieveStock from "./archievestock/ArchieveStock";

function Store(props: any) {
  const subMenu: number = props.menuType;
  const [menu, setMenu] = useState<number>();

  useEffect(() => {
    if (subMenu === 1) {
      setMenu(1);
    } else if (subMenu === 2) {
      setMenu(2);
    } else if (subMenu === 3) {
      setMenu(3);
    } else if (subMenu === 4) {
      setMenu(4);
    }
  }, [subMenu]);

  return (
    <>
      {menu === 1 ? (
        <BufferStock />
      ) : menu === 2 ? (
        <AvailableStock />
      ) : menu === 3 ? (
        <LiveStock />
      ) : menu === 4 ? (
        <ArchieveStock />
      ) : null}
    </>
  );
}

export default Store;
