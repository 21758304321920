import LowerSection from "./lower-section/LowerSection";
import UpperSection from "./upper-section/UpperSection";
import style from "./BufferStock.module.css";

function BufferStock() {
  return (
    <div className={style["bufferstock-wrapper"]}>
      <UpperSection />
      <LowerSection />
    </div>
  );
}

export default BufferStock;
