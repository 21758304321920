import ItemCard from "../item-card/ItemCard";
import style from "./Product.module.css";
import query from "../../../components/data-centre/Queries";
import { useEffect, useState, useContext } from "react";
import CartItemCountContext from "../../Data-Store/CartItemCount-Context";

interface propertiesType {
  id: string;
  name: string;
  imagePath: string;
  kgValue: number;
  gmValue: number;
  totalPrice: number;
  date: string;
  time: string;
}

function Product(props: any) {
  const [product, setProduct] = useState([]);
  const [itemCart, setItemCart] = useState<any>([]);
  const ctx = useContext(CartItemCountContext);
  let imageName;

  useEffect(() => {
    query.Product.search(1, 26, "select")
      .then((x: any) => setProduct(x.data))
      .catch((x) => {
        console.log(x);
      });
  }, []);

  useEffect(() => {
    let arr: any = localStorage.getItem("itemcart");
    if (arr != null) {
      let temp = [...JSON.parse(arr)];
      setItemCart(temp);
      ctx.setcount(temp.length);
    }
  }, []);

  const localCartHandler = (value: propertiesType) => {
    let arr: any = localStorage.getItem("itemcart");
    let arrParsed: any;
    if (arr == null) {
      arrParsed = [value];
    } else {
      arrParsed = [...JSON.parse(arr)];
      let index = arrParsed.findIndex((x: any) => x.id === value.id);
      if (index >= 0) {
        arrParsed.splice(index, 1, {
          ...arrParsed[index],
          kgValue: value.kgValue,
          gmValue: value.gmValue,
        });
        if (value.kgValue === 0 && value.gmValue === 0) {
          arrParsed.splice(index, 1);
        }
      } else {
        arrParsed = [...arrParsed, value];
      }
    }
    ctx.setcount(arrParsed.length);
    localStorage.setItem("itemcart", JSON.stringify(arrParsed));
  };

  return (
    <>
      <div className={style.wrapper}>
        {product.map((x: any, index) => {
          imageName = x.Image.toLowerCase().replaceAll(" ", "");
          let indexExist = itemCart.findIndex((t: any) => t.id === x.Id);

          return (
            <ItemCard
              key={index}
              id={x.Id}
              image={"/vegetables/" + imageName}
              name={x.Variant_Name}
              nameHindi={x.Variant_Name_Hin}
              kgValue={indexExist >= 0 ? itemCart[indexExist].kgValue : 0}
              gmValue={indexExist >= 0 ? itemCart[indexExist].gmValue : 0}
              onClick={(x: propertiesType) => localCartHandler(x)}
            />
          );
        })}
      </div>
    </>
  );
}

export default Product;
