import { useContext, useEffect, useState } from "react";
import style from "./Header.module.css";

import sahajjnamelogo from "../../assets/sahajj-name-logo.png";
import { MdManageAccounts } from "react-icons/md";
import { HiShoppingCart } from "react-icons/hi";
import { IoIosNotifications } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import Navigation from "../../Pages/Navigation";
import CartItemCountContext from "../../Data-Store/CartItemCount-Context";
import ModalPopup from "../modal/ModalPopup";
import Login from "../login-signup/Login";
import IconNotificationCount from "../header-notification-icons/IconNotificationCount";
import Signup from "../login-signup/Signup";
import Search from "../../utility/Search";

function Header(props: any) {
  const ctx = useContext(CartItemCountContext);
  const [itemsAdded, setItemAdded] = useState<number>(0);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [isLogin, setLogin] = useState<boolean>(false);
  const [isSignUp, setSignUp] = useState<boolean>(false);

  const [navigationMenuWidth, setNavigationMenuWidth] =
    useState<string>("-300px");
  const [sideMenuBackdropWidth, setSideMenuBackdropWidth] =
    useState<string>("0px");
  useEffect(() => {
    setItemAdded(ctx.getcount());
  }, [ctx.getcount()]);

  return (
    <>
      <div className={style["header-wrapper"]}>
        {isLogin && (
          <ModalPopup>
            <Login
              close={() => setLogin((x) => false)}
              signupClick={() => {
                setLogin((x) => false);
                return setSignUp((x) => true);
              }}
            />
          </ModalPopup>
        )}
        {isSignUp && (
          <ModalPopup>
            <Signup
              close={() => {
                setSignUp((x) => false);
              }}
              loginClick={() => {
                setSignUp((x) => false);
                setLogin((x) => true);
              }}
            />
          </ModalPopup>
        )}

        <Navigation
          setleft={() => {
            setNavigationMenuWidth("-300px");
            setSideMenuBackdropWidth("0px");
          }}
          left={navigationMenuWidth}
          backdropWidth={sideMenuBackdropWidth}
        />

        {/* <div className={style["hamburger-menu-wrapper"]}>
          <GiHamburgerMenu
            className={style["hamburger-menu"]}
            onClick={() => {
              setNavigationMenuWidth("0px");
              setSideMenuBackdropWidth("100vw");
            }}
          />
        </div> */}
        <div className={style["logo-div"]}>
          <a>{<img src={sahajjnamelogo} />}</a>
        </div>
        <div className={style["search-div-navbar"]}>
          <Search />
        </div>

        <ul className={style["navbar-icons-wrapper"]}>
          <li className={style["icon-notification-wrapper"]}>
            <IoIosNotifications className={style["icon-notification"]} />
            <div
              style={
                notificationCount > 0
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            >
              <IconNotificationCount itemsCount={notificationCount} />
            </div>
          </li>
          <li className={style["icon-cart-wrapper"]}>
            <HiShoppingCart className={style["icon-cart"]} />
            <div
              style={
                itemsAdded > 0
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            >
              <IconNotificationCount itemsCount={itemsAdded} />
            </div>
          </li>
          <li
            onClick={() => {
              setLogin(true);
            }}
            className={style["manage-account-wrapper"]}
          >
            <MdManageAccounts className={style["icon-manage-accounts"]} />
            <span>My Account</span>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
