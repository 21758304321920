import style from "./AddSubCategory.module.css";
import axios from "axios";
import { useEffect, useState } from "react";
import LoaderUI from "../../common-utility/LoaderUI";
import MessageModal from "../../common-utility/MessageModal";
import StringCaseUI from "../../common-utility/StringCaseUI";
import Dropdown from "../../common-utility/Dropdown";
import { memo } from "react";
import SubcategoryContext from "../../../data-centre/Subcategory-context";
import { useContext } from "react";
import query from "../../../data-centre/Queries";

function AddSubCategory(props: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitted, setSubmitMessage] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<string>("notSubmitted");
  const [catgNameList, setCatgnameList] = useState<any>([]);
  const [dropdown, setDropdown] = useState<boolean>(false);

  const ctx = useContext(SubcategoryContext);

  const product = {
    Catg_Id: "",
    Name_English: "",
    Name_Hindi: "",
    Image: "",
    Category_Name: "",
  };

  useEffect(() => {
    getDataByName();
    return function cleanup() {
      setCatgnameList([]);
    };
  }, []);

  const getDataByName = async () => {
    query.Category.listByName().then((row) => {
      // console.log("setcatglist: ", row.data);
      return setCatgnameList(row.data);
    });
  };

  const addData = async (body: any) => {
    setLoading(true);
    await query.SubCategory.add(body).then((response) => {
      if (response.statusText === "OK") {
        setLoading(false);
        setSubmitMessage(true);
        props.add();
      }
    });
  };

  // MULTIPLE CRUD  USING AXIOS IN SINGLE QUERY

  // async function getData() {
  //   await axios
  //     .all([axios.post(one), axios.get(two)])
  //     .then(
  //       axios.spread((...response) => {
  //         setPostresponse(response[0].statusText),
  //           setCatgnameList(response[1].data);
  //       })
  //     )
  //     .catch((errors) => console.log(errors));
  // }

  const submitHandler = (event: any) => {
    event.preventDefault();
    product.Catg_Id = event.target["categorydropdown"].value;
    product.Name_English = StringCaseUI(event.target["SubcategoryEng"].value);
    product.Name_Hindi = event.target["SubcategoryHin"].value;
    product.Image = event.target["Image"].value;
    addData(product);
    event.target["SubcategoryEng"].value = "";
    event.target["SubcategoryHin"].value = "";
    event.target["Image"].value = "";
    event.target["categorydropdown"].value = 0;
  };

  const messageModalHandler = () => {
    setSubmitMessage(false);
  };

  const showcontexthandler = () => {
    ctx.update("bhav");
  };

  return (
    <>
      <form onSubmit={submitHandler} className={style.form}>
        <div className={style.innerform}>
          <div className={style["label-input"]}>
            <label>Category</label>
            {console.log("addsubcategory list: ", catgNameList)}
            <Dropdown
              list={catgNameList}
              disable={false}
              required={true}
              name="categorydropdown"
              initialValue="0"
              onClick={() => {}}
              isSelected={false}
            />
          </div>
          <div className={style["label-input"]}>
            <label>Subcategory</label>
            <input
              name="SubcategoryEng"
              required={true}
              placeholder="In English"
            />
          </div>
          <div className={style["label-input"]}>
            <label>Subcategory</label>
            <input
              name="SubcategoryHin"
              required={true}
              placeholder="In Hindi"
            />
          </div>
          <div className={style["label-input"]}>
            <label>Image</label>
            <input name="Image" required={true} />
          </div>
        </div>
        <div className={style.buttondiv}>
          <button type="submit">Add</button>
        </div>
      </form>
      {isLoading && (
        <LoaderUI content="Adding..." isDimmerActive={true} size="medium" />
      )}
      {isSubmitted && (
        <MessageModal
          message={"Subcategory added successfully!!"}
          close={messageModalHandler}
        />
      )}
    </>
  );
}

const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.value === nextProps.value;
};

export default memo(AddSubCategory, areEqual);
