interface type {
  list: { name: any; value: any }[];
  onChange?: any;
  disabled?: boolean;
  required?: boolean;
  name: string | undefined;
  style?: React.CSSProperties | undefined;
  value?: string | null | undefined;
}
function Droplist({
  list,
  onChange,
  disabled,
  required,
  name,
  style,
  value,
}: type) {
  const props = { disabled, required, name, style, value };
  return (
    <>
      <select
        {...props}
        onChange={(e: any) =>
          onChange === undefined ? "" : onChange(e.target.value)
        }
        value={value === null || value === undefined ? "" : value}
      >
        <option key="select" value="">
          Select
        </option>
        {list.map((item: any, index: number) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
}

export default Droplist;
