import { useState } from "react";

const Qtyinput = ({
  setQtyInput,
  isBlurValue,
  isManualEditable,
  color,
  fontweight,
  ...rest
}: any) => {
  const [isClicked, setClicked] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<any>();

  return (
    <input
      style={{
        backgroundColor:
          isClicked === true ? "#FFFAD7" : isManualEditable ? "#f2dea4" : "#fff",
        fontWeight: fontweight,
        color: color,
      }}
      onFocus={(e) => {
        setInitialValue(e.target.value);
        console.log("inital", e.target.value);
      }}
      onMouseDown={(e: any) => {
        setClicked((x) => true);
      }}
      onBlur={(e: any) => {
        setClicked((x) => false);
        if (isBlurValue === true) {
          if (e.target.value === "") {
            setQtyInput(Number(initialValue).toFixed(2));
            return;
          }

          setQtyInput(Number(e.target.value).toFixed(2));
        }
      }}
      onChange={(e) => {
        setQtyInput(e.target.value);
      }}
      {...rest}
    />
  );
};

export default Qtyinput;
