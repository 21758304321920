import { useState } from "react";
import { useEffect } from "react";
import {
  action_required_drop,
  action_status_drop,
  metricList,
  paymentStatusList,
  purchaseStatusList,
} from "../../../../../../common-objects/Purchase";
import query from "../../../../../data-centre/Queries";
import Dropdown from "../../../../common-utility/Dropdown";

import {
  PurchaseItemDetailedStatusUpdate,
  Update,
} from "./purchaseitemdetailed-component/purchaseItemDetailedStatusUpdate";

function PurchaseItemDetailedStatus(props: any) {
  const [vendorPurchaseList, setVendorPurchaseList] = useState<any>({});
  const [isDisabled, setDisabledMode] = useState<boolean>(true);

  useEffect(() => {
    getVendorPurchaseList(props.data);
  }, []);

  const getVendorPurchaseList = async (x: any) => {
    await query.VendorPurchase.search(x).then((response) => {
      if (response.statusText === "OK") {
        setVendorPurchaseList(response.data);
      }
    });
  };

  const updateButtonHandler = (e: any, id: number) => {
    e.preventDefault();
    if (PurchaseItemDetailedStatusUpdate(e, id) === "done") {
      props.loader(true, "Updating...");
      Update.up().then((response) => {
        if (response.statusText === "OK") {
          props.loader(false, "");
          props.modal(
            true,
            "Update Successfully for Id: " + vendorPurchaseList.Id
          );
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={(e) => updateButtonHandler(e, vendorPurchaseList.Id)}>
        <table>
          <tbody>
            <tr>
              <th>Id</th>
              <td>{vendorPurchaseList.Id}</td>
            </tr>
            <tr>
              <th>Purchase Status</th>
              <td>
                <Dropdown
                  initialValue={vendorPurchaseList.Purchase_status}
                  disable={isDisabled}
                  isSelected={true}
                  required={true}
                  name="purchase_status"
                  list={purchaseStatusList.filter(
                    (val) => val.value !== vendorPurchaseList.Purchase_status
                  )}
                  clicked={false}
                  onClick={() => {}}
                />
              </td>
            </tr>
            <tr>
              <th>Payment Status</th>
              <td>
                <Dropdown
                  initialValue={vendorPurchaseList.Payment_status}
                  disable={isDisabled}
                  isSelected={true}
                  required={true}
                  name="payment_status"
                  list={paymentStatusList.filter(
                    (val) => val.value !== vendorPurchaseList.Payment_status
                  )}
                  clicked={false}
                  onClick={() => {}}
                />
              </td>
            </tr>
            <tr>
              <th>Action Status</th>
              <td>
                <Dropdown
                  initialValue={vendorPurchaseList.Action_status}
                  disable={isDisabled}
                  isSelected={true}
                  required={true}
                  name="action_status"
                  list={action_status_drop.filter(
                    (val) => val.value !== vendorPurchaseList.Action_status
                  )}
                  clicked={false}
                  onClick={() => {}}
                />
              </td>
            </tr>
            <tr>
              <th>Action Required</th>
              <td>
                <Dropdown
                  initialValue={vendorPurchaseList.Action_required}
                  disable={isDisabled}
                  isSelected={true}
                  required={true}
                  name="action_required"
                  list={action_required_drop.filter(
                    (val) => val.value !== vendorPurchaseList.Action_required
                  )}
                  clicked={false}
                  onClick={() => {}}
                />
              </td>
            </tr>
            <tr>
              <th>Vendor Name</th>
              <td>
                <input
                  name="vendor_name"
                  type="text"
                  disabled={isDisabled}
                  defaultValue={vendorPurchaseList.Vendor_name}
                />
              </td>
            </tr>
            <tr>
              <th>Bill No</th>
              <td>
                <input
                  name="bill_no"
                  type="text"
                  disabled={isDisabled}
                  defaultValue={vendorPurchaseList.Bill_no}
                />
              </td>
            </tr>
            <tr>
              <th>Purchase Date</th>
              <td>
                <input
                  name="purchase_date"
                  type="date"
                  disabled={isDisabled}
                  value={new Date(
                    vendorPurchaseList.Bill_date === null ||
                    new Date(vendorPurchaseList.Bill_date).toLocaleDateString(
                      "en-CA"
                    ) === "1900-01-01"
                      ? ""
                      : vendorPurchaseList.Bill_date
                  ).toLocaleDateString("en-CA")}
                  onChange={(e: any) => {
                    setVendorPurchaseList({
                      ...vendorPurchaseList,
                      Bill_date: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>Payment Date</th>
              <td>
                <input
                  name="payment_date"
                  type="date"
                  disabled={isDisabled}
                  value={new Date(
                    vendorPurchaseList.Payment_date === null ||
                    new Date(
                      vendorPurchaseList.Payment_date
                    ).toLocaleDateString("en-CA") === "1900-01-01"
                      ? ""
                      : vendorPurchaseList.Payment_date
                  ).toLocaleDateString("en-CA")}
                  onChange={(e: any) => {
                    setVendorPurchaseList({
                      ...vendorPurchaseList,
                      Payment_date: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>Action Amount</th>
              <td>
                <input
                  name="action_amount"
                  type="double"
                  step={0.01}
                  min={0}
                  disabled={isDisabled}
                  defaultValue={vendorPurchaseList.Action_amount}
                />
              </td>
            </tr>
            <tr>
              <th>Action Amount Date</th>
              <td>
                <input
                  name="action_amount_date"
                  type="date"
                  disabled={isDisabled}
                  value={new Date(
                    vendorPurchaseList.AA_date === null ||
                    new Date(vendorPurchaseList.AA_date).toLocaleDateString(
                      "en-CA"
                    ) === "1900-01-01"
                      ? ""
                      : vendorPurchaseList.AA_date
                  ).toLocaleDateString("en-CA")}
                  onChange={(e: any) => {
                    setVendorPurchaseList({
                      ...vendorPurchaseList,
                      AA_date: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>Action Quantity</th>
              <td>
                <input
                  name="action_quantity"
                  type="number"
                  step={0.001}
                  min={0}
                  disabled={isDisabled}
                  defaultValue={vendorPurchaseList.Action_quantity}
                />
                {vendorPurchaseList.Metric === undefined ? null : (
                  <Dropdown
                    disable={isDisabled}
                    initialValue={
                      vendorPurchaseList.Metric === "" ||
                      vendorPurchaseList.Metric === null
                        ? "Select"
                        : vendorPurchaseList.Metric
                    }
                    isSelected={true}
                    required={true}
                    name="metricList"
                    list={metricList.filter(
                      (val) => val.value !== vendorPurchaseList.Metric
                    )}
                    clicked={false}
                    onClick={() => {}}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>Action Quantity Date</th>
              <td>
                <input
                  name="action_quantity_date"
                  disabled={isDisabled}
                  type="date"
                  value={new Date(
                    vendorPurchaseList.AQ_date === null ||
                    new Date(vendorPurchaseList.AQ_date).toLocaleDateString(
                      "en-CA"
                    ) === "1900-01-01"
                      ? ""
                      : vendorPurchaseList.AQ_date
                  ).toLocaleDateString("en-CA")}
                  onChange={(e: any) => {
                    setVendorPurchaseList({
                      ...vendorPurchaseList,
                      AQ_date: e.target.value,
                    });
                    // setAQdate(e.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        {!isDisabled && (
          <button name="update" type="submit">
            Update
          </button>
        )}
      </form>
      {isDisabled && (
        <button
          name="edit"
          onClick={() => {
            setDisabledMode(false);
          }}
        >
          Edit Mode
        </button>
      )}
      <button onClick={() => props.close("", false)}>close</button>
    </>
  );
}

export default PurchaseItemDetailedStatus;
