import AddPurchase from "../lower-section/lower-components/AddPurchase";
import AddFilter from "./AddFilter";

function UpperSection(props: any) {
  //const filterSearchHandler = (catgId: string, subcatgId: string) => {};

  return (
    <>
      <AddPurchase />
      <AddFilter filter={props.filter} />
    </>
  );
}

export default UpperSection;
