import { useEffect, useState } from "react";
import ManagePurchase from "./ManagePurchase";
import style from "./Purchase.module.css";

function Purchase(props: any) {
  const subMenu: number = props.menuType;
  const [isMenuVisible, setMenu] = useState<boolean>(false);

  useEffect(() => {
    if (subMenu === 1) {
      setMenu(true);
    }
  }, [subMenu]);
  return (
    <div className={style.container}>{isMenuVisible && <ManagePurchase />}</div>
  );
}

export default Purchase;
