import {
  ItemAvailable,
  OrderDetail,
  OrderItemDetailEtended,
  OrderItemSummary,
  wrapper_order_detail_summary_user,
} from "../../../../../Interface/Purchase";
import ConfirmationModal from "../../../common-utility/ConfirmationModal";
import DateTime from "../../../common-utility/DateTime";
import style from "./OrdersDetailList.module.css";
import { useContext, useEffect, useState } from "react";
import { MdOutlineModeEditOutline, MdDelete } from "react-icons/md";
import OrderDetailEdit from "./OrderDetailEdit";
import LoginContext from "../../../../data-centre/Login-context";
import valueCoversionHandler from "../../../billing-system/components/PlaceOrder/valueConversionHandler";
import moneyValueConversion from "./moneyValueConversion";
import OrderContext from "../../../../data-centre/Order-context";

interface type {
  list: wrapper_order_detail_summary_user[];
  header: { name: string; width: string }[];
  setLoader: any;
  setPromptMessage: any;
  loadData: (obj: {}) => Promise<void>;
  print?: any;
  loadIndicator: number;
}

function OrdersDetailList({
  list,
  header,
  setLoader,
  setPromptMessage,
  loadData,
  print,
  loadIndicator,
}: type) {
  const [editRow, setEditRow] = useState<wrapper_order_detail_summary_user>();
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [confirmationMessageVisibility, setConfirmationMessageVisibility] =
    useState<boolean>(false);
  const [confirmationResponse, setConfirmationResponse] = useState<any>();

  const orderCtx = useContext(OrderContext);

  const deleteHandler = (pricingid: number) => {
    setConfirmationResponse("");
  };

  const confirmationMessageHandler = (type: string, data: any) => {
    switch (type) {
      case "delete":
        setConfirmationMessage(
          "Are You Sure to Delete the PricingId " + data + " ?"
        );
        setConfirmationMessageVisibility(true);
        setConfirmationResponse(data);
        break;
    }
  };

  const confirmationResponseHandler = (type: string, data: any) => {
    setConfirmationMessageVisibility(false);
    setConfirmationMessage("");
    switch (type) {
      case "delete":
        deleteHandler(data);
        break;
    }
  };

  useEffect(() => {
    if (loadIndicator === 1) {
      loadData(orderCtx.getOrderDate());
    }
  }, [loadIndicator]);

  const profitHandler = (
    entity: OrderItemSummary[],
    itemAvailable: ItemAvailable[],
    orderInvoiceStatus: string
  ) => {
    console.log("entity", entity);
    let profit: number = 0;
    let sum: number;
    let totalcp: number = 0;
    if (orderInvoiceStatus !== "frammed") {
      return 0;
    }
    for (let i = 0; i < entity.length; i++) {
      sum = 0;
      for (
        let j = 0;
        j <
        (entity[i].orderItemDetailMapperExtended?.Qtys_taken?.length as number);
        j++
      ) {
        let valuemetric = Number(
          valueCoversionHandler(
            entity[i].Qty_metric as string,
            itemAvailable[i].Metric_rules as string,
            (entity[i].orderItemDetailMapperExtended?.Qtys_taken as number[])[
              j
            ],
            (entity[i].orderItemDetailMapperExtended?.Qtys_metric as string[])[
              j
            ]
          )
        );
        totalcp =
          valuemetric *
          moneyValueConversion(
            entity[i].Qty_metric as string,
            itemAvailable[i].Metric_rules as string,
            Number(entity[i].orderItemDetailMapperExtended?.Unit_cp[j]),
            (
              entity[i].orderItemDetailMapperExtended
                ?.Unit_cp_metric as string[]
            )[j]
          );
        sum = sum + totalcp;
      }
      profit = profit + (entity[i].Sub_total as number) - sum;
    }

    return profit.toFixed(2);
  };

  return (
    <>
      {confirmationMessageVisibility && (
        <ConfirmationModal
          onClose={() => setConfirmationMessageVisibility(false)}
          Ok={() => confirmationResponseHandler("delete", confirmationResponse)}
        >
          {confirmationMessage}
        </ConfirmationModal>
      )}
      {editRow && (
        <OrderDetailEdit
          row={editRow}
          onClose={setEditRow}
          setLoader={setLoader}
          setPromptMessage={setPromptMessage}
        />
      )}
      <div className={style["order-table-wrapper"]}>
        <div className={style.tablehead}>
          {header.map((head) => {
            return orderCtx
              .getOrderModuleAuthorization()
              .columns.findIndex((x) => x === head.name) <= -1 ? (
              <span
                key={head.name}
                style={{ width: head.width, wordWrap: "break-word" }}
              >
                {head.name}
              </span>
            ) : (
              ""
            );
          })}
        </div>
        <div className={style.rowswrapper}>
          {list.map((item, index: number) => (
            <div className={style.tablerow} key={item.Order_id}>
              <span style={{ width: header[0].width }}>{index + 1}</span>
              <span style={{ width: header[1].width }}>{item.Order_id}</span>
              <span style={{ width: header[2].width }}>
                {item.users.First_name + " " + item.users.Last_name}
              </span>
              <span style={{ width: header[3].width }}>
                {DateTime({
                  value: item.OrderCreated_at,
                  required: "date",
                })}
                <br />
                {DateTime({
                  value: item.OrderCreated_at,
                  required: "time",
                })}
              </span>
              <span style={{ width: header[4].width }}>
                {DateTime({
                  value: item.DeliveryRequest_date,
                  required: "date",
                })}
              </span>
              <span style={{ width: header[5].width }}>
                {item.OrderDelivery_time != null
                  ? DateTime({
                      value: item.OrderDelivery_time,
                      required: "date",
                    })
                  : ""}
              </span>
              <span
                style={{
                  width: header[6].width,
                  color:
                    item.OrderInvoice_status === "frammed" ? "green" : "red",
                }}
              >
                {item.OrderInvoice_status}
              </span>
              <span style={{ width: header[7].width }}>{item.Offer_id}</span>
              <span style={{ width: header[8].width }}>
                {item.Delivery_charge}
              </span>
              <span style={{ width: header[9].width }}>
                Rs{" " + item.OrderTotal_amt}
              </span>
              <span
                style={{
                  width: header[10].width,
                  textAlign: "center",
                  color:
                    (item.TotalAmt_recieved as number) < item.OrderTotal_amt
                      ? "Red"
                      : "",
                }}
              >
                {item.TotalAmt_recieved ? item.TotalAmt_recieved : "-"}
              </span>
              <span
                style={{
                  width: header[11].width,
                  color:
                    item.Order_status === "Delivered"
                      ? "green"
                      : item.Order_status === "Pending"
                      ? "red"
                      : item.Order_status === "Cancel"
                      ? "Orange"
                      : "Black",
                }}
              >
                {item.Order_status}
              </span>
              <span style={{ width: header[12].width }}>{item._remark}</span>
              <span
                style={{
                  width: header[13].width,
                }}
              >
                <span
                  className={style.click}
                  onClick={() => {
                    setLoader(true);
                    print(item);
                  }}
                >
                  Click
                </span>
                <span>
                  {orderCtx
                    .getOrderModuleAuthorization()
                    .columns.findIndex((x) => x === "Edit") <= -1 &&
                    profitHandler(
                      item.orderItemSummaryMappers,
                      item._products,
                      item.OrderInvoice_status
                    )}
                </span>
                {orderCtx
                  .getOrderModuleAuthorization()
                  .columns.findIndex((x) => x === "Edit") <= -1 && (
                  <button
                    className={style.viewBill}
                    name="view-bill"
                    type="button"
                    onClick={() => {}}
                  >
                    view
                  </button>
                )}
              </span>
              {orderCtx
                .getOrderModuleAuthorization()
                .columns.findIndex((x) => x === "Edit") <= -1 && (
                <span
                  className={style.editspan}
                  style={{
                    width: header[14].width,
                  }}
                >
                  <MdOutlineModeEditOutline
                    className={style.editicon}
                    onClick={() => {
                      setEditRow(item);
                    }}
                  />
                </span>
              )}
              {orderCtx
                .getOrderModuleAuthorization()
                .columns.findIndex((x) => x === "Delete") <= -1 && (
                <span
                  className={style.deletespan}
                  style={{
                    width: header[15].width,
                  }}
                >
                  <MdDelete
                    onClick={() =>
                      confirmationMessageHandler("delete", item.Order_id)
                    }
                    className={style.deleteicon}
                  />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default OrdersDetailList;
