const moneyValueConversion = (
  referenceMetric: string,
  metricRule: string,
  amt: number,
  amtMetric: string
) => {
  let rules = metricRule.split(",");

  return amtMetric === referenceMetric
    ? amt
    : amtMetric === rules[1]
    ? Number(((Number(rules[0]) * amt) / Number(rules[2])).toFixed(5))
    : Number(((Number(rules[2]) * amt) / Number(rules[0])).toFixed(5));
};

export default moneyValueConversion;
