import { useEffect, useRef, useState } from "react";
import {
  OrderDetail,
  wrapper_order_detail_summary_user,
} from "../../../../../Interface/Purchase";

import query from "../../../../data-centre/Queries";
import style from "./OrderDetailEdit.module.css";
import EditPopup from "../../../common-utility/EditPopup";
import DroplistU from "../../../common-utility/DroplistU";
import { isNumber } from "../../../../../customer-app/utility/validation";

interface type {
  row: wrapper_order_detail_summary_user;
  onClose: (x: any) => any;
  setLoader: any;
  setPromptMessage: any;
}

function OrderDetailEdit({ row, onClose, setLoader, setPromptMessage }: type) {
  const { orderItemSummaryMappers, users, _products, ...orderDetail } = row;
  const [rowValues, setRowValues] = useState<OrderDetail>(orderDetail);
  const resetHandler = () => {
    setRowValues(orderDetail);
  };
  const [recievedAmt, setNewRecievedAmt] = useState<any>();

  const orderStatusList = [
    { name: "Pending", value: "Pending" },
    { name: "Delivered", value: "Delivered" },
    { name: "Cancel", value: "Cancel" },
  ];

  const paymentRecievedModeList = [
    { name: "Cash", value: "Cash" },
    { name: "Online", value: "Online" },
  ];

  const updateHandler = async (e: any) => {
    e.preventDefault();
    let result = await paymentRecvdModeHandler(rowValues.PaymentRecieved_mode);
    
    if (!result) {
      return;
    }

    let arr = { ...rowValues };
    let amount: any;

    if (rowValues.Order_status === "Delivered") {
      amount = arr.TotalAmt_recieved?.toString().trim().split("Rs.")[1];
      arr = isNumber(amount)
        ? { ...arr, TotalAmt_recieved: Number(amount) }
        : { ...arr, TotalAmt_recieved: row.TotalAmt_recieved };

      if ((row.TotalAmt_recieved as number) >= row.OrderTotal_amt) {
        setPromptMessage("Amount is already recieved!!!", true, 0);
        return;
      } else if (arr.TotalAmt_recieved === row.TotalAmt_recieved) {
        setPromptMessage("Please add some recieved amt!!!", true, 0);
        return;
      }
    } else if (rowValues.Order_status === "Cancel") {
      amount = arr.Refund_amt?.toString().trim().split("Rs.")[1];
      arr = isNumber(amount)
        ? { ...arr, Refund_amt: Number(amount) }
        : { ...arr, Refund_amt: row.Refund_amt };
      if ((row.TotalAmt_recieved as number) <= 0) {
        setPromptMessage(
          "You can't refund money without recieving!!!",
          true,
          0
        );
        return;
      } else if (arr.Refund_amt === row.Refund_amt) {
        setPromptMessage("Please add some refund amt!!!", true, 0);
        return;
      }
    }

    setLoader(true);

    await query.Order.edit({ ...arr })
      .then((response) => {
        if (response.statusText === "OK") {
          onClose(undefined);
          setLoader(false);
          if (response.data === 1) {
            setPromptMessage("Updated Successfully!!!", true, 1);
          } else {
            setPromptMessage("No Row Updated!!!", true, 0);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setPromptMessage("there is some error", true, -1);
        // onClose(undefined);
      });
  };

  const orderStatusOnChange = (val: any) => {
    if (
      (row.Order_status === "Delivered" && val === "Pending") ||
      (row.Order_status === "Delivered" && val === "")
    ) {
      setPromptMessage(
        "Order Once delivered can't be changed to no option or pending!!!",
        true,
        0
      );
      console.log("i am in");
      return;
    }
    setRowValues(
      (x) =>
        (x = {
          ...x,
          Order_status: val,
          TotalAmt_recieved: undefined,
        })
    );
  };

  const orderStatusValue = () => {
    return rowValues.Order_status !== undefined &&
      rowValues.Order_status !== null
      ? rowValues.Order_status
      : "";
  };

  const amtRcvdOnChange = (e: any) => {
    let val: any;
    let newSum: any;
    if (e.target.value.trim().includes("Rs.")) {
      val = "Rs. " + e.target.value.split("Rs.")[1].trim();
      newSum =
        (row.TotalAmt_recieved ? row.TotalAmt_recieved : 0) +
        (isNumber(e.target.value.split("Rs.")[1].trim())
          ? Number(e.target.value.split("Rs.")[1])
          : 0);
    } else {
      val = "Rs. " + e.target.value.split("Rs.")[0].trim();
      newSum =
        (row.TotalAmt_recieved ? row.TotalAmt_recieved : 0) +
        (isNumber(e.target.value.split("Rs.")[0].trim())
          ? Number(e.target.value.split("Rs.")[0])
          : 0);
    }
    newSum = "Rs. " + newSum;

    if (
      Number(val.trim().split("Rs.")[1]) < 0 ||
      !isNumber(val.trim().split("Rs.")[1])
    ) {
      e.target.value = "Rs. ";
      val = "Rs. ";
      newSum = val + !row.TotalAmt_recieved ? 0 : row.TotalAmt_recieved;
    }
    setNewRecievedAmt(val);
    setRowValues(
      (x) =>
        (x = {
          ...x,
          TotalAmt_recieved: newSum,
        })
    );
  };

  const amtRecdOnBlur = (e: any) => {
    let val: any = "";
    if (e.target.value.trim().includes("Rs.")) {
      val = isNumber(e.target.value.split("Rs.")[1])
        ? e.target.value.split("Rs.")[1]
        : "";
    }
    setRowValues(
      (x) =>
        (x = {
          ...x,
          Balance_toCustomer: isNumber(val)
            ? (row.Balance_toCustomer
                ? (row.Balance_toCustomer as number)
                : x.OrderTotal_amt) -
                val >
              0
              ? (row.Balance_toCustomer
                  ? (row.Balance_toCustomer as number)
                  : x.OrderTotal_amt) - val
              : 0
            : row.Balance_toCustomer,

          Balance_toCompany: isNumber(val)
            ? (row.TotalAmt_recieved as number) + Number(val) > x.OrderTotal_amt
              ? (row.TotalAmt_recieved as number) +
                Number(val) -
                x.OrderTotal_amt
              : 0
            : row.Balance_toCompany,
        })
    );
  };

  const RefundValue = () => {
    return rowValues.Refund_amt ? rowValues.Refund_amt : "";
  };
  const refundOnChange = (e: any) => {
    let targetVal = e.target.value;
    let arr = targetVal.trim().split("Rs. ");
    let val: any = targetVal;
    if (!targetVal.includes("Rs.")) {
      val = "Rs. " + targetVal;
    } else {
      if (!isNumber(arr[1])) {
        val = "Rs. ";
      }
    }

    setRowValues((x) => (x = { ...x, Refund_amt: val }));
  };

  const paymentRecvdModeHandler = (val: any) => {
    if (
      (row.TotalAmt_recieved as number) > 0 &&
      rowValues.PaymentRecieved_mode === ""
    ) {
      setPromptMessage(
        "You can't change mode of received Payment to empty!!!",
        true,
        0
      );
      return false;
    } else if ((rowValues.TotalAmt_recieved as number) > 0 && val === "") {
      setPromptMessage("Please select the payment received mode!!!", true, 0);
      return false;
    }
    setRowValues((x) => (x = { ...x, PaymentRecieved_mode: val }));
    return true;
  };

  useEffect(() => {
    return () => {
      setRowValues({} as any);
    };
  }, []);

  return (
    <div className={style.editwrapper}>
      <EditPopup
        onClose={onClose}
        update={updateHandler}
        reset={resetHandler}
        clsBtnStyle={{ width: "10%", height: "10%" }}
      >
        <div>
          <span>Order Id</span>
          <span>{rowValues.Order_id}</span>
        </div>
        <div>
          <span>Customer Name</span>
          <span>{`${row.users.First_name}  ${row.users.Last_name}`}</span>
        </div>

        <div>
          <span>Order Status</span>
          <DroplistU
            style={{ color: "black" }}
            list={orderStatusList}
            name="orderstatuslist"
            value={orderStatusValue()}
            required={true}
            onChange={orderStatusOnChange}
          />
        </div>
        <div>
          <span>Order Amount</span>
          <span>Rs.{" " + rowValues.OrderTotal_amt} </span>
        </div>
        <div>
          <span>Balance To Company</span>
          <span>
            {rowValues.Balance_toCompany
              ? `Rs. ${" " + rowValues.Balance_toCompany}`
              : "-"}
          </span>
        </div>
        <div>
          <span>Balance To Customer</span>
          <span>
            {rowValues.Balance_toCustomer
              ? `Rs. ${rowValues.Balance_toCustomer}`
              : "-"}
          </span>
        </div>
        {(row.TotalAmt_recieved ||
          isNumber(
            (rowValues.TotalAmt_recieved as number)
              ?.toString()
              .trim()
              .split("Rs.")[1]
          )) && (
          <div>
            <span>Amt Recieved Balance</span>
            <span>{row.TotalAmt_recieved ? row.TotalAmt_recieved : 0}</span>
          </div>
        )}
        {rowValues.Order_status === "Delivered" &&
          (row.TotalAmt_recieved as number) < row.OrderTotal_amt && (
            <div>
              <span>Add Amount Received</span>
              <input
                type="text"
                name="addamtrecieved"
                value={recievedAmt ? recievedAmt : ""}
                onChange={amtRcvdOnChange}
                onBlur={amtRecdOnBlur}
              />
              <span style={{ color: "red", fontWeight: 400 }}>
                Add New Amt. only
              </span>
            </div>
          )}

        {rowValues.Order_status === "Delivered" &&
          (row.TotalAmt_recieved as number) < row.OrderTotal_amt && (
            <div>
              <span>Payment Received Mode</span>
              <DroplistU
                list={paymentRecievedModeList}
                name="paymentReceievdList"
                value={
                  rowValues.PaymentRecieved_mode
                    ? rowValues.PaymentRecieved_mode
                    : ""
                }
                onChange={paymentRecvdModeHandler}
              />
            </div>
          )}

        {(isNumber(rowValues.Refund_amt?.toString().trim().split("Rs.")[1]) ||
          isNumber(row.Refund_amt)) && (
          <div>
            <span>Refund Amount</span>
            {row.Refund_amt ? row.Refund_amt : 0}
          </div>
        )}

        {rowValues.Order_status === "Cancel" && (
          <div>
            <span>Refund Amount</span>
            <input
              type="text"
              name="refund"
              value={RefundValue()}
              onChange={refundOnChange}
            />
          </div>
        )}
        <div>
          <span>Remark</span>
          <input type="text" name="remark" />
        </div>
      </EditPopup>
    </div>
  );
}

export default OrderDetailEdit;
