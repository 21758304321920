import CustomerDetail from "./components/CustomerDetail";
import style from "./BillCreation.module.css";
import ActionMenus from "./components/ActionMenus";
import PlaceOrder from "./components/PlaceOrder/PlaceOrder";

const BillCreation = (props: any) => {
  var ele: any = document.documentElement;

  // useEffect(() => {
  //   fullscreenHandler();
  // }, []);
  const fullscreenHandler = () => {
    if (ele.requestFullscreen) {
      ele.requestFullscreen();
    }
  };
  const closeHandler = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };
  return (
    <div id="billing" className={style["wrapper"]}>
      <div className={style["customer-wrapper"]}>
        <CustomerDetail />
      </div>
      <div className={style["prod-actionmenus-wrapper"]}>
        <PlaceOrder />
        <ActionMenus />
      </div>
    </div>
  );
};

export default BillCreation;
