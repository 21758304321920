interface type {
  value: any;
  required: string;
}

function DateTime(x: type) {
  const datetime = new Date(x.value);

  if (x.required === "date") {
    const dt = datetime
      .toLocaleDateString("en-US", {
        timeZone: "Asia/Kolkata",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/");
    return dt[1] + "-" + dt[0] + "-" + dt[2];
  } else if (x.required === "time") {
    return datetime.toLocaleTimeString("en-US", {
      timeZone: "Asia/Kolkata",
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  return "";
}

export default DateTime;
