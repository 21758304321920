import React from "react";
import style from "./Button.module.css";

const Button = (props: any) => {
  const { name, data, disabled, type } = props;
  let buttonStyle;
  if (type === "buttonControl1") {
    buttonStyle = style.buttonControl1;
  }
  const button = (
    <button
      disabled={disabled}
      onClick={() => props.onClick(data)}
      name={name}
      className={buttonStyle}
    >
      {name}
    </button>
  );
  return <>{button}</>;
};

export default Button;
