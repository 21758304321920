import { useContext, useEffect, useState } from "react";
import textboxstyle from "../../../../common-css/Textbox.module.css";
import { isNumber } from "../../../../customer-app/utility/validation";
import query from "../../../data-centre/Queries";
import style from "./CustomerDetail.module.css";
import DatePicker from "react-datepicker";
import {
  CustomerProfile,
  ItemAvailable,
  OrderItemSummary,
  wrapper_order_detail_summary_user,
} from "../../../../Interface/Purchase";
import BillingContext from "../../../data-centre/Billing-context";

export default function Invoice({
  setStartDate,
  startDate,
  promptMessageHandler,
  setLoaderVisibility,
}: any) {
  const [userData, setUserData] = useState<wrapper_order_detail_summary_user>();
  const [invoiceSearch, setInvoiceSearch] = useState<any>();
  const ctx = useContext(BillingContext);
  const [invoice, setInvoice] = useState<number>();

  const invoiceSearchHandler = async (orderId: number) => {
    setLoaderVisibility(true);
    await query.Order.list({ orderId: orderId })
      .then((response) => {
        setLoaderVisibility(false);
        if (response.statusText === "OK") {
          if (response.data.length > 0) {
            setUserData(response.data[0]);
          } else {
            setUserData({} as any);
            promptMessageHandler("No Record found!!!");
          }
        }
      })
      .catch((error) => {
        promptMessageHandler("Some Error Occur!!!");
      });
  };

  useEffect(() => {
    console.log("invoice section");
    if (
      userData !== null &&
      userData !== undefined &&
      Object.keys(userData).length > 0
    ) {
      ctx.setUserProfile(userData?.users as CustomerProfile);
      const ItemAvailable: ItemAvailable[] = [];
      ctx.setOrderItem(userData.orderItemSummaryMappers);
      userData.orderItemSummaryMappers.map((key, index: number) => {
        ItemAvailable.push({
          Stock_id: key.Stock_id,
          Variant_name: userData._products[index].Variant_name,
          Pricing_id: key.OrderItem_id as number,
          ArchievePricing_id: key.ArchievePricing_id?.toString(),
          _metric: key.Qty_metric,
          SndCgst_code: key.Gst_id as number,
          Unit_mrp: key.Unit_mrp,
          Avg_cp: key.Unit_price as number,
          Metric_rules: userData._products[index].Metric_rules,
          Stock_available: key.Qty_order,
        });
      });
      ctx.setBillinItems(ItemAvailable);
      ctx.setOrderPointerEvents(true);
      ctx.setOrderDetail({ ...userData });
      if (userData.OrderInvoice_status == "frammed") {
        ctx.setInvoicePointerEvents(true);
      } else {
        ctx.setInvoicePointerEvents(false);
      }
    } else {
      ctx.setUserProfile({} as any);
      ctx.setBillinItems([]);
      ctx.setOrderItem([]);
    }
    ctx.setInvoice(userData?.Order_id);
  }, [userData]);

  useEffect(() => {
    if (invoiceSearch !== undefined && invoiceSearch.length === 0) {
      // ctx.setUserProfile({} as any);
      setUserData(undefined);
      ctx.setInvoice(undefined);
    }
  }, [invoiceSearch]);

  useEffect(() => {
    if (ctx.getInvoice()) {
      setInvoice(ctx.getInvoice());
      ctx.setInvoiceGenerateType(undefined);
    } else {
      setInvoice(undefined);
      setInvoiceSearch(undefined);
    }
  }, [ctx.getInvoice()]);

  return (
    <>
      <div className={style["invoicesearch-wrapper"]}>
        <input
          className={textboxstyle.textbox}
          type="search"
          name="invoicesearch"
          placeholder="Search invoice no.."
          onKeyDown={(e: any) => {
            if (!isNumber(e.key) && e.button !== 0 && e.key !== "Backspace") {
              e.preventDefault();
            }
            if (e.key === "Enter") {
              invoiceSearchHandler(e.target.value);
            }
          }}
          value={invoiceSearch ? invoiceSearch : ""}
          onChange={(e: any) => setInvoiceSearch(e.target.value)}
        ></input>
      </div>
      <div className={style.invoiceNo}>
        <span>Invoice Number</span>
        <input
          className={textboxstyle.textbox}
          disabled={invoice ? true : false}
          style={{ fontWeight: 600, fontSize: "15px", color: "black" }}
          name="invoiceNo"
          type="text"
          required={true}
          value={invoice ? invoice : ""}
          onChange={(e) => {
            if (isNumber(e.target.value)) {
              setInvoice(Number(e.target.value));
              ctx.setInvoiceGenerateType("manula");
            } else {
              setInvoice(undefined);
            }
          }}
        />
      </div>
      <div className={style.date}>
        <span>Invoice Date</span>
        <DatePicker
          disabled
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          dateFormat="dd-MM-yyyy"
          className={textboxstyle.textbox + " " + style.datelist}
        />
      </div>
    </>
  );
}
