import style from "./PlaceOrder.module.css";
import style1 from "../CustomerDetail.module.css";
import textboxstyle from "../../../../../common-css/Textbox.module.css";
import { useState, useEffect, useRef, useContext } from "react";
import {
  CustomerProfile,
  ItemAvailable,
  OrderDetail,
  OrderItemSummary,
} from "../../../../../Interface/Purchase";
import DroplistU from "../../../common-utility/DroplistU";
import { isNumber } from "../../../../../customer-app/utility/validation";
import BillingContext from "../../../../data-centre/Billing-context";
import MessageModal from "../../../common-utility/MessageModal";
import DatePicker from "react-datepicker";
import { QueryDataHandler } from "./PlaceOrderSubComponents/QueryDataHandler";
import InputSearch from "./PlaceOrderSubComponents/InputSearch";
import { AddItemHandler } from "./PlaceOrderSubComponents/AddItemHandler";
import AddOrderItem from "./AddOrderItem";
import LoginContext from "../../../../data-centre/Login-context";

const PlaceOrder = (props: any) => {
  const [selectedTab, setSearchTab] = useState<number>(0);
  const [searchList, setSearchList] = useState<any>([]);
  const [orderItemList, setOrderItemList] = useState<OrderItemSummary[]>([]);
  const [productList, setProductList] = useState<ItemAvailable[]>([]);
  const [productListKey, setProductListKey] = useState<number[]>([]);
  const [mappedKeys, setMappedKeys] = useState<any[]>([]);
  const [modifiedSearchList, setModifiedSearchList] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>();
  const [checkPoint, setCheckPoint] = useState<boolean>(true);
  // const [isHoverOnRow, setRowHover] = useState<boolean>(false);
  // const [selectCategory, setSelectCategory] = useState<any>([
  //   { name: "", value: 0 },
  // ]);
  const [searchBox, setSearchBox] = useState<string>("");
  //const [gstRules, setGstRules] = useState<any>([]);
  const [index, setIndex] = useState(-1);
  const [deliveryCharge, setDeliveryCharge] = useState<string>("0");
  const [pointerEvents, setPointerEvents] = useState<any>();
  const [total, setTotal] = useState<number>(0);
  const [otherDiscount, setOtherDiscount] = useState<number>(0);
  const [promptMessage, setPromptMessage] = useState<string>("");
  const [promptVisible, setPromptVisibility] = useState<boolean>(false);
  const [roleIndex, setRoleIndex] = useState<number>(-1);
  type rowSelectedType = [
    index: number,
    bgcolor: string,
    color: string,
    key: number,
    pricingId: number
  ];
  const [rowSelected, setSelectedRow] = useState<rowSelectedType>([
    -1,
    "",
    "",
    -1,
    -1,
  ]);
  const [elementsDisability, setElementsDisability] = useState<boolean>(false);

  const ctx = useContext(BillingContext);
  const loginCTX = useContext(LoginContext);
  const inputRef: any = useRef([]);

  const header = [
    { name: "SNo." },
    { name: "ITEM" },
    { name: "QTY" },
    { name: "METRIC" },
    { name: "MRP" },
    { name: "TAX. VALUE" },
    { name: "TAX" },
    { name: "UNIT PRICE" },
    { name: "DISCOUNT" },
    { name: "SUB TOTAL" },
  ];

  const deliveryChargesList = [
    { name: 10, value: 10 },
    { name: 20, value: 20 },
    { name: 30, value: 30 },
    { name: 40, value: 40 },
    { name: 50, value: 50 },
    { name: 100, value: 100 },
    { name: 150, value: 150 },
    { name: 200, value: 200 },
  ];

  //**RESETING PLACE ORDER PROPERTIES */
  useEffect(() => {
    if (ctx.getNewOrder() || ctx.getClearItems()) {
      setSearchTab(0);
      setSearchList([]);
      setOrderItemList([]);
      setProductList([]);
      setProductListKey([]);
      setStartDate(null);
      setModifiedSearchList([]);
      setSearchBox("");
      setIndex(-1);
      setDeliveryCharge("0");
      setTotal(0);
      setOtherDiscount(0);
      inputRef.current = [];
      ctx.setBillinItems([]);
      let x: any = {};
      ctx.setOrderItemDetail([]);
      ctx.setInvoiceGenerateType(undefined);
      ctx.setOrderItem([]);
      if (ctx.getNewOrder()) {
        ctx.setNewOrder(false);
        ctx.setOrderPointerEvents(false);
        ctx.setInvoicePointerEvents(false);
        setRoleIndex(-1);
      }
      if (ctx.getClearItems()) {
        ctx.setOrderDetail(
          ((y: any) =>
            (y = {
              ...x,
              Customer_id: y.Customer_id,
              Order_status: y.Order_status,
              OrderCreated_at: y.OrderCreated_at,
              OrderInvoice_status: y.OrderInvoice_status,
            })) as any
        );
        ctx.setClearItems(false);
      } else {
        ctx.setOrderDetail(x);
      }
    }
  }, [ctx.getNewOrder(), ctx.getClearItems()]);
  //**END */

  useEffect(() => {
    ctx.setGstRules();

    ctx.setOrderDetail({
      ...ctx.getOrderDetail(),
      _biller: loginCTX.getUser() ? loginCTX.getUser().userId : "anonymous",
    });
    // return () => {
    //   setProductList([]);
    //   setMappedKeys([]);
    //   setProductListKey([]);
    //   setOrderItemList([]);
    //   setSearchList([]);
    //   setModifiedSearchList([]);
    //   ctx.setBillinItems([]);
    //   ctx.setOrderItemDetail([]);
    //   ctx.setOrderDetail({} as any);
    //   ctx.setOrderItem([]);
    //   ctx.setUserProfile({} as any);
    //   ctx.setNewOrder(false);
    //   ctx.setClearItems(false);
    //   ctx.setRoleIndex({} as any);
    //   ctx.setInvoice(undefined);
    //   ctx.setInvoiceGenerateType(undefined);
    // };
  }, []);

  useEffect(() => {
    console.log(`roleindex is ${ctx.getRoleIndex()}`);
    if (searchBox !== "" && searchBox.length > 2 && ctx.getRoleIndex() > -1) {
      QueryDataHandler({
        rowSelected,
        ctx,
        searchBox,
        setSearchList,
        setModifiedSearchList,
      });
    } else {
      setSearchList([]);
      if (searchBox.length <= 3) {
        setModifiedSearchList([]);
      }
    }
    return () => {};
  }, [searchBox]);

  useEffect(() => {
    inputRef.current = inputRef.current.slice(0, searchList.length);
  }, [searchList.length]);

  //** SEARCHLIST POPUP COLOR */
  useEffect(() => {
    if (inputRef.current[index] !== null && index > -1) {
      inputRef.current[index].scrollIntoView({ behavior: "smooth" });
      inputRef.current[index].style.backgroundColor = "#98AFC7";
    }
  }, [index]);

  useEffect(() => {
    if (rowSelected[0] === -1) {
      let val = orderItemList.reduce(
        (sum: any, prop: any) => sum + prop.Sub_total,
        0
      );
      if (isNumber(val)) {
        setTotal(Number(val.toFixed(2)));
      }
      console.log("orderitemlist:", orderItemList);
      ctx.setOrderItem(orderItemList);
      ctx.setOrderDetail({
        ...ctx.getOrderDetail(),
        OrderTotal_amt: isNumber(Number(deliveryCharge) + val)
          ? Math.round(Number(deliveryCharge) + val)
          : 0,
      });
      ctx.setBillinItems(productList);
    }
  }, [orderItemList]);

  const addItemHandler = (id: number) => {
    AddItemHandler({
      id,
      setSelectedRow,
      rowSelected,
      searchList,
      setOrderItemList,
      orderItemList,
      setProductList,
      setModifiedSearchList,
      setSearchList,
      setSearchBox,
      setMappedKeys,
      mappedKeys,
      setCheckPoint,
      productList,
      setProductListKey,
      productListKey,
      setPromptMessage,
      setPromptVisibility,
      ctx,
    });
  };

  const orderItemHandler = (obj: any) => {
    if (rowSelected[0] === -1 && ctx.getInvoice() === undefined) {
      let orderItemArr = [...orderItemList];
      orderItemArr[obj.index].Qty_order = Number(obj.qtyInput);
      orderItemArr[obj.index].Qty_metric = obj.metricChoosen;
      orderItemArr[obj.index].Unit_price = Number(obj.unitSP);
      orderItemArr[obj.index].Sub_total = Number(obj.subTotal);
      orderItemArr[obj.index].Taxable_amt =
        obj.taxableValue === undefined || obj.taxableValue === ""
          ? null
          : Number(obj.taxableValue);
      orderItemArr[obj.index].UnitPrice_manual = !obj.UnitPriceManual
        ? null
        : Number(obj.UnitPriceManual);
      orderItemArr[obj.index].Unit_mrp = Number(obj.unitMRP);
      setOrderItemList(orderItemArr);
    }
  };

  //this key handler is important // and will act differently when invoice is defined and when undefined
  const keyHandler = (index: number) => {
    let key: number;
    let ind = index + 1;
    if (productListKey.length >= ind) {
      key = productListKey[index];
    } else {
      key = index === 0 ? ind : productListKey[index - 1] + 1;

      setProductListKey((x) => (x = [...x, key]));
    }
    return key;
  };

  const itemSelectedIndexHandler = (
    index: number,
    key: number,
    pricingId: number
  ) => {
    console.log("index: ", index, "key: ", key, "pricingid: ", pricingId);
    if (rowSelected[0] === index) {
      setSelectedRow((y: rowSelectedType) => (y = [-1, "", "", -1, -1]));
    } else {
      setSelectedRow(
        (y: rowSelectedType) => (y = [index, "blue", "white", key, pricingId])
      );
    }
  };

  useEffect(() => {
    if (ctx.getRoleIndex() < 0 && !ctx.getRoleIndex()) {
      setSearchList([]);
    }
  }, [ctx.getRoleIndex()]);

  useEffect(() => {
    if (rowSelected[0] !== -1) {
      QueryDataHandler({
        setSelectedRow,
        rowSelected,
        ctx,
        searchBox,
        setSearchList,
        setModifiedSearchList,
      });
    } else {
      setModifiedSearchList([]);
    }
  }, [rowSelected]);

  useEffect(() => {
    if (ctx.getInvoice()) {
      {
        const mappedKeysArray: any = [];
        ctx.getBillingItems().map((item: ItemAvailable) => {
          mappedKeysArray.push([item.Pricing_id]);
        });
        let keyArray = [...Array(mappedKeysArray.length)].map(
          (_, index: number) => index + 1
        );
        setProductListKey(keyArray);
        setMappedKeys(mappedKeysArray);
        setOrderItemList(ctx.getOrderItem());
        setProductList(ctx.getBillingItems());

        console.log(
          "date is:",
          (ctx.getOrderDetail() as OrderDetail).DeliveryRequest_date
        );

        setStartDate(
          Date.parse((ctx.getOrderDetail() as OrderDetail).DeliveryRequest_date)
        );

        setDeliveryCharge(
          (ctx.getOrderDetail() as OrderDetail).Delivery_charge
            ? (ctx.getOrderDetail() as OrderDetail).Delivery_charge.toString()
            : ""
        );
      }
    } else {
      setProductList([]);
      setMappedKeys([]);
    }
  }, [ctx.getInvoice()]);

  const deliveryOnChangeHandler = (val: any) => {
    setDeliveryCharge(val);
    ctx.setOrderDetail({
      ...ctx.getOrderDetail(),
      Delivery_charge: parseFloat(val),
      OrderTotal_amt: Math.round(
        isNumber(val) ? Number(val) : 0 + (total === undefined ? 0 : total)
      ),
    });
  };

  const dateOnChangeHandler = (date: any) => {
    date.setHours(new Date().getHours());
    date.setMinutes(new Date().getMinutes());
    date.setSeconds(new Date().getSeconds());
    setStartDate(date);
    ctx.setOrderDetail({
      ...ctx.getOrderDetail(),
      DeliveryRequest_date: date,
    });
  };

  const removeItemHandler = (id: any) => {
    let arr = [...productList];
    arr.splice(id, 1);
    console.log("productlist_inremove: ", arr);
    setProductList(arr);

    if (!ctx.getInvoice()) {
      let arr2 = [...orderItemList];
      arr2.splice(id, 1);
      for (let i = id; i < arr2.length; i++) {
        arr2[i].Item_sequence = i + 1;
      }
      setOrderItemList(arr2);
      arr2 = [];
    }

    if (ctx.getInvoice()) {
      let mappedKeysArray = [...mappedKeys];
      let indices: [key: number, storeId: number] = [-1, -1];
      for (let i = 0; i < mappedKeys.length; i++) {
        for (let k = 1; k < mappedKeys[i].length; k++) {
          if (mappedKeys[i][k] === productList[id].Pricing_id) {
            indices = [i, k];
            break;
          }
        }
        if (indices[0] !== -1 && indices[1] !== -1) {
          break;
        }
      }

      let orderItemDetailArr = [...ctx.getOrderItemDetail()];

      let index = orderItemDetailArr.findIndex(
        (item) => item.OrderItem_id === mappedKeys[indices[0]][0]
      );

      if (orderItemDetailArr[index].Store_ids.length === 1) {
        orderItemDetailArr.splice(index, 1);
      } else {
        orderItemDetailArr[index].Store_ids.splice(indices[1] - 1, 1);
        orderItemDetailArr[index].Qtys_taken?.splice(indices[1] - 1, 1);
        orderItemDetailArr[index].Qtys_metric?.splice(indices[1] - 1, 1);
      }
      console.log("orderitemdetail_inremove: ", orderItemDetailArr);
      ctx.setOrderItemDetail([...orderItemDetailArr]);
      orderItemDetailArr = [];

      mappedKeysArray[indices[0]].splice(indices[1], 1);
      setMappedKeys([...mappedKeysArray]);
      mappedKeysArray = [];
    }

    // if (!ctx.getInvoice()) {
    //   mappedKeysArray.splice(id, 1);
    //   setMappedKeys([...mappedKeysArray]);
    // }

    let keyArr = [...productListKey];
    keyArr.splice(id, 1);
    console.log("pdlistkey_inremove: ", keyArr);
    setProductListKey(keyArr);
    if (ctx.getInvoice()) {
      itemSelectedIndexHandler(-1, -1, -1);
    }
    arr = [];
    keyArr = [];
  };

  useEffect(() => {
    console.log(
      "orderpointer",
      ctx.getOrderPointerEvents(),
      "invoicepointer",
      ctx.getInvoicePointerEvents()
    );
    if (ctx.getOrderPointerEvents()) {
      setElementsDisability(true);
    } else if (!ctx.getOrderPointerEvents() && !ctx.getInvoicePointerEvents()) {
      setElementsDisability(false);
    }
  }, [ctx.getOrderPointerEvents()]);

  useEffect(() => {
    console.log("useeffect invoicepointer: ", ctx.getInvoicePointerEvents());
    if (ctx.getInvoicePointerEvents()) {
      if (ctx.getOrderPointerEvents()) {
        ctx.setOrderPointerEvents(false);
      }
      setElementsDisability(true);
    } else {
      setElementsDisability(false);
    }
  }, [ctx.getInvoicePointerEvents()]);

  // useEffect(() => {
  //   console.log("orderItemDetailList: ", ctx.getOrderItemDetail());
  //   console.log("mappedkey: ", mappedKeys);
  // }, [ctx.getOrderItemDetail()]);

  // useEffect(() => {
  //   console.log("orderDetail: ", ctx.getOrderDetail());
  // }, [ctx.getOrderDetail()]);

  return (
    <>
      {promptVisible && (
        <MessageModal
          message={promptMessage}
          close={() => setPromptVisibility(false)}
        />
      )}
      <div className={style1["wrapper"] + " " + style["wrapper"]}>
        <div className={style.productsearch}>
          {/* <Dropdown
            required={true}
            disable={false}
            isSelected={false}
            placeholder="category"
            className={textboxstyle.textbox}
            style={{
              width: "fit-content",
              height: "34px",
              marginTop: 0,
              marginBottom: 0,
              fontSize: "1em",
              cursor: "pointer",
            }}
            list={selectCategory}
            name="productdetail"
            onClick={(x: any) => {
              return x;
            }}
          /> */}
          <div className={style.searchoption}>
            <div
              onClick={() => setSearchTab(0)}
              className={selectedTab === 0 ? style.backgroundcolor : ""}
            >
              Name
            </div>
            <div
              onClick={() => setSearchTab(1)}
              className={selectedTab === 1 ? style.backgroundcolor : ""}
            >
              Barcode
            </div>
            <div
              onClick={() => setSearchTab(2)}
              className={selectedTab === 2 ? style.backgroundcolor : ""}
            >
              Code
            </div>
          </div>
          <div className={style.inputsearch}>
            <InputSearch
              searchBox={searchBox}
              ctx={ctx}
              setPromptMessage={setPromptMessage}
              setPromptVisibility={setPromptVisibility}
              // roleIndexHandler={roleIndexHandler}
              setIndex={setIndex}
              itemSelectedIndexHandler={itemSelectedIndexHandler}
              setSearchBox={setSearchBox}
              selectedTab={selectedTab}
              modifiedSearchList={modifiedSearchList}
              index={index}
              searchList={searchList}
              addItemHandler={addItemHandler}
              inputRef={inputRef}
              elementsDisability={elementsDisability}
            />
          </div>
          <div className={style["deliveryDatewrapper"]}>
            <DatePicker
              selected={startDate}
              todayButton="Today"
              onChange={dateOnChangeHandler}
              placeholderText="Delivery Date"
              className={textboxstyle.textbox + " " + style.deliveryDate}
              disabled={elementsDisability}
              timeInputLabel="Time:"
              dateFormat="dd-MM-yyyy"
              showTimeInput
            />
          </div>
          <div className={style.otherdiv}>
            <DroplistU
              className={textboxstyle.textbox + " " + style.deliveryCharge}
              list={deliveryChargesList}
              name="deliverychargelist"
              placeholder="Delivery Charge"
              value={deliveryCharge}
              onChange={deliveryOnChangeHandler}
              disabled={elementsDisability}
            />
          </div>
        </div>

        <div className={style["product-wrapper"]}>
          <div className={style.header}>
            {header.map((item) => (
              <div key={item.name}>{item.name}</div>
            ))}
          </div>

          {/* {console.log("productlist: ", productList)} */}
          {productList.map((item: any, index: any) => {
            return (
              <div key={keyHandler(index)} style={{ margin: 0, padding: 0 }}>
                <AddOrderItem
                  removeItem={removeItemHandler}
                  checkPoint={checkPoint}
                  role={(ctx.getUserProfile() as CustomerProfile)._role}
                  index={index}
                  list={item}
                  gstRules={ctx.getGstRules()}
                  orderItemHandler={orderItemHandler}
                  itemSelectedIndex={itemSelectedIndexHandler}
                  rowSelected={rowSelected}
                  mappedKeys={mappedKeys}
                  orderItemList={orderItemList}
                  invoice={ctx.getInvoice()}
                  billingctx={ctx}
                />
              </div>
            );
          })}
        </div>
        <div className={style["subtotal-wrapper"]}>
          <div>
            <span>Subtotal</span>
            <span>{total.toString()}</span>
          </div>
          <div>
            <span>Other Discount</span>
            <span>0</span>
          </div>
          <div>
            <span>Delivery Charge</span>
            <span>
              Rs
              {isNumber(deliveryCharge) ? deliveryCharge : 0}
            </span>
          </div>
          <div>
            <span>Net Payable Amt.(Round Off +/-)</span>
            <span>
              Rs{" "}
              {(
                total +
                (isNumber(deliveryCharge) ? parseFloat(deliveryCharge) : 0)
              ).toFixed(0)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default PlaceOrder;
