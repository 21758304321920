import { useEffect, useState } from "react";
import style from "./Slider.module.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { BsSquareFill } from "react-icons/bs";

function Slider(props: any) {
  const [image, setImage] = useState<any>(props.arr[0]);
  const [count, setCount] = useState<number>(0);
  // const [sliderStyle, setSliderStyle] = useState<any>(style.imagediv);
  const length = props.arr.length;
  const dummy_array = Array(length).fill(1);

  useEffect(() => {
    const id = setInterval(() => {
      handler("inc");
    }, 5000);

    return () => {
      clearInterval(id);
    };
  });

  const handler = (type: string) => {
    let x = count;
    if (type === "dec") x = count === 0 ? length - 1 : --x;
    else x = count === length - 1 ? 0 : ++x;
    setCount(x);
    setImage(props.arr[x]);
  };

  const squareHandler = (key: any) => {
    console.log("square-handler" + key);
    setCount(key);
    setImage(props.arr[key]);
  };

  return (
    <div className={style["wrapper"]}>
      <FaAngleLeft onClick={() => handler("dec")} className={style.leftarrow} />
      <FaAngleRight
        onClick={() => handler("inc")}
        className={style.rightarrow}
      />
      <div className={style["square-wrapper"]}>
        {dummy_array.map((ar, index) => (
          <BsSquareFill
            onClick={() => squareHandler(index)}
            key={index}
            className={
              index === count
                ? style["image-square-select"]
                : style["image-square"]
            }
          />
        ))}
      </div>
      {image}
    </div>
  );
}

export default Slider;
