import { useState } from "react";
import LabelInput from "../../../../common-elements/LabelInput";
import ManinSectionHeader from "../../../../components/management/common-components/MainSectionHeader";
import style from "../registration/Registration.module.css";
import statecityjson from "../../../../json-files/States.json";
import SearchableDropdown from "../../../../common-elements/searchableDropdown/SearchableDropdown";

function Address(props: any) {
  const [required, setRequired] = useState<boolean>(true);

  let listjson = statecityjson.map((x: any, index1: number) => ({
    id: index1,
    country: "India",
    code: "+91",
    state: x.state,
    districts: x.districts.map((y: any, index2: number) => ({
      id: index1.toString() + "-" + index2.toString(),
      city: y,
    })),
  }));

  let countryList: any = [];
  let cityList: any = [];
  let stateList: any = [];

  countryList = [
    {
      sno: 0,
      id: "+91",
      name1: "India",
      name2: "",
      color: "",
    },
  ];

  listjson.map((x: any, index: number) =>
    x.state === "Rajasthan"
      ? (stateList = [
          ...stateList,
          {
            sno: index,
            id: x.id,
            name1: x.state,
            name2: "",
            color: "",
          },
        ])
      : null
  );

  listjson.map((x: any, index: number) =>
    x.state === "Rajasthan"
      ? x.districts.map(
          (y: any, index: number) =>
            (cityList = [
              ...cityList,
              {
                sno: index,
                id: y.id,
                name1: y.city,
                name2: "",
                color: "",
              },
            ])
        )
      : null
  );

  const changeHandler = (e: any) => {
    e.target.setCustomValidity("");
  };

  const invalidHandler = (e: any) => {
    const errorMsz =
      e.target.value !== "" ? "PIN is not valid" : "Please fill out this field";

    return e.target.name === "pin"
      ? e.target.setCustomValidity(errorMsz)
      : null;
  };

  const menuArray = ["Home", "Customers"];

  const inputArray = [
    {
      labelName: "Address Line 1",
      type: "text",
      required: required,
      name: "address1",
    },
    {
      labelName: "Address Line 2",
      type: "text",
      required: false,
      name: "address2",
    },
    {
      labelName: "Country",
      type: "text",
      required: required,
      name: "country",
      styles: { color: "" },
      placeholder: "Select country..",
      listArray: countryList,
    },
    {
      labelName: "State",
      type: "text",
      required: required,
      name: "state",
      styles: { color: "" },
      placeholder: "Select state..",
      listArray: stateList,
    },
    {
      labelName: "City",
      type: "text",
      required: required,
      name: "city",
      styles: { color: "" },
      placeholder: "Select city..",
      listArray: cityList,
    },
    {
      labelName: "PIN code",
      type: "number",
      required: required,
      name: "pin",
      max: 999999,
      min: 100000,
    },
  ];

  const submitHandler = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <ManinSectionHeader headerName="Add Address" subMenu={menuArray} />

      <form
        autoComplete="off"
        onSubmit={submitHandler}
        className={style["main-wrapper"]}
      >
        {inputArray.map((x: any) => {
          let property = {
            ...x,
            onChange: changeHandler,
          };

          return (
            <div key={x.name}>
              {x.name === "country" ||
              x.name === "state" ||
              x.name === "city" ? (
                <SearchableDropdown id={x.labelName} {...x} />
              ) : (
                <LabelInput
                  onInvalid={(e) =>
                    x.name === "pin" ? invalidHandler(e) : null
                  }
                  {...property}
                />
              )}
            </div>
          );
        })}
        <div>
          <button className={style.buttons} type="submit">
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default Address;
