import { useContext, useEffect, useState } from "react";
import style from "./SideMenu.module.css";
import { AiOutlineCaretDown } from "react-icons/ai";
import { menu, subMenu } from "../../data-centre/SideMenuData";
import LoginContext from "../../data-centre/Login-context";
import OrderContext from "../../data-centre/Order-context";

function SideMenu(props: any) {
  const [menuClicked, setMenuClicked] = useState<string>("dashboard");

  // const [isDashboardUl, setDashBoardUl] = useState<boolean>(false);
  const [isCategoryUl, setCategoryUl] = useState<boolean>(false);
  const [isProductUl, setProductUl] = useState<boolean>(false);
  const [isPurchaseUl, setPurchaseUl] = useState<boolean>(false);
  const [isSupplierUl, setSupplierUl] = useState<boolean>(false);
  const [isPriceUl, setPriceUl] = useState<boolean>(false);
  const [isCustomerUl, setCustomerUl] = useState<boolean>(false);
  const [isStoreUl, setStoreUl] = useState<boolean>(false);
  const [isReportsUl, setReportsUl] = useState<boolean>(false);
  const [isOrderUl, setOrderUl] = useState<boolean>(false);
  const [isBillingUl, setBillingUl] = useState<boolean>(false);

  const menuVisibility = {
    isDashboardMenu: false,
    isCategoryMenu: false,
    isProductMenu: false,
    isPurchaseMenu: false,
    isSupplierMenu: false,
    isPriceMenu: false,
    isCustomerMenu: false,
    isStoreMenu: false,
    isReportsMenu: false,
    isOrderMenu: false,
    isBillingMenu: false,
  };
  const [menuAuthorization, setMenuAuthorizedVisibility] =
    useState(menuVisibility);
  // const [isSettingUl, setSettingUl] = useState<boolean>(false);

  const loginCtx = useContext(LoginContext);
  const orderCtx = useContext(OrderContext);

  useEffect(() => {
    if (
      loginCtx.getUser().userId === "triloksingh.prajapat" &&
      loginCtx.getUser().password === "SinghTrilok855$$"
    ) {
      setMenuAuthorizedVisibility(
        (x) => (x = { ...x, isBillingMenu: true, isOrderMenu: true })
      );
    } else {
      setMenuAuthorizedVisibility(
        (x) =>
          (x = {
            isDashboardMenu: true,
            isCategoryMenu: true,
            isProductMenu: true,
            isPurchaseMenu: true,
            isSupplierMenu: true,
            isPriceMenu: true,
            isCustomerMenu: true,
            isStoreMenu: true,
            isReportsMenu: true,
            isOrderMenu: true,
            isBillingMenu: true,
          })
      );
    }
  }, []);

  const obj = {
    sectionClicked: "",
    subSectionClicked: 0,
    previousMenuClicked: "",
  };

  const clickHandler = (
    section: string,
    subSection: number,
    prvSection: string
  ) => {
    obj.sectionClicked = section;
    obj.subSectionClicked = subSection;
    obj.previousMenuClicked = prvSection;
    setMenuClicked(section);
    props.mainSectionHandler(obj);
  };

  return (
    <div className={style.wrapper}>
      <div className={style["aside-container"]}>
        {/* {menu.map((x) => (
          <a className={style.dashBoard} href={"#" + x}>
            <p>{x}</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        ))} */}
        {menuAuthorization.isDashboardMenu && (
          <a
            id="dashboard"
            onClick={
              () => clickHandler("dashboard", 0, menuClicked)
              // setMenuClicked("dashboard");
            }
            className={style.dashBoard}
            href="#DashBoard"
          >
            <p>DashBoard</p>
          </a>
        )}
        {menuAuthorization.isCategoryMenu && (
          <a
            onClick={() => {
              if (isCategoryUl === false) {
                setCategoryUl(true);
              } else {
                setCategoryUl(false);
              }
            }}
            className={style.category}
            href="#Category"
          >
            <p>Category</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isCategoryUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("category", 1, menuClicked);
              }}
            >
              Manage Category
            </li>
            <li
              onClick={() => {
                clickHandler("category", 2, menuClicked);
              }}
            >
              Manage SubCategory
            </li>
          </ul>
        )}
        {menuAuthorization.isProductMenu && (
          <a
            onClick={() => {
              if (isProductUl === false) {
                setProductUl(true);
              } else {
                setProductUl(false);
              }
            }}
            className={style.product}
            href="#Product"
          >
            <p>Product</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isProductUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("product", 1, menuClicked);
              }}
            >
              Manage Products
            </li>
            {/* <li
              onClick={() => {
                clickHandler("product", 2, menuClicked);
              }}
            >
              Update Product
            </li> */}
          </ul>
        )}
        {menuAuthorization.isPurchaseMenu && (
          <a
            onClick={() => {
              if (isPurchaseUl === false) {
                setPurchaseUl(true);
              } else {
                setPurchaseUl(false);
              }
            }}
            className={style.purchase}
            href="#Purchase"
          >
            <p>Purchase</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isPurchaseUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("purchase", 1, menuClicked);
              }}
            >
              Manage Purchase
            </li>
            {/* <li
              onClick={() => {
                clickHandler("purchase", 2, menuClicked);
              }}
            >
              Update Purchase
            </li> */}
          </ul>
        )}

        {menuAuthorization.isStoreMenu && (
          <a
            onClick={() => {
              if (isStoreUl === false) {
                setStoreUl(true);
              } else {
                setStoreUl(false);
              }
            }}
            className={style.store}
            href="#Store"
          >
            <p>Store</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isStoreUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("store", 1, menuClicked);
              }}
            >
              Buffer Stock
            </li>
            <li
              onClick={() => {
                clickHandler("store", 2, menuClicked);
              }}
            >
              Available Stock
            </li>
            <li
              onClick={() => {
                clickHandler("store", 3, menuClicked);
              }}
            >
              Live Stock
            </li>
            <li
              onClick={() => {
                clickHandler("store", 4, menuClicked);
              }}
            >
              Archieve Stock
            </li>
          </ul>
        )}
        {menuAuthorization.isSupplierMenu && (
          <a
            onClick={() => {
              if (isSupplierUl === false) {
                setSupplierUl(true);
              } else {
                setSupplierUl(false);
              }
            }}
            className={style.store}
            href="#Supplier"
          >
            <p>Supplier</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isSupplierUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("supplier", 1, menuClicked);
              }}
            >
              Manage Suppliers
            </li>
          </ul>
        )}
        {menuAuthorization.isPriceMenu && (
          <a
            onClick={() => {
              if (isPriceUl === false) {
                setPriceUl(true);
              } else {
                setPriceUl(false);
              }
            }}
            className={style.pricing}
            href="#Pricing"
          >
            <p> Pricing</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isPriceUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("price", 1, menuClicked);
              }}
            >
              Default SetPrice
            </li>
            <li
              onClick={() => {
                clickHandler("price", 2, menuClicked);
              }}
            >
              Price Formulation
            </li>
          </ul>
        )}
        {menuAuthorization.isCustomerMenu && (
          <a
            onClick={() => {
              if (isCustomerUl === false) {
                setCustomerUl(true);
              } else {
                setCustomerUl(false);
              }
            }}
            className={style.customer}
            href="#Customer"
          >
            <p>Customer</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isCustomerUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("customer", 1, menuClicked);
              }}
            >
              Manage Customers
            </li>
            <li
              onClick={() => {
                clickHandler("customer", 2, menuClicked);
              }}
            >
              Add Customer
            </li>
            <li
              onClick={() => {
                clickHandler("customer", 3, menuClicked);
              }}
            >
              Add Address
            </li>
          </ul>
        )}
        {menuAuthorization.isOrderMenu && (
          <a
            onClick={() => {
              if (isOrderUl === false) {
                setOrderUl(true);
              } else {
                setOrderUl(false);
              }
            }}
            className={style.order}
            href="#Order"
          >
            <p>Order</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isOrderUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("order", 1, menuClicked);
              }}
            >
              Orders Detail
            </li>
            <li
              onClick={() => {
                clickHandler("order", 2, menuClicked);
              }}
            >
              Return/Exchange
            </li>
            <li
              onClick={() => {
                clickHandler("order", 3, menuClicked);
              }}
            >
              Track Order
            </li>
          </ul>
        )}
        {menuAuthorization.isReportsMenu && (
          <a
            onClick={() => {
              if (isReportsUl === false) {
                setReportsUl(true);
              } else {
                setReportsUl(false);
              }
            }}
            className={style.reports}
            href="#Reports"
          >
            <p>Reports</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isReportsUl && (
          <ul>
            <li>Category</li>
            <li>Product</li>
            <li>Customer</li>
            <li>Order</li>
            <li>Pricing</li>
            <li>Purchasing</li>
            <li>Store</li>
          </ul>
        )}
        {menuAuthorization.isBillingMenu && (
          <a
            onClick={() => {
              if (isBillingUl === false) {
                setBillingUl(true);
              } else {
                setBillingUl(false);
              }
            }}
            className={style.billing}
            href="#Billing"
          >
            <p>Billing</p>
            <AiOutlineCaretDown className={style["down-arrow"]} />
          </a>
        )}

        {isBillingUl && (
          <ul>
            <li
              onClick={() => {
                clickHandler("billing", 1, menuClicked);
              }}
            >
              Create Bill
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default SideMenu;
