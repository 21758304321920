import CommonInterface from "./CommonInterface";
import style from "./Signup.module.css";

function Signup(props: any) {
  const additionaljsx = (
    <>
      <span className={style.loginText}>
        Already have an account? <u onClick={() => props.loginClick()}>Login</u>
      </span>
    </>
  );
  const dataHandler = (x: any) => {};
  return (
    <>
      <CommonInterface
        heading="Sign Up"
        buttonName="Continue"
        close={() => props.close()}
        additionaljsx={additionaljsx}
        onDataHandler={dataHandler}
      />
    </>
  );
}
export default Signup;
