import { useState } from "react";
import AvailableStockList from "./components/AvailableStockList";
import Search from "./components/Search";
import style from "./AvailableStock.module.css";
import ModalPopup from "../../../../customer-app/components/modal/ModalPopup";
import { useContext, useEffect } from "react";
import StoreContext from "../../../data-centre/Store-context";
import StockPopupChildren from "./components/StockPopupChildren";
import query from "../../../data-centre/Queries";
import LoaderUI from "../../common-utility/LoaderUI";
import MessageModal from "../../common-utility/MessageModal";

function AvailableStock(props: any) {
  const [storeVisibility, setStoreVisibility] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<any>();
  const [isNotificationVisible, setNotification] = useState<boolean>(false);
  const [isLoader, setLoader] = useState<boolean>(false);
  const [isMessageModal, setMessageModal] = useState<boolean>(false);
  const storeCtx = useContext(StoreContext);

  useEffect(() => {
    getNotification();
  });

  const getNotification = async () => {
    await query.ArchieveStore.list()
      .then((response) => {
        if (response.statusText === "OK") {
          setNotification((x) => (x = response.data));
        }
      })
      .catch((error) => console.log(error));
  };

  const listHandler = (e: any) => {
    setSearchOptions(e);
    setStoreVisibility(true);
  };

  const fwdHandler = async () => {
    setLoader((x) => (x = true));
    await query.Pricing.add({})
      .then((response) => {
        if (response.statusText === "OK") {
          setLoader((x) => (x = false));
          setMessageModal((x) => (x = true));
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={style["wrapper"]}>
      {isNotificationVisible && (
        <button
          className={style.forwardbtn}
          onClick={fwdHandler}
          name="forwardbtn"
          type="button"
        >
          Forward For Pricing
        </button>
      )}
      {isLoader && (
        <LoaderUI
          content="Forward Items..."
          size="medium"
          isDimmerActive={true}
          key="key"
        />
      )}
      {isMessageModal && (
        <MessageModal
          message="Forwarded Successfully.."
          close={() => {
            setMessageModal((x) => (x = false));
          }}
        />
      )}
      {storeCtx.getValue()[0] && (
        <ModalPopup>
          <StockPopupChildren values={storeCtx.getValue()[1] as any} />
        </ModalPopup>
      )}
      <Search listHandler={listHandler} />
      {storeVisibility ? (
        <AvailableStockList
          setTableVisibility={(x: any) => setStoreVisibility(x)}
          e={searchOptions}
        />
      ) : (
        <h2 style={{ color: "black", textAlign: "center" }}>
          Do Some Valid Search For Data
        </h2>
      )}
    </div>
  );
}

export default AvailableStock;
