import { useState, useEffect } from "react";
import SetItemPrice from "./price-actions/SetItemPrice";
import PriceFormulation from "./price-actions/PriceFormulation";

function Price(props: any) {
  const subMenu: number = props.menuType;
  const [isMenuVisible, setMenu] = useState<boolean>(false);

  useEffect(() => {
    if (subMenu === 1) {
      setMenu(true);
    } else if (subMenu === 2) {
      setMenu(false);
    }
  }, [subMenu]);

  return (
    <>
      {isMenuVisible && <SetItemPrice />}
      {!isMenuVisible && <PriceFormulation />}
    </>
  );
}

export default Price;
