import style from "./RightAside.module.css";
import { TbExchange } from "react-icons/tb";
import { FiTruck } from "react-icons/fi";
import { CgMonday } from "react-icons/cg";

function RightAside() {
  return (
    <div className={style.wrapper}>
      <div className={style["exchange-div"]}>
        <TbExchange className={style["icon"]} />
        <div className={style["para"]}>
          <h4>Exchange Policy</h4>
          <p>Exchange Items Very Easily</p>
        </div>
      </div>
      <div className={style["shipping-div"] + " " + style.div2}>
        <FiTruck className={style["icon"]} />
        <div className={style["para"]}>
          <h4>Free Shipping</h4>
          <p>Free Shipping For Orders</p>
        </div>
      </div>
      <div className={style["sameday-div"]}>
        <CgMonday className={style["icon"] + " " + style["sameday-icon"]} />
        <div className={style["para"]}>
          <h4>Delivery Policy</h4>
          <p>Same Day Delivery Available</p>
        </div>
      </div>
    </div>
  );
}

export default RightAside;
