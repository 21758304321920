import style from "./SliderImages.module.css";
import veg3 from "../assets/veg3.jpg";
import veg5 from "../assets/veg5.jpg";
import shippingbackgroundimg from "../assets/shippingbackgroundimg.jpg";

export const image1 = (
  <div className={style.wrapper}>
    <div className={style["paragraph-div1"]}>
      <p className={style.para1}>Welcome To Sahajj+ </p>
      <p className={style.para2}>
        Get Quality Veggies&nbsp;
      </p>
      <p className={style.para3}>At Fair Price...</p>
    </div>
    <img src={veg5} className={style.img1} />
  </div>
);

export const image2 = (
  <div className={style.wrapper}>
    <div className={style["paragraph-div1"]}>
      <p className={style.para1}> Get Your Products</p>
      <p className={style.para2}>Delivered At Your&nbsp;</p>
      <p className={style.para3}>Doorsteps On Time...</p>
      {/* <p className={style.para3}>ONLINE VEGGIES STORE</p> */}
    </div>
    <img src={shippingbackgroundimg} className={style.img2} />
  </div>
);

export const image3 = (
  <div className={style.wrapper}>
    <div className={style["paragraph-div1"]}>
      <p className={style.para1}> image3</p>
      <p className={style.para2}>it is image3</p>
      {/* <p className={style.para3}>ONLINE VEGGIES STORE</p> */}
    </div>
    <img src={veg3} className={style.img2} />
  </div>
);

