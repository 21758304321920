export const header = [
  "Buffer Id",
  "Product",
  "Variant",
  "Batch No",
  // "Entry At",
  "Quantity In",
  "Unit MRP",
  "Unit CP",
  "Purchase Amount",
  "Purchase Date",
  "Expiry",
  "Conversion Metric",
  "Brand",
  "Gst",
  "itmod",
  // "Other Tax",
  "UnitNetTax",
  // "TaxType",
  "Quantity Avail",
  "Quantity Out",
];

export const BufferStore = {
  Id: 0,
  Product_name: "",
  Variant_name: "",
  Created_at: new Date("1900-01-01").toISOString(),
  Quantity_in: 0,
  Metric: "",
  Unit_mrp: 0,
  Unit_cp: 0,
  Purchase_amt: 0,
  Metric_conversion: "",
  Brand: "",
  Expiry_date: new Date("1900-01-01").toISOString(),
  Gst: 0,
  Other_tax: 0,
  Net_tax: 0,
  Batch_no: "",
  TaxOn_status: "",
  Quantity_out: 0,
  Metric_out: "",
  Quantity_avail: 0,
  Metric_avail: "",
};
