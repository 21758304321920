import React from "react";
import { useState } from "react";
import LoaderUI from "../../../common-utility/LoaderUI";
import MessageModal from "../../../common-utility/MessageModal";
import Modal from "../../../common-utility/Modal";
import SubmitForm from "../basic/SubmitForm";

const AddProduct = (props: any) => {
  // const { catgList } = props.catgList;
  const [submitFormVisibility, setubmitFormVisibility] =
    useState<boolean>(true);
  const [messageVisibility, setMessageVisibility] = useState<boolean>(false);
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
  const [modalVisibility, setModalVisibility] = useState<boolean>(true);
  const [message, setMessage] = useState<string>();

  const product = {
    Id: "",
    Catg_Id: "",
    SubCatg_Id: 0,
    Product_Name: "",
    Variant_Name: "",
    Variant_Name_Additional: "",
    Product_Name_Hin: "",
    Variant_Name_Hin: "",
    Variant_Name_Hin_Additional: "",
    Size: "",
    Brand: "",
    Image: "",
    HSN:"",
    SndCgstCode:0,
    IgstCode:0,
    Category_Name: "",
    Subcategory_Name: "",
  };
  const messageHandler = (message: string) => {
    setMessage(message);
    setMessageVisibility(true);
  };

  const closeMessageHandler = () => {
    setMessageVisibility(false);
    setModalVisibility(true);
  };

  const loaderHandler = (visibility: boolean) => {
    if (visibility === true) {
      setModalVisibility(false);
    }
    setLoaderVisibility(visibility);
  };

  return (
    <>
      {modalVisibility && (
        <Modal>
          <SubmitForm
            catgList={props.catgList}
            cancel={props.cancel}
            product={{ product }}
            message={messageHandler}
            loader={loaderHandler}
            type="add"
          />
        </Modal>
      )}
      {console.log("message modal: ", messageVisibility)}
      {messageVisibility && (
        <MessageModal close={closeMessageHandler} message={message} />
      )}

      {loaderVisibility && (
        <LoaderUI content="Adding..." isDimmerActive={true} size="medium" />
      )}
    </>
  );
};

export default AddProduct;
