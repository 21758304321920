import { error } from "console";
import { useContext } from "react";
import { useState, useEffect } from "react";
import ProductContext from "../../../data-centre/Product-context";
import query from "../../../data-centre/Queries";
import LoaderUI from "../../common-utility/LoaderUI";
import AddFilter from "./actions/Add-Filter";
import DeleteProduct from "./actions/DeleteProduct";
import EditProduct from "./actions/EditProduct";
import Table from "./basic/Table";

function ManageProduct() {
  const [productItems, setProducts] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [editComponentVisibility, setEditComponentVisibility] =
    useState<boolean>(false);
  const [deleteComponentVisibility, setDeleteComponentVisibility] =
    useState<boolean>(false);
  const [editDeleteData, setEditDeleteData] = useState<any>();

  const ctx = useContext(ProductContext); // passing values through context

  useEffect(() => {
    getData();
    return function cleanup() {
      setProducts([]);
    };
  }, []);

  async function getData() {
    await query.Product.list()
      .then((response) => {       
        ctx.filterList(response.data);
      })
      .catch((error) => console.log(error));
    setLoader(false);
  }

  const editHandler = (data: any) => {
    setEditComponentVisibility(true);
    setEditDeleteData(data);
    console.log("edit button is clicked", data.Id, data.Product_Name);
  };

  const deleteHandler = (data: any) => {
    setDeleteComponentVisibility(true);
    setEditDeleteData(data);
    // console.log("delete button is clicked", data.Id, data.Product_Name);
  };

  const closeEditHandler = () => {
    setLoader(true);
    getData();
    setEditComponentVisibility(false);
  };

  const closeDeleteHandler = (x: any) => {
    if (x !== "cancel") {
      setLoader(true);
      getData();
    }
    setDeleteComponentVisibility(false);
  };

  const addHandler = () => {
    setLoader(true);
    getData();
  };

  return (
    <>
      <div>PRODUCT MANAGEMENT</div>

      <AddFilter add={addHandler} />

      {editComponentVisibility && (
        <EditProduct
          cancel={closeEditHandler}
          close={closeEditHandler}
          item={editDeleteData}
        />
      )}
      {deleteComponentVisibility && (
        <DeleteProduct
          cancel={() => closeDeleteHandler("cancel")}
          close={closeDeleteHandler}
          item={editDeleteData}
        />
      )}

      <Table
        editHandler={editHandler}
        deleteHandler={deleteHandler}
        properties={{ productItem: ctx.list.filter }}
      />

      {loader && (
        <LoaderUI content="Loading..." isDimmerActive={true} size="large" />
      )}
    </>
  );
}

export default ManageProduct;
