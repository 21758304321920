export const vendor = {
  Id: 0,
  Vendor_name: "",
  Bill_no: "",
  Bill_date: new Date("1 january 1900 00:00 UTC").toISOString(),
  Purchase_status: "",
  Payment_status: "",
  Payment_date: new Date("1 january 1900 00:00 UTC").toISOString(),
  Exchange_id: 0,
  Action_required: "None",
  Action_status: "None",
  Action_amount: 0,
  AA_date: new Date("1 january 1900 00:00 UTC").toISOString(),
  Action_quantity: 0,
  Metric: "",
  AQ_date: new Date("1 january 1900 00:00 UTC").toISOString(),
};

export const header = [
  "Id",
  "Purchase Status",
  "Payment Status",
  "Action Required",
  "Vendor Name",
  "Bill No",
  "Purchase Date",
  "Action Amount",
  "Action Amount Date",
  "Action Quantity",
  "Action Quantity Date",
];
