import { useState } from "react";
import StoreContext from "./Store-context";

const StoreProvider = (props: any) => {
  const [valueShared, setValueShared] = useState<any>();
  const [visibility, setVisibility] = useState<boolean>(false);

  const setValueHandler = (x: boolean, y: any) => {
    setVisibility(x);
    setValueShared(y);
  };

  const getValueHandler = () => {
    return [visibility, valueShared] as any;
  };

  const valuesHandler = {
    setValue: setValueHandler,
    getValue: getValueHandler,
  };

  return (
    <>
      <StoreContext.Provider value={valuesHandler}>
        {props.children}
      </StoreContext.Provider>
    </>
  );
};

export default StoreProvider;
