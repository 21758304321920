import style from "./MainSectionHeader.module.css";
interface properties {
  headerName: string;
  subMenu: any[];
}
function ManinSectionHeader({ headerName, subMenu }: properties) {
  return (
    <div className={style.header}>
      {headerName}
      <span>
        {subMenu.map((x: any, index) => {
          return (
            <span key={index}>
              {index > 0 ? ">" : ""}
              <p>&nbsp; {x} &nbsp;</p>
            </span>
          );
        })}
      </span>
    </div>
  );
}

export default ManinSectionHeader;
