import {
  CustomerProfile,
  ItemAvailable,
  Store,
} from "../../../../../../Interface/Purchase";
import query from "../../../../../data-centre/Queries";

export async function QueryDataHandler({
  rowSelected,
  ctx,
  searchBox,
  setSearchList,
  setModifiedSearchList,
}: any) {
  if (rowSelected[0] === -1) {
    await query.Billing.Search({
      param1: searchBox,
      userTypeIndex:
        (ctx.getUserProfile() as CustomerProfile)._role === "Customer"
          ? 0
          : (ctx.getUserProfile() as CustomerProfile)._role === "Bulk"
          ? 1
          : 2,
    })
      .then((response) => {
        if (response.statusText === "OK") {
          setSearchList(response.data);
          setModifiedSearchList([]);
          response.data.map((x: ItemAvailable) =>
            setModifiedSearchList(
              (y: any) =>
                (y = [
                  ...y,
                  {
                    value: x.Pricing_id,
                    name:
                      "{" +
                      x.Variant_name +
                      "}" +
                      "-" +
                      "{Availability: " +
                      x.Stock_available?.toFixed(3) +
                      x._metric +
                      "}" +
                      "-" +
                      "{NegativeStock: " +
                      x.IsNegative_stock +
                      "}",
                  },
                ])
            )
          );
        } else {
          console.log("no data found");
        }
      })
      .catch((error) => console.log(error));
  } else {
    console.log(rowSelected[3]);
    await query.Order.listItemDetail({ id: rowSelected[3] })
      .then((response) => {
        if (response.statusText === "OK") {
          setSearchList(response.data);
          setModifiedSearchList([]);
          response.data.map((x: Store) =>
            setModifiedSearchList(
              (y: any) =>
                (y = [
                  ...y,
                  {
                    value: x.Store_id,
                    name:
                      "{" +
                      x._Code +
                      "}" +
                      "-" +
                      "{Availability: " +
                      (x.Qty_remNet - (x.Passive_qty as number)).toFixed(3) +
                      x._metric +
                      "}",
                  },
                ])
            )
          );
        } else {
          console.log("no record found");
        }
      })
      .catch((error) => console.log());
  }
}
