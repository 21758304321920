import { useContext } from "react";
import StoreContext from "../../../../data-centre/Store-context";
import style from "./StockPopupChildren.module.css";

function StockPopupChildren({ values }: any) {
  const storeCtx = useContext(StoreContext);
  return (
    <div className={style["wrapper"]}>
      {values.map((x: any) => (
        <div key={x.name}>
          {x.name !== "heading" ? (
            <>
              <div>{x.name}</div>
              <div>{x.value}</div>
            </>
          ) : (
            <div>{x.value}</div>
          )}
        </div>
      ))}
      <div>
        <button
          onClick={() => {
            storeCtx.setValue(false, {});
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default StockPopupChildren;
