import style1 from "./CustomerDetail.module.css";
import style from "./ActionMenus.module.css";
import { useRef, useState, useContext } from "react";
import BillingContext from "../../../data-centre/Billing-context";
import { useReactToPrint } from "react-to-print";
import PrintingDocument from "./PrintDocument";
import query from "../../../data-centre/Queries";
import MessageModal from "../../common-utility/MessageModal";
import LoaderSpinnerKit from "../../common-utility/LoaderSpinnerKit";
import { BsCartX } from "react-icons/bs";
import ConfirmationModal from "../../common-utility/ConfirmationModal";
import Modal from "../../common-utility/Modal";
import {
  CustomerProfile,
  ItemAvailable,
  OrderDetail,
  OrderItemDetail,
  OrderItemSummary,
} from "../../../../Interface/Purchase";
import { isNumber } from "../../../../customer-app/utility/validation";
import Invoice from "./Invoice";
import valueCoversionHandler from "./PlaceOrder/valueConversionHandler";

const ActionMenus = (props: any) => {
  const [promptMessage, setPromptMessage] = useState<string>("");
  const [promptMessageVisibility, setPromptMessageVisibility] =
    useState<boolean>(false);
  const [confirmationMessageVisibility, setConfirmationMessageVisibility] =
    useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<string>("");
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
  const [selectedPaymentMode, setPaymentMode] = useState<number>(-1);
  const printingRef: any = useRef();
  const billingctx = useContext(BillingContext);

  const paymodes = [
    "Cash",
    "Debit Card",
    "Credit Card",
    "COD",
    "EMI",
    "Cheque",
  ];

  const handlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Invoice",
  });

  //**** SAVE ORDER HANDLER */
  const saveOrderHandler = async () => {
    setLoaderVisibility(true);
    await query.Order.add({
      orderDetailMapper: billingctx.getOrderDetail(),
      orderItemSummaryMapper: billingctx.getOrderItem(),
      roleIndex: billingctx.getRoleIndex(),
    })
      .then((response) => {
        setPromptMessageVisibility(true);
        if (response.statusText === "OK") {
          billingctx.setOrderDetail(response.data.Data1);
          let orderItem: OrderItemSummary[] = [...response.data.Data2];
          billingctx.setOrderItem(orderItem);
          billingctx.setOrderPointerEvents(true);
          let ItemAvailable: ItemAvailable[] = [
            ...billingctx.getBillingItems(),
          ];

          for (let item of ItemAvailable) {
            item.Pricing_id = orderItem.find(
              (x) =>
                x.ArchievePricing_id?.toString() === item.ArchievePricing_id
            )?.OrderItem_id as number;
          }

          billingctx.setBillinItems(ItemAvailable);
          billingctx.setInvoice((response.data.Data1 as OrderDetail).Order_id);
          setLoaderVisibility(false);
          setPromptMessage(response.data.Message);
        } else {
          setLoaderVisibility(false);
          setPromptMessage(response.data);
        }
      })
      .catch((error) => {
        setLoaderVisibility(false);
        setPromptMessageVisibility(true);
        setPromptMessage(error.response.data);
      });
  };
  //**** END*/

  const saveOrderDetailHandler = async () => {
    setLoaderVisibility(true);
    await query.Order.addItemDetail(billingctx.getOrderItemDetail())
      .then((response) => {
        setLoaderVisibility(false);
        setPromptMessageVisibility(true);
        if (response.statusText === "OK") {
          setPromptMessage("Order Item Details Saved Successfully!!!");
          billingctx.setInvoicePointerEvents(true);
          billingctx.setOrderPointerEvents(false);
        } else {
          setPromptMessage("None of Items Saved!!!");
        }
      })
      .catch((error) => {
        setLoaderVisibility(false);
        setPromptMessageVisibility(true);
        setPromptMessage("Error occurs while saving Order Item Details!!!");
      });
  };

  const newOrderHandler = () => {
    billingctx.setInvoice(undefined);
    if (billingctx.getInvoicePointerEvents()) {
      billingctx.setInvoicePointerEvents(false);
    }
    if(billingctx.getOrderPointerEvents())
    {
      billingctx.setOrderPointerEvents(false);
    }
    
    billingctx.setNewOrder(true);
  };

  const clearItemHandler = () => {
    billingctx.setClearItems(true);
  };

  const promptMessageHandler = (message: any) => {
    setPromptMessage(message);
    setPromptMessageVisibility(true);
  };

  const confirmationHandler = (type: string) => {
    let orderDetail: OrderDetail = billingctx.getOrderDetail();
    let orderItemDetail: OrderItemDetail[] = billingctx.getOrderItemDetail();
    let orderItemSummary: OrderItemSummary[] = billingctx.getOrderItem();
    let invoice = billingctx.getInvoice();
    let ItemAvailable: ItemAvailable[] = billingctx.getBillingItems();
    console.log("invoice value", invoice);
    switch (type) {
      case "new order":
        setPromptMessage("Create New Order?");
        setConfirmationMessageVisibility(true);
        setConfirmationType("new order");
        break;

      case "clear items":
        if (isNumber(orderDetail)) {
          promptMessageHandler(
            "Can't clear items of a saved order. Create New Order!!!"
          );
          return;
        }
        setConfirmationType("clear items");
        setPromptMessage("Sure to clear items list?");
        setConfirmationMessageVisibility(true);
        break;

      case "save order":
        console.log("orderitemdetail", orderItemDetail);
        if (billingctx.getInvoicePointerEvents()) {
          promptMessageHandler("Items store detail already completed!!!");
          return;
        } else if (orderItemSummary.length < 1) {
          promptMessageHandler("Please add some items !!!");
          return;
        } else if (
          isNumber(orderDetail.Order_id) &&
          orderItemDetail &&
          orderItemDetail.length === 0
        ) {
          promptMessageHandler("Order is already saved!!!");
          return;
        } else if (
          orderDetail.DeliveryRequest_date === undefined ||
          orderDetail.DeliveryRequest_date === null
        ) {
          setPromptMessage("Select Delivery request Date !!!");
          setPromptMessageVisibility(true);
          return;
        } else if (orderItemSummary.length > 0 && !invoice) {
          for (let item of orderItemSummary) {
            if (item.Sub_total === undefined || item.Sub_total === 0) {
              promptMessageHandler(
                "Some items in list are not having quantity and metrics !!!"
              );
              return;
            }
          }
        } else if (!orderItemDetail && invoice) {
          promptMessageHandler("Add Item store details for saved order!!!");
          return;
        } else if (orderItemDetail.length > 0 && invoice) {
          console.log(
            `itemsummary is ${orderItemSummary.length} and  orderitemdetail is ${orderItemDetail.length}`
          );

          if (orderItemDetail.length !== orderItemSummary.length) {
            promptMessageHandler("Add store item for all saved items!!!");
            return;
          }

          let i = 0;
          for (let item of orderItemDetail) {
            if (
              !(item.Qtys_taken as number[])[0] ||
              !(item.Qtys_metric as string[])[0]
            ) {
              promptMessageHandler("Add item store quantity and metrics!!!");
              return;
            }

            let sum = item.Qtys_taken?.reduce(
              (sum, x, inc) =>
                (sum =
                  sum +
                  valueCoversionHandler(
                    orderItemSummary[i].Qty_metric as string,
                    ItemAvailable[i].Metric_rules as string,
                    x,
                    (item.Qtys_metric as string[])[inc]
                  )),
              0
            );
            console.log(`sum of ${i} is ${sum} `);
            if (sum !== orderItemSummary[i].Qty_order) {
              promptMessageHandler(
                `Total Qty of Saved Item ${ItemAvailable[i].Variant_name} not equal to sum of Qty taken!!!`
              );
              return;
            }
            i++;
          }
        }
        setPromptMessage("Are you sure to save order?");
        setConfirmationMessageVisibility(true);
        setConfirmationType("save order");
        break;
      default:
        break;
    }
  };

  const resetProperties = () => {
    setConfirmationMessageVisibility(false);
    setConfirmationType("");
  };

  const confirmationResponseHandler = (type: string) => {
    switch (type) {
      case "new order":
        newOrderHandler();
        resetProperties();
        break;
      case "clear items":
        clearItemHandler();
        resetProperties();
        break;
      case "save order":
        !billingctx.getInvoice()
          ? saveOrderHandler()
          : saveOrderDetailHandler();
        resetProperties();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {promptMessageVisibility && (
        <MessageModal
          message={promptMessage}
          close={() => setPromptMessageVisibility(false)}
        />
      )}
      {confirmationMessageVisibility && (
        <Modal>
          <div>{promptMessage}</div>
          <div>
            <button
              type="button"
              onClick={() => {
                confirmationResponseHandler(confirmationType);
              }}
            >
              YES
            </button>
            <button
              onClick={() => setConfirmationMessageVisibility(false)}
              type="button"
            >
              NO
            </button>
          </div>
        </Modal>
      )}
      {loaderVisibility && (
        <LoaderSpinnerKit
          backColor="#686769"
          size={60}
          frontColor="#0dcaf0"
          loading={true}
        />
      )}
      {
        <div style={{ visibility: "hidden" }}>
          <PrintingDocument
            orderDetail={billingctx.getOrderDetail()}
            orderItemList={billingctx.getOrderItem()}
            userProfile={billingctx.getUserProfile()}
            billingItems={billingctx.getBillingItems()}
            gstRules={billingctx.getGstRules()}
            ref={printingRef}
          />
        </div>
      }
      <div className={style1["wrapper"] + " " + style["wrapper"]}>
        <div>
          <div onClick={() => confirmationHandler("clear items")}>
            Clear Items
          </div>
          <div onClick={() => confirmationHandler("new order")}>New Order</div>
        </div>
        <div>
          {paymodes.map((mode, index: any) => (
            <PaymentModes
              key={index}
              index={index}
              mode={mode}
              clickMode={selectedPaymentMode}
              paymentModeHandler={(x: any) => setPaymentMode((y) => x)}
            />
          ))}
        </div>
        <div>
          <div onClick={() => confirmationHandler("save order")}>
            Save Order
          </div>
          <div>Cancel Order</div>
          <div>Exchange Items </div>
          <div>Refund</div>
          <div onClick={handlePrint}>Print</div>
          <div>Order Payment</div>
        </div>
      </div>
    </>
  );
};

export default ActionMenus;

const PaymentModes = ({ index, mode, clickMode, paymentModeHandler }: any) => {
  return (
    <div
      style={{ backgroundColor: clickMode === index ? "#f05c5c" : "" }}
      onClick={() => paymentModeHandler(index)}
    >
      {mode}
    </div>
  );
};
