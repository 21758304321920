import { useContext, useEffect } from "react";
import { useState } from "react";
import style from "./Navigation.module.css";
import sahajjlogo from "../../assets/sahajj-name-logo.png";
import { CgProfile } from "react-icons/cg";
import LoginContext from "../../data-centre/Login-context";

function Navigation() {
  const [isProfilePopup, setProfilePopup] = useState<boolean>(false);

  const datetime = new Date().toLocaleString();

  const [showTime, setTime] = useState<any>(datetime);
  const loginCtx = useContext(LoginContext);

  const profileHandler = () => {
    if (isProfilePopup === false) {
      setProfilePopup(true);
    } else {
      setProfilePopup(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      return setTime(new Date().toLocaleString());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [showTime]);

  return (
    <>
      <section className={style["navigation-wrapper"]}>
        <div className={style["logo-div"]}>
          <img src={sahajjlogo} alt="sahajj image" />
        </div>
        <div className={style["userdetails-div"]}>
          <span>
            <strong>Welcome {loginCtx.getUser()===undefined?"Guest":loginCtx.getUser().name}</strong>
          </span>
          <div className={style["time-cell"]}>{showTime}</div>
        </div>

        <div className={style["user-pic-setting-wrapper"]}>
          <CgProfile
            onClick={() => {
              isProfilePopup === true
                ? setProfilePopup(false)
                : setProfilePopup(true);
            }}
            className={style["profile-box"]}
          />
        </div>
      </section>
      {isProfilePopup && (
        <ul className={style["profile-setting"]}>
          <li>Setting</li>
          <li>Logout</li>
        </ul>
      )}
    </>
  );
}

export default Navigation;
