import { cleanup } from "@testing-library/react";
import { useState, useEffect } from "react";
import Dropdown from "../../../common-utility/Dropdown";
import query from "../../../../data-centre/Queries";
import style from "./Add-Filter.module.css";
import AddProduct from "./AddProduct";
import { useContext } from "react";
import ProductContext from "../../../../data-centre/Product-context";
import MessageModal from "../../../common-utility/MessageModal";
import LoaderUI from "../../../common-utility/LoaderUI";
import SignSVG from "../../../common-utility/SignSVG";

const AddFilter = (props: any) => {
  const [catgList, setCatgList] = useState<any>([]);
  const [subCatgList, setSubCatgList] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [addComponentVisible, setAddComponentvisibility] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const ctx = useContext(ProductContext);

  useEffect(() => {
    getCatgListByName();
    return function cleanup() {
      setCatgList([]);
    };
  }, []);

  const getCatgListByName = async () => {
    await query.Category.listByName().then((response) =>
      setCatgList(response.data)
    );
  };

  const addHandler = (visibility: boolean) => {
    // console.log("catglist in filter :" + catgList);
    setAddComponentvisibility(visibility);
    if (visibility === false) {
      props.add();
    }
  };

  const catglistHandler = (value: any) => {
    if (value !== "") {
      query.SubCategory.detail(value, "catg").then((response) =>
        setSubCatgList(response.data)
      );
    } else if (value === "") {
      setSubCatgList([]);
      setProductList([]);
    }
  };

  const subCatgListHandler = (value: any) => {
    console.log("subcat value ", value);
    if (value !== "") {
      query.Product.droplist("subcategory", value).then((response) =>
        setProductList(response.data)
      );
    } else {
      setProductList([]);
    }
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    let sel1 = event.target["catgdropdownlist"].value;
    let sel2 = event.target["subcatgdropdownlist"].value;
    let sel3 = event.target["productdropdownlist"].value;
    sel2 = sel2 === "" ? "select" : sel2;
    sel3 = sel3 === "" ? "select" : sel3;
    console.log("sel2: " + sel2 + " sel3: " + sel3);
    getFilterData(sel1, sel2, sel3);
  };

  const getFilterData = async (sel1: any, sel2: any, sel3: any) => {
    setLoader(true);
    await query.Product.search(sel1, sel2, sel3).then((response: any) =>
      ctx.filterList(response.data)
    );
    setLoader(false);
  };

  return (
    <>
      <div className={style.maindiv}>
        <div className={style.buttondiv}>
          {addComponentVisible && (
            <AddProduct catgList={catgList} cancel={addHandler} />
          )}
          <button onClick={() => addHandler(true)}>Add Product</button>
        </div>
        <form onSubmit={submitHandler} className={style.innerdiv}>
          <div className={style.paddingDropdown}>
            <label className={style.height}>Category</label>
            {/* {console.log("catgeorydropdownlist", catgList)} */}
            <Dropdown
              onClick={catglistHandler}
              list={catgList}
              required={true}
              initialValue="0"
              name="catgdropdownlist"
              clicked={true}
              disable={false}
            />
          </div>
          <div className={style.paddingDropdown}>
            <label>Subcategory</label>
            <Dropdown
              onClick={subCatgListHandler}
              list={subCatgList}
              required={false}
              initialValue="0"
              name="subcatgdropdownlist"
              clicked={true}
              disable={false}
            />
          </div>
          <div className={style.paddingDropdown}>
            <label>Product</label>

            <Dropdown
              list={productList}
              required={false}
              initialValue="0"
              name="productdropdownlist"
              onClick={() => {}}
              disable={false}
            />
          </div>
          <div>
            <button className={style.submitDiv} type={"submit"}>
              <SignSVG sign="search" height="2rem" />
            </button>
          </div>
        </form>
      </div>
      {loader && (
        <LoaderUI content="Loading..." isDimmerActive={true} size="large" />
      )}
    </>
  );
};
export default AddFilter;
