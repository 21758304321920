import Footer from "./components/customer-page-common-section/Footer";
import Header from "./components/customer-page-common-section/Header";
import LeftAside from "./components/customer-page-common-section/LeftAside";
import RightAside from "./components/customer-page-common-section/RightAside";
import MenuIcons from "./components/item-menu/ItemMenuIcons";
import Product from "./components/products/Product";
import CartItemCountProvider from "./Data-Store/CartItemCount-Provider";
import style from "./CustomerActivity.module.css";
import { useState } from "react";

function CustomerActivity(props:any) {
  const [category, setCategory] = useState<string>("");

  const [rightSectionVisibility, setRightSectionVisibility] = useState(true);
  return (
    <>
      <CartItemCountProvider>
        <div id={style["header-div"]}>
          <Header />
        </div>
        <div id={style["left-aside-div"]}>
          <LeftAside />
        </div>
        <div id={style["content-div"]}>
          <MenuIcons
            onClick={(category: string) => {
              setRightSectionVisibility(false);
              setCategory(category);
            }}
          />
        </div>

        <div id={style["right-aside-div"]}>
          {rightSectionVisibility && <RightAside />}
          {category === "Fruits" || category === "Vegetables" ? (
            <Product category={category} />
          ) : (
            ""
          )}
        </div>

        <div id={style["footer-component-div"]}>
          <Footer />
        </div>
      </CartItemCountProvider>
    </>
  );
}

export default CustomerActivity;
