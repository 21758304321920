import { useEffect, useState } from "react";
import LiveStock_Filter from "./components/LiveStock_Filter";
import query from "../../../data-centre/Queries";
import { LiveStockMapperExtended } from "../../../../Interface/Purchase";
import MessageModal from "../../common-utility/MessageModal";
import LoaderSpinnerKit from "../../common-utility/LoaderSpinnerKit";
import LiveStock_List from "./components/LiveStock_List";
import style from "./LiveStock.module.css";

function LiveStock() {
  const [livestocklist, setLivestockList] = useState<LiveStockMapperExtended[]>(
    []
  );
  const [promptMessage, setPromptMessage] = useState<string>("");
  const [promptMessageVisibility, setPromptMessageVisibility] =
    useState<boolean>(false);
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);

  const header = [
    { name: "SNo", width: "2%" },
    { name: "Created At", width: "9%" },
    { name: "Product", width: "17%" },
    { name: "Unit MRP", width: "5%" },
    { name: "Conversion Rule", width: "9%" },
    { name: "PricingId", width: "5%" },
    { name: "InStock", width: "5%" },
    { name: "Reserved Qty", width: "7%" },
    { name: "Offer Id", width: "6%" },
    { name: "Arch. PricingId", width: "9%" },
    { name: "IsNegative", width: "7%" },
    { name: "User Type", width: "8%" },
    { name: "Status", width: "5%" },
    { name: "Edit", width: "2%" },
    { name: "Delete", width: "4%" },
  ];

  useEffect(() => {
    livestocklistHandler("Customer");
    return () => {
      setLivestockList([]);
    };
  }, []);

  const livestocklistHandler = async (userType: string) => {
    setLoaderVisibility(true);
    await query.Livestock.list({ userType: userType })
      .then((response) => {
        setLoaderVisibility(false);
        if (response.statusText === "OK") {
          // setLivestockList(response.data);
          filterHandler(userType, response.data);
        } else {
          setPromptMessage("NO DATA FOUND!!!");
          setPromptMessageVisibility(true);
        }
      })
      .catch((error) => {
        setLoaderVisibility(false);
        setPromptMessage(error);
        setPromptMessageVisibility(true);
      });
  };

  const promptMessageHandler = (message: string, visibility: boolean) => {
    setPromptMessage(message);
    setPromptMessageVisibility(visibility);
  };

  const filterHandler = (UserType: string, data: any) => {
    console.log(data.length, livestocklist.length);
    let arr = [...data];
    setLivestockList([...arr.filter((x) => x.UserType === UserType)]);
    arr = data = [];
  };

  return (
    <div className={style["livestock-main-wrapper"]}>
      {promptMessageVisibility && (
        <MessageModal
          message={promptMessage}
          close={() => setPromptMessageVisibility(false)}
        />
      )}
      {loaderVisibility && (
        <LoaderSpinnerKit
          backColor="#686769"
          size={60}
          frontColor="#0dcaf0"
          loading={true}
        />
      )}
      <LiveStock_Filter />
      <LiveStock_List
        list={livestocklist}
        header={header}
        setLoader={setLoaderVisibility}
        setPromptMessage={promptMessageHandler}        
        loadData={livestocklistHandler}
      />
    </div>
  );
}

export default LiveStock;
