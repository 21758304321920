import React from "react";
import { useReducer } from "react";
import ProductContext from "./Product-context";

let newstate;
let original = [] as any;
let filter = [] as any;
let initialstate = { original, filter };
const reducer = (state: any, action: any) => {
  // if (action.type === "set") {
  //   original = [...action.item];
  //   //action.item.map((data: any) => original.push(data));
  // } else if (action.type === "filter") {

  // }
  filter = [...action.item];
  return { original, filter };
};

const ProductProvider = (props: any) => {
  const [state, dispatcher] = useReducer(reducer, initialstate);

  const listHandler = (item: any) => {
    dispatcher({ type: "set", item: item });
  };

  const filterHandler = (item: any) => {
    dispatcher({ type: "filter", item: item });
  };

  const valuetranferred = {
    list: state,
    originalList: listHandler,
    filterList: filterHandler,
  };
  return (
    <>
      <ProductContext.Provider value={valuetranferred}>
        {props.children}
      </ProductContext.Provider>
    </>
  );
};

export default ProductProvider;
