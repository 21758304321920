import { useState, useEffect, useContext } from "react";
import style from "./SupplierListing.module.css";
import query from "../../../data-centre/Queries";
import StoreContext from "../../../data-centre/Store-context";
import { VscCircleLargeFilled } from "react-icons/vsc";
import DateTime from "../../common-utility/DateTime";
import { Supplier } from "../../../../Interface/Supplier";
import { MdDelete } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import LoaderUI from "../../common-utility/LoaderUI";

function SupplierListing({ e, setTableVisibility }: any) {
  const [message, setMessage] = useState<string>();
  const [isLoaderActive, setLoaderVisibility] = useState<boolean>(false);
  const [list, setList] = useState<any>([]);
  const [rowIndex, setRowIndex] = useState<number>(-1);

  useEffect(() => {
    const id = getProductDetailList(e);
  }, [e.Name, e.Contact, e.City]);

  const tableHeader = [
    { name: "SNO", width: "3%" },
    { name: "ID", width: "5%" },
    { name: "VENDOR NAME", width: "8%" },
    { name: "CONTACT1", width: "8%" },
    { name: "CONTACT2", width: "8%" },
    { name: "CREATED AT", width: "12%" },
    { name: "PAYMENT MODE", width: "4%" },
    { name: "ADDRESS1", width: "9%" },
    { name: "ADDRESS2", width: "9%" },
    { name: "CITY", width: "5%" },
    { name: "STATE", width: "5%" },
    { name: "COUNTRY", width: "8%" },
    { name: "PINCODE", width: "4%" },
    { name: "REMARK", width: "6%" },
    { name: "", width: "3%" },
    { name: "", width: "3%" },
  ];

  const getProductDetailList = async (obj: any) => {
    await query.Supplier.Search(obj).then((response) => {
      if (response.statusText === "OK") {
        // console.log(response.data);
        setList((x: any) => (x = response.data));
        response.data.length > 0
          ? setTableVisibility(true)
          : setTableVisibility(false);
      } else {
        setList([]);
        setTableVisibility(false);
      }
    });
  };

  const deleteHandler = async (id: number) => {
    setLoaderVisibility(true);
    await query.Supplier.remove(id)
      .then((response) => {
        if (response.statusText === "OK") {
          getProductDetailList(e);
          console.log("remove successfully" + response.status);
        } else {
          console.log(response.status);
        }
        setLoaderVisibility(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {isLoaderActive && (
        <LoaderUI
          content={"Removing ..."}
          isDimmerActive={true}
          size="medium"
          key=""
        />
      )}
      <form className={style["main-div-stocklist"]}>
        <div className={style.tableHeader}>
          {list.length > 0
            ? tableHeader.map((x: any, index: number) => (
                <div
                  key={index}
                  style={{
                    width: x.width,
                    backgroundColor:
                      index === 14 || index === 15 ? "white" : "",
                  }}
                >
                  {x.name}
                </div>
              ))
            : null}
        </div>
        {list.length > 0
          ? list.map((x: Supplier, index: number) => (
              <TableData
                key={++index}
                tableHeader={tableHeader}
                color={rowIndex > -1 && rowIndex === index ? "#FFF6C3" : ""}
                index={++index}
                str={x}
                setRowIndex={setRowIndex}
                deleteHandler={deleteHandler}
              />
            ))
          : null}
      </form>
    </>
  );
}
export default SupplierListing;

const TableData = ({
  tableHeader,
  index,
  setRowIndex,
  color,
  str,
  deleteHandler,
}: any) => {
  //const storeCtx = useContext(StoreContext);
  // const handler = (x: any[]) => {
  //   storeCtx.setValue(true, x);
  // };
  let x: Supplier = str;
  var datetime = new Date(x.Created_at);
  const timeString12hr = DateTime({ value: datetime, required: "time" });
  const dateString = DateTime({ value: datetime, required: "date" }).split("/");
  const formattedDate =
    dateString[1] + "-" + dateString[0] + "-" + dateString[2];

  return (
    <div
      className={style.tableData}
      key={index}
      style={{ backgroundColor: color }}
      onClick={() => setRowIndex(--index)}
    >
      <div style={{ width: tableHeader[0].width }}>{index}</div>
      <div style={{ width: tableHeader[1].width, textAlign: "left" }}>
        {x.Id}
      </div>
      <div style={{ width: tableHeader[2].width }}>{x._name}</div>
      <div style={{ width: tableHeader[3].width }}>{x._contact1}</div>
      <div style={{ width: tableHeader[4].width }}>{x._contact2}</div>
      <div style={{ width: tableHeader[5].width }}>
        {formattedDate + " " + timeString12hr}
      </div>
      <div style={{ width: tableHeader[6].width }}>{x.Payment_mode}</div>
      <div style={{ width: tableHeader[7].width }}>{x._address1}</div>
      <div style={{ width: tableHeader[8].width }}>{x._address2}</div>
      <div style={{ width: tableHeader[9].width }}>{x._city}</div>
      <div style={{ width: tableHeader[10].width }}>{x._state}</div>
      <div style={{ width: tableHeader[11].width }}>{x._country}</div>
      <div style={{ width: tableHeader[12].width }}>{x._pincode}</div>
      <div style={{ width: tableHeader[13].width }}>{x._remark}</div>
      <div style={{ width: tableHeader[14].width }}>
        <MdOutlineModeEditOutline
          onClick={() => {
            console.log(x._name);
          }}
          className={style.editicon}
        >
          Edit
        </MdOutlineModeEditOutline>
      </div>
      <div style={{ width: tableHeader[15].width }}>
        <MdDelete
          onClick={() => deleteHandler(x.Id)}
          className={style.deleteicon}
        />
      </div>
    </div>
  );
};
