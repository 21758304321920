import style from "./Error.module.css";

function Errror(props: any) {
  const { message } = props;
  console.log("message: " + message);

  return (
    <>
      <div className={style.error}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="upwardArrow"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 15l7-7 7 7"
          />
        </svg>
        <div>{message}</div>
      </div>
    </>
  );
}

export default Errror;
