import style from "./MessageModal.module.css";

function Backdrop() {
  return (
    <>
      <div className={style.backdrop}></div>
    </>
  );
}

function OverLay({ message, close }: any) {
  return (
    <>
      <div>
        <div className={style.modal}>
          <div className={style.content}>{message}</div>
          <div>
            <button onClick={close}>Close</button>
          </div>
        </div>
      </div>
    </>
  );
}
interface type {
  message: any;
  close: any;
}
function MessageModal(props: type) {
  return (
    <>
      <Backdrop />
      <OverLay {...props} />
    </>
  );
}
export default MessageModal;
