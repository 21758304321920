import { useState } from "react";
import { LiveStockMapperExtended } from "../../../../../Interface/Purchase";
import { isDecimal } from "../../../../../customer-app/utility/validation";
import DateTime from "../../../common-utility/DateTime";
import style from "./LiveStock_List.module.css";
import { MdOutlineModeEditOutline, MdDelete } from "react-icons/md";
import Edit from "./Edit";
import ConfirmationModal from "../../../common-utility/ConfirmationModal";


interface type {
  list: LiveStockMapperExtended[];
  header: { name: string; width: string }[];
  setLoader: any;
  setPromptMessage: any;
  loadData: (userType: string) => Promise<void>;
}

function LiveStock_List({
  list,
  header,
  setLoader,
  setPromptMessage,
  loadData,
}: type) {
  const [rowData, setRowData] = useState<LiveStockMapperExtended | undefined>(
    undefined
  );
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [confirmationMessageVisibility, setConfirmationMessageVisibility] =
    useState<boolean>(false);
  const [confirmationResponse, setConfirmationResponse] = useState<any>();

  const deleteHandler = (pricingid: number) => {
    setConfirmationResponse("");
  };

  const confirmationMessageHandler = (type: string, data: any) => {
    switch (type) {
      case "delete":
        setConfirmationMessage(
          "Are You Sure to Delete the PricingId " + data + " ?"
        );
        setConfirmationMessageVisibility(true);
        setConfirmationResponse(data);
        break;
    }
  };

  const confirmationResponseHandler = (type: string, data: any) => {
    setConfirmationMessageVisibility(false);
    setConfirmationMessage("");
    switch (type) {
      case "delete":
        deleteHandler(data);
        break;
    }
  };

  const [tab, setTab] = useState<string>("Customer");
  const tabHandler = (type: string) => {
    setTab(type);
    loadData(type);
  };

  return (
    <>
      {confirmationMessageVisibility && (
        <ConfirmationModal
          onClose={() => setConfirmationMessageVisibility(false)}
          Ok={() => confirmationResponseHandler("delete", confirmationResponse)}
        >
          {confirmationMessage}
        </ConfirmationModal>
      )}
      {rowData && (
        <Edit
          row={rowData}
          onClose={setRowData}
          setLoader={setLoader}
          setPromptMessage={setPromptMessage}
          loadData={loadData}
        />
      )}
      <div className={style["livestock-table-wrapper"]}>
        <div className={style.tab}>
          <div
            onClick={() => tabHandler("Customer")}
            style={{
              backgroundColor: tab === "Customer" ? "#9b59b6" : "",
              color: tab === "Customer" ? "white" : "black",
            }}
          >
            Customer
          </div>
          <div
            onClick={() => tabHandler("Bulk")}
            style={{
              backgroundColor: tab === "Bulk" ? "#9b59b6" : "",
              color: tab === "Bulk" ? "white" : "black",
            }}
          >
            Bulk
          </div>
        </div>
        <div className={style.tablehead}>
          {header.map((head) => (
            <span key={head.name} style={{ width: head.width }}>
              {head.name}
            </span>
          ))}
        </div>
        <div className={style.rowswrapper}>
          {list.map((item, index: number) => (
            <div className={style.tablerow} key={item.ArchievePricing_id_I}>
              <span style={{ width: header[0].width }}>{index + 1}</span>
              <span style={{ width: header[1].width }}>
                {DateTime({ value: item.Created_time, required: "date" })}
              </span>
              <span style={{ width: header[2].width }}>
                {item.Variant_name}
              </span>
              <span style={{ width: header[3].width }}>{item.Unit_mrp}</span>
              <span style={{ width: header[4].width }}>
                {item.Metric_conversion}
              </span>
              <span style={{ width: header[5].width }}>{item.Pricing_id}</span>
              <span style={{ width: header[6].width }}>
                {item.Stock_available + " " + item._metric}
              </span>
              <span style={{ width: header[7].width }}>
                {(isDecimal(item.Reserverd_stock)
                  ? item.Reserverd_stock.toFixed(3)
                  : item.Reserverd_stock) +
                  " " +
                  item._metric}
              </span>
              <span style={{ width: header[8].width }}>{item.Offer_id}</span>
              <span style={{ width: header[9].width }}>
                {item.ArchievePricing_id_I}
              </span>
              <span
                style={{
                  width: header[10].width,
                  fontWeight:
                    item.IsNegative_stock.toString() === "false" ? 600 : 500,
                }}
              >
                {item.IsNegative_stock.toString()}
              </span>
              <span style={{ width: header[11].width }}>{item.UserType}</span>
              <span
                style={{
                  width: header[12].width,
                  color: item.LiveStock_status === "Live" ? "green" : "",
                }}
              >
                {item.LiveStock_status}
              </span>
              <span
                className={style.editspan}
                style={{
                  width: header[13].width,
                }}
              >
                <MdOutlineModeEditOutline
                  className={style.editicon}
                  onClick={() => setRowData(item)}
                />
              </span>
              <span
                className={style.deletespan}
                style={{
                  width: header[14].width,
                }}
              >
                <MdDelete
                  onClick={() =>
                    confirmationMessageHandler("delete", item.Pricing_id)
                  }
                  className={style.deleteicon}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default LiveStock_List;
