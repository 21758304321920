import { useState, useEffect } from "react";
import style from "./SetItemPrice.module.css";
import query from "../../../data-centre/Queries";
import Modal from "../../common-utility/Modal";
import { Pricing } from "../../../../Interface/Purchase";
import MessageModal from "../../common-utility/MessageModal";
import TableData from "./SetItemPriceComponents/TableData";
import LoaderSpinnerKit from "../../common-utility/LoaderSpinnerKit";

function SetItemPrice(props: any, { setTableVisibility }: any) {
  const [message, setMessage] = useState<string>();
  const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(false);
  const [list, setList] = useState<any>([]);
  const [rowId, setRowId] = useState<any>("");
  const [clickCount, setClickCount] = useState<number>(0);
  const [isMessageModalVisible, setMessageModalVisibility] =
    useState<boolean>(false);
  const [isMessageVisible, setMessageVisibility] = useState<boolean>(false);
  const [listToUpdate, setLisToUpdate] = useState<any>([]);
  const [hover, setHover] = useState<string>("");
  const [checkboxState, setCheckboxState] = useState<boolean>(false);
  const [exclusionCheckboxState, setExclusionCheckboxState] =
    useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [typeSate, setTypeSate] = useState<string>("");

  const getListHandler = async (type1: string) => {
    setLoaderVisibility(true);
    await query.Pricing.list({ type: type1 })
      .then((response) => {
        if (response.statusText === "OK") {
          setList((x: any) => (x = response.data));
        }
      })
      .catch((error) => console.log(error));
    setLoaderVisibility(false);
  };

  useEffect(() => {
    getListHandler("Customer");
    setTypeSate("Customer");
  }, []);

  const tableHeader = [
    { name: "SNO", width: "2%" },
    { name: "PRICING ID", width: "4%" },
    { name: "PRODUCT MAME", width: "7%" },
    { name: "VARIANT NAME", width: "7.8%" },
    { name: "LAST UPDATE", width: "6.5%" },
    { name: "UNIT MRP", width: "4.5%" },
    {
      name: (
        <>
          <div
            style={{ height: "30%", borderLeft: "none", borderRight: "none" }}
          >
            LIVE RATES
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "70%",
              padding: "0%",
              border: "none",
            }}
          >
            <div style={{ width: "30%", height: "100%", borderLeft: "none" }}>
              AVG. CP
            </div>
            <div style={{ width: "40%", height: "100%" }}>PROFIT MARG.(%)</div>
            <div style={{ width: "30%", height: "100%" }}>AVG. SP</div>
          </div>
        </>
      ),
      // width: "12%",
      width: "18.7%",
    },

    {
      name: (
        <>
          <div
            style={{ height: "30%", borderLeft: "none", borderRight: "none" }}
          >
            EXPECTED RATES
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "70%",
              padding: "0%",
              border: "none",
            }}
          >
            <div style={{ width: "30%", height: "100%", borderLeft: "none" }}>
              AVG. CP
            </div>
            <div style={{ width: "40%", height: "100%" }}>PROFIT MARG.(%)</div>
            <div style={{ width: "30%", height: "100%" }}>AVG. SP</div>
          </div>
        </>
      ),
      // width: "12%",
      width: "19%",
    },
    { name: "NEWLY QTY. ADD", width: "4%" },
    { name: "METRIC RULES", width: "6%" },
    { name: "SEARCH MODE", width: "5.5%" },
    { name: "RATES STATUS", width: "4.5%" },
    {
      name: (
        <>
          PRICE EXCLUSION
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            name="maincheckbox"
            onChange={() => setExclusionCheckboxState((x) => !x)}
          />
        </>
      ),
      width: "6%",
    },
    {
      name: (
        <>
          <button
            className={style.update}
            style={{
              backgroundColor: hover,
              boxShadow: "1px 1px 1px lightgray",
              cursor: "pointer",
              fontSize: "1em",
            }}
            onMouseOver={() => {
              setHover("lightgray");
            }}
            onMouseOut={() => {
              setHover("darkgray");
            }}
            type="submit"
            name="update"
          >
            Update
          </button>
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            name="maincheckbox"
            onChange={() => setCheckboxState((x) => !x)}
          />
        </>
      ),
      width: "4.5%",
    },
  ];

  const getProductDetailList = async (e: any) => {
    let catgId = e.target["catgList"].value;
    let subCatgId = e.target["subCatgList"].value;
    let ProductName = e.target["productList"].value;
    let Choice = e.target["choice"].value;
    if (catgId === "") {
      catgId = "select";
    }
    if (subCatgId === "") {
      subCatgId = "select";
    }
    if (ProductName === "") {
      ProductName = "select";
    }
    if (Choice === "") {
      Choice = 0;
    }

    catgId === "select" && subCatgId === "select" && ProductName === "select"
      ? await query.Pricing.list("")
          .then((response) => {
            if (response.statusText === "OK") {
              setList((x: any) => (x = response.data));
              response.data.length > 0
                ? setTableVisibility(true)
                : setTableVisibility(false);
            } else {
              setList([]);
              setTableVisibility(false);
              setMessage("error in fetching detailproduct list");
            }
          })
          .catch((error) => setMessage(error))
      : await query.Store.search(catgId, subCatgId, ProductName, Choice).then(
          (response) => {
            if (response.statusText === "OK") {
              setList((x: any) => (x = response.data));
              response.data.length > 0
                ? setTableVisibility(true)
                : setTableVisibility(false);
            } else {
              setList([]);
              // setUncheckedSate(true);
            }
          }
        );
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    if (listToUpdate.length < 1) {
      setMessage("Please Select Item For Price Setup!!!");
      setMessageVisibility(true);
    } else {
      setMessageModalVisibility(true);
    }
  };

  const okHandler = async () => {
    setMessageModalVisibility(false);
    setLoaderVisibility(true);
    listToUpdate.map((x: any) => {
      for (let val in x) {
        console.log(`${val}:${x[val]}`);
      }
    });
    await query.Pricing.edit(listToUpdate)
      .then((response) => {
        if (response.statusText === "OK") {
          getListHandler(typeSate);
          setMessage("Updated Successfully!!!");
          setMessageVisibility(true);
          setLisToUpdate([]);
          setReset((x) => !x);
          // setCheckboxState(false);
          // setExclusionCheckboxState(false);
          setLoaderVisibility(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage("Some Error Occurred");
        setMessageVisibility(true);
        setLoaderVisibility(false);
      });
  };

  return (
    <>
      {isLoaderVisible && (
        <LoaderSpinnerKit
          backColor="#686769"
          size={60}
          // frontColor="#00ff89"
          frontColor="#0dcaf0"
          loading={true}
        />
      )}

      {isMessageModalVisible && (
        <Modal>
          <div>Are You Sure To Live Price?</div>
          <div>
            <button onClick={okHandler} className={style.modalbutton}>
              Ok
            </button>
            <button
              className={style.modalbutton}
              onClick={() => {
                setReset((x) => !x);
                setExclusionCheckboxState(false);
                setMessageModalVisibility(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
      {isMessageVisible && (
        <MessageModal
          close={() => {
            setMessageVisibility(false);
          }}
          message={<h3>{message}</h3>}
        />
      )}
      <div className={style.tab}>
        <button
          className={
            style.normalbuttonstyle +
            " " +
            (typeSate == "Customer" ? style.buttonclickstyle : "")
          }
          onClick={() => {
            getListHandler("Customer");
            setTypeSate("Customer");
          }}
        >
          CUSTOMER
        </button>
        <button
          className={
            style.normalbuttonstyle +
            " " +
            (typeSate == "Bulk" ? style.buttonclickstyle : "")
          }
          onClick={() => {
            getListHandler("Bulk");
            setTypeSate("Bulk");
          }}
        >
          BULK
        </button>
      </div>
      <form onSubmit={submitHandler} className={style["main-div-stocklist"]}>
        <div className={style.tableHeader}>
          {list.length > 0
            ? tableHeader.map((x: any, index: number) => (
                <div
                  key={index}
                  style={{ width: x.width, paddingBottom: "0%" }}
                >
                  {x.name}
                </div>
              ))
            : null}
        </div>
        {list.length > 0
          ? list.map((x: Pricing, index: number) => (
              <TableData
                key={x.Pricing_id}
                setMessageVisibility={(x: any) => {
                  setMessage("Please Uncheck Header CheckBox!!!");
                  setMessageVisibility(x);
                }}
                tableHeader={tableHeader}
                color={
                  clickCount === 0 && rowId === x.ArchieveStore_id
                    ? "#FFF6C3"
                    : clickCount % 2 === 0 &&
                      clickCount > 0 &&
                      rowId === x.ArchieveStore_id
                    ? "#FFF6C3"
                    : ""
                }
                exclusionCheckboxState={exclusionCheckboxState}
                reset={reset}
                isChecked={checkboxState}
                accordian={
                  clickCount === 0 && rowId === x.Pricing_id
                    ? true
                    : clickCount % 2 === 0 &&
                      clickCount > 0 &&
                      rowId === x.Pricing_id
                    ? true
                    : false
                }
                id={x.Pricing_id}
                index={++index}
                str={x}
                setRowId={(id: any) => {
                  if (rowId === id) {
                    setClickCount((x) => (x = x + 1));
                  } else {
                    setClickCount(0);
                  }
                  setRowId(id);
                }}
                clickIds={(pid: any, obj: {}) => {
                  if (pid >= 0) {
                    setLisToUpdate((x: any) => (x = [...x, obj]));
                  } else {
                    let filterList = listToUpdate.filter(
                      (x: any) => x.Pricing_id !== pid * -1
                    );
                    setLisToUpdate((x: any) => (x = [...filterList]));
                  }
                }}
              />
            ))
          : null}
      </form>
    </>
  );
}
export default SetItemPrice;
