import { useState } from "react";
import BillingContext from "./Billing-context";
import {
  ItemAvailable,
  CustomerProfile,
  OrderDetail,
  OrderItemSummary,
  GstRules,
  OrderItemDetail,
} from "../../Interface/Purchase";
import query from "./Queries";

const BillingProvider = (props: any) => {
  const [orderDetail, setOrderDetail] = useState<OrderDetail>();
  const [orderItem, setOrderItem] = useState<OrderItemSummary[]>();
  const [orderItemDetail, setOrderItemDetail] = useState<OrderItemDetail[]>();
  const [userProfile, setUserProfile] = useState<CustomerProfile>();
  const [billingItems, setBillingItems] = useState<ItemAvailable[]>();
  const [clearItems, setClearItems] = useState<boolean>(false);
  const [newOrder, setNewOrder] = useState<boolean>(false);
  const [invoiceNo, setInvoiceNo] = useState<number | undefined>();
  const [invoiceGenerateType, setInvoiceGenerateType] = useState<
    string | undefined
  >();
  const [orderPointerEvents, setOrderPointerEvents] = useState<boolean>(false);
  const [invoicePointerEvents, setInvoicePointerEvents] =
    useState<boolean>(false);
  const [roleIndex, setRoleIndex] = useState<number>(-1);
  const [gstRules, setGstRules] = useState<any>([]);

  const getOrderDetailHandler = () => {
    return orderDetail;
  };

  const setOrderDetailHandler = (obj: OrderDetail) => {
    setOrderDetail(obj);
  };

  const getOrderItemHandler = () => {
    return orderItem;
  };

  const setOrderItemHandler = (obj: OrderItemSummary[]) => {
    setOrderItem(obj);
  };

  const getUserProfileHandler = () => {
    return userProfile;
  };

  const setUserProfileHandler = (obj: CustomerProfile) => {
    setUserProfile(obj);
  };

  const getBillingItemsHandler = () => {
    return billingItems;
  };

  const setBillingItemsHandler = (obj: ItemAvailable[]) => {
    setBillingItems(obj);
  };

  const getNewOrderHandler = () => {
    return newOrder;
  };

  const setNewOrderHandler = (isNewOrder: boolean) => {
    setNewOrder(isNewOrder);
  };

  const getClearItemsHndler = () => {
    return clearItems;
  };

  const setClearItemsHandler = (isClearItems: boolean) => {
    setClearItems(isClearItems);
  };

  const getOrderPointerEventsHandler = () => {
    return orderPointerEvents;
  };

  const setOrderPointerEventsHandler = (event: boolean) => {
    setOrderPointerEvents(event);
  };

  const getInvoicePointerEventsHandler = () => {
    return invoicePointerEvents;
  };

  const setInvoicePointerEventsHandler = (event: boolean) => {
    setInvoicePointerEvents(event);
  };

  const getRoleIndexHandler = () => {
    return roleIndex;
  };

  const setRoleIndexHandler = (users: CustomerProfile) => {
    let index =
      users._role === "Customer" ? 0 : users._role === "Bulk" ? 1 : -1;
    console.log(`index: ${index}`);
    setRoleIndex(index);
  };

  const getGstRulesHandler = () => {
    return gstRules;
  };

  const setGstRulesHandler = async () => {
    await query.GstRules.list()
      .then((response) => {
        if (response.statusText === "OK") {
          setGstRules(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        setGstRules([]);
      });
  };

  const getInvoiceHandler = () => {
    return invoiceNo;
  };

  const setInvoiceHandler = (no: number | undefined) => {
    setInvoiceNo(no);
  };

  const getInvoiceGenerateTypeHandler = () => {
    return invoiceGenerateType;
  };

  const setInvoiceGenerateTypeHandler = (generateType: string | undefined) => {
    setInvoiceGenerateType(generateType);
  };

  const getOrderItemDetailHandler = () => {
    return orderItemDetail;
  };

  const setOrderItemDetailHandler = (orderItemDetail: OrderItemDetail[]) => {
    setOrderItemDetail(orderItemDetail);
  };

  const valuetranferred = {
    setOrderDetail: setOrderDetailHandler,
    getOrderDetail: getOrderDetailHandler,
    setOrderItem: setOrderItemHandler,
    getOrderItem: getOrderItemHandler,
    setUserProfile: setUserProfileHandler,
    getUserProfile: getUserProfileHandler,
    setBillinItems: setBillingItemsHandler,
    getBillingItems: getBillingItemsHandler,
    setNewOrder: setNewOrderHandler,
    getNewOrder: getNewOrderHandler,
    setClearItems: setClearItemsHandler,
    getClearItems: getClearItemsHndler,
    setOrderPointerEvents: setOrderPointerEvents,
    getOrderPointerEvents: getOrderPointerEventsHandler,
    setInvoicePointerEvents: setInvoicePointerEvents,
    getInvoicePointerEvents: getInvoicePointerEventsHandler,
    setRoleIndex: setRoleIndexHandler,
    getRoleIndex: getRoleIndexHandler,
    setGstRules: setGstRulesHandler,
    getGstRules: getGstRulesHandler,
    getInvoice: getInvoiceHandler,
    setInvoice: setInvoiceHandler,
    setOrderItemDetail: setOrderItemDetailHandler,
    getOrderItemDetail: getOrderItemDetailHandler,
    getInvoiceGenerateType: getInvoiceGenerateTypeHandler,
    setInvoiceGenerateType: setInvoiceGenerateTypeHandler,
  };
  return (
    <BillingContext.Provider value={valuetranferred}>
      {props.children}
    </BillingContext.Provider>
  );
};

export default BillingProvider;
