import { memo, useEffect, useState } from "react";
import style from "./ManageCategory.module.css";
import axios from "axios";
import React from "react";
import AddCategory from "../components/AddCategory";
import LoaderUI from "../../common-utility/LoaderUI";
import query from "../../../data-centre/Queries";

function ManageCategory() {
  const [productCategory, setProductCategory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  //console.log("initial array ", productCategory);

  useEffect(() => {
    getData();
    return function cleanup() {
      console.log("empty");
      setProductCategory([]);
    };
  }, []);

  async function getData() {
    setLoading(true);
    console.log("url: ", process.env.REACT_APP_API_URL + "ProductCategory");
    await query.Category.list().then((response) => {
      console.log(response);
      setProductCategory(response.data);
    });
    setLoading(false);
  }

  return (
    <>
      {console.log("count1")}
      <div>MANAGE CATEGORY</div>
      <AddCategory />
      {!isLoading && (
        <table className={style["category-table"]}>
          <thead>
            <tr className={style["thead-row"]}>
              <th className={style["thead-Id"]}>Id</th>
              <th className={style["thead-Id"]}>Priority</th>
              <th className={style["thead-name"]}>Category Name</th>
              <th className={style["thead-nameHindi"]}>Category Name(Hindi)</th>
              <th className={style["thead-Image"]}>Image</th>
              <th className={style["thead-edit"]}></th>
              <th className={style["thead-delete"]}></th>
            </tr>
          </thead>
          <tbody>
            {productCategory.map((rows: any) => (
              <tr className={style["tbody-row"]} key={rows.Id}>
                <td> {rows.Id}</td>
                <td> {rows.Priority}</td>
                <td>{rows.Name_English}</td>
                <td>{rows.Name_Hindi}</td>
                <td>{rows.Image}</td>
                <td className={style.edit}>
                  <button>Edit</button>
                </td>
                <td className={style.delete}>
                  <button>Delete</button>
                </td>
                {console.log("coun2")}
              </tr>
            ))}
            {console.log("coun3")}
          </tbody>
        </table>
      )}
      {isLoading && (
        <LoaderUI content="Loading..." isDimmerActive={true} size="large" />
      )}
    </>
  );
}

export default memo(ManageCategory);
