import { useState, useEffect, useContext } from "react";
import BillingContext from "../../data-centre/Billing-context";

import BillCreation from "./BillCreation";
import style from "./Billing.module.css";

function Billing(props: any) {
  const subMenu: number = props.menuType;
  const [isMenuVisible, setMenu] = useState<boolean>(false);

  // let val: any = billingctx.getOrderDetail();
  // console.log(val);
  // useEffect(() => {
  //   if (val === true) {
  //     setMenu(true);
  //   }
  // }, [val]);

  useEffect(() => {
    if (subMenu === 1) {
      setMenu(true);
    } else if (subMenu === 2) {
      setMenu(false);
    }
  }, [subMenu]);

  return (
    <>
      <div className={style["wrapper"]}>
        {isMenuVisible && <BillCreation />}
      </div>
    </>
  );
}

export default Billing;
