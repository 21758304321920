import { useEffect, useState } from "react";
import ProductProvider from "../../data-centre/ProductProvider";
import ManageProduct from "./components/ManageProduct";
import style from "./Product.module.css";

function Product(props: any) {
  const subMenu: number = props.menuType;
  const [isMenuVisible, setMenu] = useState<boolean>(false);

  useEffect(() => {
    if (subMenu === 1) {
      setMenu(true);
    }
  }, [subMenu]);

  return (
    <>
      <div className={style.container}>
        <ProductProvider>{isMenuVisible && <ManageProduct />}</ProductProvider>
      </div>
    </>
  );
}

export default Product;
