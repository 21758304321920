import { createContext } from "react";
import { userDetail, userType } from "../../Interface/Purchase";

let user: userDetail;
let isExist: boolean;

const context = {
  getUser: () => user,
  checkUser: (user: userType) => isExist,
};

const LoginContext = createContext(context);
export default LoginContext;
