import { useState, useEffect } from "react";
import ReturnExchangeOrder from "./order-actions/ReturnExchangeOrder";
import TrackOrder from "./order-actions/TrackOrder";
import OrdersDetail from "./order-actions/OrdersDetail";

function Order(props: any) {
  const subMenu: number = props.menuType;
  const [isLeft, setLeft] = useState<boolean>(false);
  const [isRight, setRight] = useState<boolean>(false);

  useEffect(() => {
    if (subMenu === 1) {
      setLeft(true);
      setRight(true);
    } else if (subMenu === 2) {
      setLeft(true);
      setRight(false);
    } else if (subMenu === 3) {
      setLeft(false);
      setRight(true);
    }
  }, [subMenu]);

  return (
    <>
      {isLeft && isRight && <OrdersDetail />}
      {isLeft && !isRight && <ReturnExchangeOrder />}
      {!isLeft && isRight && <TrackOrder />}
    </>
  );
}

export default Order;
