import LabelInput from "../../../../common-elements/LabelInput";
import ManinSectionHeader from "../../../../components/management/common-components/MainSectionHeader";
import style from "./Registration.module.css";
import citiesList from "../../../../json-files/Rajasthancities";

import { useState, useRef } from "react";

function Registration(props: any) {
  const inputRef: any = useRef();
  const listRef: any = useRef([]);
  const [city, setCity] = useState<any[]>([]);
  const [isKeyPressed, setkeyPressed] = useState<boolean>(false);
  const [cityListVisibility, setCityListVisibility] = useState("");
  const [cityListIndex, setCityIndex] = useState<number>(-1);
  const [required, setRequired] = useState<boolean>(true);

  const inputArray = [
    {
      labelName: "First Name",
      type: "text",
      required: required,
      name: "fname",
      styles: { color: "#777" },
    },
    {
      labelName: "Last Name",
      type: "text",
      required: false,
      name: "lname",
      styles: { color: "#777" },
    },
    {
      labelName: "Email",
      type: "email",
      required: false,
      name: "email",
      styles: { color: "#777" },
    },
    {
      labelName: "Mobile",
      type: "number",
      required: required,
      name: "mobile",
      styles: { color: "#777" },
    },
    {
      labelName: "City",
      type: "text",
      required: true,
      name: "city",
      styles: { color: "#777" },
      ref: inputRef,
    },
    {
      labelName: "Password",
      type: "password",
      required: required,
      name: "pwd",
      styles: { color: "#777" },
    },
  ];

  const arrowUpHandler = () => {
    if (city.length > 0) {
      if (cityListIndex > 0) {
        setCityIndex((x) => x - 1);
      } else if (cityListIndex === -1 || cityListIndex === 0) {
        setCityIndex((x) => (x = city.length - 1));
      } else {
        return;
      }
      cityIndex("up");
    }
  };

  const arrowDownHandler = () => {
    if (city.length > 0) {
      if (cityListIndex < city.length - 1) {
        setCityIndex((x) => x + 1);
      } else if (cityListIndex === city.length - 1) {
        setCityIndex((x) => (x = 0));
      } else {
        return;
      }
      cityIndex("down");
    }
  };

  const cityIndex = (key: string) => {
    let ct = cityListIndex;
    if (key === "down") {
      if (cityListIndex === city.length - 1) {
        ct = 0;
      } else {
        ct = ct + 1;
      }
    } else if (key === "up") {
      if (cityListIndex === -1 || cityListIndex === 0) ct = city.length - 1;
      if (cityListIndex > 0) ct = ct - 1;
    } else return;
    setCity((item) =>
      item.map((x) =>
        x.sno === ct ? { ...x, color: "lightgray" } : { ...x, color: "" }
      )
    );
    listRef.current[ct].scrollIntoView({ behavior: "smooth" });
  };

  const onChangeHandler = (e: any) => {
    e.target.setCustomValidity("");
    setCityIndex(-1);
    setCityListVisibility("flex");
    let count = inputRef.current.value.length;
    setCity([]);
    let filterArray = [] as any;
    if (count >= 2) {
      filterArray = citiesList.filter(
        (x) =>
          // x.name.toLowerCase().includes(inputRef.current.value.toLowerCase()) ?(x.state.toLowerCase().includes("rajasthan")?true:false):false)
          x.name.toLowerCase().includes(inputRef.current.value.toLowerCase()) &&
          x.state.toLowerCase().includes("rajasthan")
      );
    }

    filterArray
      .sort((x: any, y: any) => x.name.localeCompare(y.name))
      .map((x: any, index: any) => {
        setCity((p) => [
          ...p,
          {
            sno: index,
            id: x.id,
            name: x.name,
            state: x.state,
            color: "",
          },
        ]);
      });
  };

  const listArray = ["Home", "Customers"];

  return (
    <>
      <ManinSectionHeader headerName="Add Customer" subMenu={listArray} />
      <form
        autoComplete="off"
        onSubmit={(e: any) => {
          e.preventDefault();
        }}
        className={style["main-wrapper"]}
      >
        {inputArray.map((x: any) => {
          let element = (
            <LabelInput
              key={x.labelName}
              labelName={x.labelName}
              ref={x.name === "city" ? inputRef : null}
              {...x}
              onClick={() => (x.name === "city" ? setRequired(false) : "")}
              onChange={(e: any) =>
                x.name === "city" ? onChangeHandler(e) : null
              }
              onKeyPress={(e: any) => {
                if (e.key === "Enter" && x.name === "city") {
                  if (city.length > 0) {
                    try {
                      inputRef.current.value = city[cityListIndex].name;
                      setCity([]);
                    } catch (ex) {
                      console.log(ex);
                    }
                  }
                }
              }}
              onBlur={(e: any) => {
                setCityListVisibility("none");
                let countFlag = 0;
                if (e.target.value.length > 0 && e.target.name === "city") {
                  setRequired(true);
                  for (let city of citiesList) {
                    if (
                      city.name.toLowerCase() === e.target.value.toLowerCase()
                    ) {
                      countFlag = 1;
                      e.target.value = city.name;
                      return;
                    }
                  }
                  if (countFlag === 0) {
                    e.target.setCustomValidity("please enter valid data");
                  }
                }
              }}
              onKeyDown={(e: any) => {
                return x.name === "mobile"
                  ? e.key === "Tab"
                    ? setRequired(false)
                    : ""
                  : x.name === "city"
                  ? e.key === "ArrowDown"
                    ? arrowDownHandler()
                    : e.key === "ArrowUp"
                    ? arrowUpHandler()
                    : ""
                  : "";
              }}
            />
          );
          return x.name !== "city" ? (
            element
          ) : (
            <div key={x.name} className={style["city-wrapper"]}>
              {element}
              {city.length > 0 ? (
                <div
                  className={style.citylist}
                  style={{ display: cityListVisibility }}
                >
                  {city.map((y) => (
                    <span
                      key={y.id}
                      onMouseDown={() => {
                        inputRef.current.value = y.name;
                      }}
                      style={{ backgroundColor: y.color }}
                      ref={(t) => (listRef.current[y.sno] = t)}
                    >
                      {y.sno + 1 + ". " + y.name + "-" + y.state}
                    </span>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
        <span className={style.termscondition}>
          <input type="checkbox" />
          <span>
            By Registering, you agree to Sahajj+ <u>Terms & Condtion</u>
          </span>
        </span>
        <div>
          <button className={style.buttons} type="submit">
            Save
          </button>
        </div>
      </form>
    </>
  );
}
export default Registration;
