import React from "react";
import Button from "../../../../../common-elements/Button";

const Table = (props: any) => {
  const { productItem } = props.properties;

  const tableHeader = [
    "Category",
    "Subcategory",
    "Product Name",
    "Variant Name1",
    "Variant Name2",
    "Product Name(Hindi)",
    "Variant Name1(Hindi)",
    "Variant Name2(Hindi)",
    "Size",
    "Brand",
    "HSN",
    "S-CGST",
    "IGST",
    "Image",
  ];

  const table = (
    <table>
      <thead>
        <tr>
          {tableHeader.map((header: string) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {productItem.map((row: any) => (
          <tr key={row.Id}>
            <td>{row.Category_Name}</td>
            <td>{row.Subcategory_Name}</td>
            <td>{row.Product_Name}</td>
            <td>{row.Variant_Name}</td>
            <td>{row.Variant_Name_Additional}</td>
            <td>{row.Product_Name_Hin}</td>
            <td>{row.Variant_Name_Hin}</td>
            <td>{row.Variant_Name_Hin_Additional}</td>
            <td>{row.Size}</td>
            <td>{row.Brand}</td>
            <td>{row.HSN}</td>
            <td>{row.SndCgstValue}</td>
            <td>{row.IgstValue}</td>
            <td>{row.Image}</td>
            <td>
              <Button
                name="Edit"
                id={row.Id}
                fontcolor="red"
                data={row}
                width="80"
                onClick={props.editHandler}
              />
            </td>
            <td>
              <Button
                name="Delete"
                id={row.Id}
                fontcolor="red"
                data={row}
                width="80"
                onClick={props.deleteHandler}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return <>{table}</>;
};

export default Table;
