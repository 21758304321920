import React, { memo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

interface types {
  content: string | undefined;
  isDimmerActive: boolean;
  size: any;
}

function LoaderUI(props: types) {
  const { content, isDimmerActive, size } = props;
  const [isType, setType] = useState<boolean>(isDimmerActive);

  // useEffect(() => {
  //   if (type === "dimmer") {
  //     setType(true);
  //   }
  // }, []);

  return (
    <>
      <div>
        {isDimmerActive && (
          <Dimmer active={true}>
            <Loader size={size} active={true} content={content} />
          </Dimmer>
        )}

        {!isDimmerActive && <Loader active={true} content={content} />}
      </div>
    </>
  );
}

export default LoaderUI;
