import { useContext } from "react";
import style from "./OrderInformation.module.css";
import OrderContext from "../../../../data-centre/Order-context";

interface type {
  orderInfo: {
    totalOrders: number;
    notDelivered: number;
    paynotrecieved: number;
    totalRecievedAmt: number;
    cashPaymentAmt: number;
    OnlinePaymentAmt: number;
    totalOrderAmt: number;
    totalProfit: number;
    totalLossAmt: number;
    totalDueAmt: number;
  };
}

function OrderInformation({ orderInfo }: type) {
  const orderCtx = useContext(OrderContext);

  return (
    <div className={style.orderinfo}>
      <div>
        {orderCtx
          .getOrderModuleAuthorization()
          .div.findIndex((x) => x === "hide") <= -1 && (
          <>
            <div>Total Loss Amt</div> <div>{orderInfo.totalLossAmt}</div>
            <div></div>
            <div>{}</div>
            <div></div>
            <div>{}</div>
          </>
        )}
      </div>
      <div>
        {orderCtx
          .getOrderModuleAuthorization()
          .div.findIndex((x) => x === "hide") <= -1 && (
          <>
            <div>Today Profit</div> <div>{orderInfo.totalProfit}</div>
            <div>Total Order Amt</div>
            <div>{orderInfo.totalOrderAmt}</div>
            <div>Total Loss Amt</div>
            <div>{orderInfo.totalLossAmt}</div>
          </>
        )}
      </div>

      <div>
        {orderCtx
          .getOrderModuleAuthorization()
          .div.findIndex((x) => x === "hide") <= -1 && (
          <>
            <div>Total Recieved Amt</div>
            <div>{orderInfo.totalRecievedAmt}</div>
            <div>Offine Amt Rec.</div>
            <div>{orderInfo.cashPaymentAmt}</div>
            <div>Online Amt Rec.</div>
            <div>{orderInfo.OnlinePaymentAmt}</div>
          </>
        )}
      </div>
      <div>
        <div>Today Orders</div> <div>{orderInfo.totalOrders}</div>
        <div>Not Delivered</div>
        <div>{orderInfo.notDelivered}</div>
        <div>Payment Not Recieved</div>
        <div>{orderInfo.paynotrecieved}</div>
      </div>
    </div>
  );
}

export default OrderInformation;
