import { createContext } from "react";
import { userDetail } from "../../Interface/Purchase";

let nonAuthorize: { tabs: string[]; columns: string[]; div: string[] };
let dates: { fromDate: any; endDate: any };
const context = {
  getOrderModuleAuthorization: () => nonAuthorize,
  setOrderModuleAuthorization: (userLogin: userDetail) => {},
  setOrderDate: (dates: { fromDate: any; endDate: any }) => {},
  getOrderDate: () => dates,
};
const OrderContext = createContext(context);

export default OrderContext;
