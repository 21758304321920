import { forwardRef } from "react";
import style from "./ResultPopup.module.css";
interface type {
  list: { name: any; value: any }[];
  value: any;
}
const ResultPopup = forwardRef(({ list, value }: type, ref: any) => {
  return (
    <>
      <div className={style["resultpopup-wrapper"]}>
        {list.map((item: any, index: number) => (
          <div
            ref={(e) => (ref.current[index] = e)}
            onClick={() => value(item.value)}
            className={style.row}
            key={index}
            id={item.value}
          >
            {item.name}
          </div>
        ))}
      </div>
    </>
  );
});

export default ResultPopup;

