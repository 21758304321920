import { useState, useEffect } from "react";
import { Pricing } from "../../../../../Interface/Purchase";
import Accordian from "./Accordian";
import style from "../SetItemPrice.module.css";
import DateTime from "../../../common-utility/DateTime";
import MessageModal from "../../../common-utility/MessageModal";
import { isNumber } from "../../../../../customer-app/utility/validation";

interface type {
  Pricing_id: number;
  Average_SP: number;
  Average_CP: number;
  QtyNewly_added: number;
  _metric: string;
  Profit_margin: number;
  SetPrice_condition: string;
  PriceList_for: string;
  ArchieveStore_id: [number];
  Live_status: [string];
  Purchase_id: [number];
  Store_id: [number];
  Qty_avail: [number];
  Company_id: string;
  Metric_conversion: string;
  Product_id: string;
  Unit_mrp: number;
  PriceList_liveStatus: string;
}

const TableData = ({
  tableHeader,
  index,
  id,
  setRowId,
  color,
  str,
  accordian,
  clickIds,
  isChecked,
  setMessageVisibility,
  exclusionCheckboxState,
  reset,
}: any) => {
  let x: Pricing = str;

  const obj: type = {
    Average_SP: 0,
    Pricing_id: 0,
    QtyNewly_added: 0,
    _metric: "",
    PriceList_for: "",
    Profit_margin: 0,
    SetPrice_condition: "",
    Average_CP: 0,
    ArchieveStore_id: [0],
    Live_status: [""],
    Purchase_id: [0],
    Store_id: [0],
    Qty_avail: [0],
    Company_id: "",
    Metric_conversion: "",
    Product_id: "",
    Unit_mrp: 0,
    PriceList_liveStatus: "",
  } as any;
  const [expectMarginInput, setExpectMarginInput] = useState<any>();
  const [expectSPInput, setExpectSPInput] = useState<any>();
  const [checkboxState, setCheckboxState] = useState<boolean>(false);
  const [checkboxExclude, setCheckboxExclude] = useState<boolean>(false);
  const [promptMessage, setPromptMessage] = useState<string>("");
  const [messageModalVisibility, setMessageModalVisibility] =
    useState<boolean>(false);

  const objHandler = (x: Pricing) => {
    obj.Pricing_id = x.Pricing_id;
    obj.PriceList_for = x.PriceList_for;
    obj.Average_SP = parseFloat(expectSPInput);
    obj.Profit_margin = parseFloat(expectMarginInput);
    obj.SetPrice_condition = checkboxExclude
      ? "Price Exclude"
      : "Price Include";
    obj.Company_id = x.Company_id;
    obj.Average_CP = x.ExpAverage_CP;
    obj.ArchieveStore_id = x.ArchieveStore_id;
    obj.Purchase_id = x.Purchase_id;
    obj.Store_id = x.Store_id;
    obj.Qty_avail = x.Qty_avail;
    obj.QtyNewly_added = x.QtyNewly_added;
    obj._metric = x._metric;
    obj.Metric_conversion = x.Metric_conversion;
    obj.Unit_mrp = x.Unit_mrp;
    obj.Product_id = x.Product_id;
    obj.PriceList_liveStatus = "Live";
    x.Live_status.map((x, index: number) => (obj.Live_status[index] = "Live"));
    // for (let item in obj) {
    //   console.log(`${item}:${(obj as any)[item]}`);
    // }
  };

  useEffect(() => {
    setExpectSPInput(
      (x.Profit_margin * x.ExpAverage_CP) / 100 + x.ExpAverage_CP
    );
    setExpectMarginInput(x.Profit_margin);
  }, [x.Profit_margin]);

  useEffect(() => {
    setCheckboxState(isChecked);
    objHandler(x);
    return isChecked === true
      ? clickIds(x.Pricing_id, obj)
      : clickIds(-x.Pricing_id, obj);
  }, [isChecked]);

  useEffect(() => {
    setCheckboxState(false);
    setCheckboxExclude(false);
    //expectMarginInput("");
  }, [reset]);

  const expectedMarginHandler = (value: any) => {
    setExpectMarginInput(value);
    let val = !isNaN(parseFloat(value))
      ? ((x.ExpAverage_CP * parseFloat(value)) / 100 + x.ExpAverage_CP).toFixed(
          2
        )
      : 0;
    setExpectSPInput((y: any) => (y = val));
  };

  const expectedSPHandler = (value: any) => {
    setExpectSPInput(value);
    let val = !isNaN(parseFloat(value))
      ? (
          ((parseFloat(value) - x.ExpAverage_CP) * 100) /
          x.ExpAverage_CP
        ).toFixed(2)
      : 0;
    setExpectMarginInput((y: any) => (y = val));
  };

  const promptMessageHandler = (message: string) => {
    setPromptMessage(message);
    setMessageModalVisibility(true);
  };

  return (
    <>
      {messageModalVisibility && (
        <MessageModal
          message={promptMessage}
          close={() => {
            setMessageModalVisibility(false);
          }}
        />
      )}
      <div
        className={style.tableData}
        key={id}
        id={id}
        style={{ backgroundColor: color }}
        onClick={() => {
          setRowId(id);
        }}
      >
        <div style={{ width: tableHeader[0].width }}>{index}</div>
        <div style={{ width: tableHeader[1].width, textAlign: "left" }}>
          {x.Pricing_id}
        </div>
        <div
          style={{
            width: tableHeader[2].width,
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          {x.Product_name}
        </div>
        <div
          style={{
            width: tableHeader[3].width,
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          {x.Variant_name}
        </div>
        <div style={{ width: tableHeader[4].width }}>
          {"01-01-1900" === DateTime({ value: x.Set_time, required: "date" })
            ? "Not Set"
            : DateTime({ value: x.Set_time, required: "date" })}
        </div>
        <div style={{ width: tableHeader[5].width }}>
          {x.Unit_mrp === 0 ? "-" : "Rs" + x.Unit_mrp}
        </div>
        <div style={{ width: tableHeader[6].width }}>
          <div>
            {x.PriceList_liveStatus === "None" ? "-" : "Rs" + x.Average_CP}
          </div>
          <div style={{ width: "40%" }}>
            {x.PriceList_liveStatus === "None"
              ? "-"
              : !isNaN(x.Profit_margin)
              ? x.Profit_margin.toFixed(2)
              : ""}
          </div>
          <div
            style={{
              // color: isNumber(x.Average_SP) ? "purple" : "",
              backgroundColor: "#1d1160",
              color: "white",
              fontWeight: 600,
            }}
          >
            {x.PriceList_liveStatus === "None"
              ? "-"
              : !isNaN(x.Average_SP)
              ? "Rs " + x.Average_SP.toFixed(2)
              : ""}
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ width: tableHeader[7].width }}
        >
          <div>{x.ExpAverage_CP}</div>
          <div>
            <input
              style={{ width: "100%" }}
              disabled={checkboxExclude}
              onChange={(e: any) => expectedMarginHandler(e.target.value)}
              type="number"
              name="exp-margin"
              value={expectMarginInput}
            />
          </div>
          <div>
            <input
              style={{ width: "100%" }}
              disabled={checkboxExclude}
              onChange={(e: any) => {
                if (e.target.value > x.Unit_mrp && x.Unit_mrp > 0) {
                  promptMessageHandler(
                    "Selling Price shouldn't be greater than MRP"
                  );
                  return;
                }
                expectedSPHandler(e.target.value);
              }}
              type="number"
              name="exp-sp"
              value={expectSPInput}
            />
          </div>
        </div>
        <div
          style={{
            width: tableHeader[8].width,
            justifyContent: "center",
            alignItems: "center",
            color: x.QtyNewly_added !== 0 ? "red" : "",
          }}
        >
          {x.QtyNewly_added !== 0 ? `${x.QtyNewly_added}${x._metric}` : "-"}
        </div>
        <div
          style={{
            width: tableHeader[9].width,
            color: "purple",
            fontWeight: 600,
          }}
        >
          {x.Metric_conversion.split(",").splice(0, 2).join("") +
            "=>" +
            x.Metric_conversion.split(",").splice(2, 2).join("")}
        </div>
        <div style={{ width: tableHeader[10].width }}>{x.ItemSearch_mode}</div>
        <div
          style={{
            width: tableHeader[11].width,
            color: x.PriceList_liveStatus === "Live" ? "green" : "",
            fontWeight: 600,
          }}
        >
          {x.PriceList_liveStatus}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ width: tableHeader[12].width }}
        >
          <input
            className={style.checkbox}
            name="checkboxExclude"
            type="checkbox"
            checked={
              x.Average_CP === 0 || x.QtyNewly_added === 0
                ? false
                : checkboxExclude
            }
            disabled={
              x.Average_CP === 0 || x.QtyNewly_added === 0 ? true : false
            }
            onChange={() => {
              setCheckboxExclude((x) => (x = !x));
              if (checkboxExclude === false) {
                expectedMarginHandler(x.Profit_margin);
              }
            }}
          />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ width: tableHeader[13].width }}
        >
          <input
            checked={checkboxState}
            onChange={() => {
              if (isChecked !== true) {
                setCheckboxState((x: any) => (x = !checkboxState));
                objHandler(x);
                return checkboxState === false
                  ? clickIds(x.Pricing_id, obj)
                  : clickIds(-x.Pricing_id, obj);
              } else {
                setMessageVisibility(true);
              }
            }}
            className={style.checkbox}
            name="checkbox"
            type="checkbox"
          />
        </div>
      </div>
      <div style={{ display: accordian === false ? "none" : "" }}>
        {<Accordian data={x} />}
      </div>
    </>
  );
};

export default TableData;
