import { useEffect, useState } from "react";
import Modal from "../../common-utility/Modal";
import DeletePurchase from "../action-components/DeletePurchase";
import ListPurchase from "./lower-components/ListPurchase";
import UpdatePurchase from "./lower-components/UpdatePurchase";

function LowerSection(props: any) {
  const [data, setData] = useState({});
  const [deleteVisibility, setDeleteVisibility] = useState<boolean>(false);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [list, setList] = useState<any>(props.filterList);
  const { catgid, subcatgid, dateFrom, dateTo, time } = props.filterList;
  useEffect(() => {
    setList(props.filterList);
  }, [time]);

  const actionHandler = (type: string, data: any) => {
    if (type === "edit") {
      setData(data);
      setModalVisibility(true);
      setDeleteVisibility(false);
    } else if (type === "delete") {
      setData(data);
      setModalVisibility(false);
      setDeleteVisibility(true);
    } else if (type === "postDel") {
      console.log(type);
      setList({
        catgid: catgid,
        subcatgid: subcatgid,
        dateFrom: dateFrom,
        dateTo: dateTo,
        time: new Date().getTime(),
      });
      setModalVisibility(false);
      setDeleteVisibility(false);
    } else {
      setData({});
      setModalVisibility(false);
      setDeleteVisibility(false);
    }
  };

  return (
    <>
      <ListPurchase action={actionHandler} filterList={list} />
      {modalVisibility && (
        <Modal>
          <UpdatePurchase action={actionHandler} data={data} />
        </Modal>
      )}
      {deleteVisibility && <DeletePurchase action={actionHandler} Id={data} />}
    </>
  );
}

export default LowerSection;
