import React from "react";
import { useEffect } from "react";
import Dropdown from "../../../common-utility/Dropdown";
import query from "../../../../data-centre/Queries";
import StringCaseUI from "../../../common-utility/StringCaseUI";
import style from "./SubmitForm.module.css";
import { useState } from "react";
import Droplist from "../../../common-utility/Droplist";

const SubmitForm = ({
  product,
  type,
  loader,
  message,
  catgList,
  cancel,
}: any) => {
  const [subCatgList, setSubcatgList] = useState([]);
  const [subCatgListVisibility, setSubCatgListVisibility] =
    useState<boolean>(false);
  const [dropdownListVisibility, setDropdownListVisibility] =
    useState<boolean>(false);
  const [scgstData, setSCGstData] = useState<any>([]);
  const [igstData, setIGstData] = useState<any>([]);
  const [scgstValue, setSCgstValue] = useState<string>(product.SndCgstCode);
  const [igstValue, setIgstValue] = useState<string>(product.IgstCode);

  let variantsize: any = product.Variant_size_Available;
  let submit_button_name = "Update Product";

  if (type === "add") {
    variantsize = "";
    submit_button_name = "Add Product";
  }

  useEffect(() => {
    if (type === "edit") {
      setSubCatgListVisibility(true);
    } else if (type === "add") {
      setDropdownListVisibility(true);
    }
  }, []);

  useEffect(() => {
    gstDataHandler();
  }, []);

  // useEffect(()=>{setIgstValue()},[igstValue]);
  // useEffect(()=>{setSCgstValue()},[scgstValue]);

  const gstDataHandler = async () => {
    await query.GstRules.list()
      .then((response) => {
        if (response.statusText === "OK") {
          let scgst: any = [];
          let igst: any = [];
          response.data.map((item: any) =>
            item.Gst_name !== "IGST"
              ? (scgst = [...scgst, { name: item._tax, value: item.Gst_id }])
              : (igst = [...igst, { name: item._tax, value: item.Gst_id }])
          );
          setSCGstData((x: any) => (x = [...scgst]));
          setIGstData((x: any) => (x = [...igst]));
        } else {
          message("not found gst data");
        }
      })
      .catch((error) => message(error));
  };

  const submitHndler = (event: any) => {
    event.preventDefault();
    if (event.target["submit"].name === "submit") {
      product.Product_Name = StringCaseUI(event.target["Product Name"].value);
      product.Variant_Name = StringCaseUI(event.target["Variant Name"].value);
      product.Variant_Name_Additional = StringCaseUI(
        event.target["Variant Name Additional"].value
      );
      product.Product_Name_Hin = event.target["Product Name(Hindi)"].value;
      product.Variant_Name_Hin = event.target["Variant Name(Hindi)"].value;
      product.Variant_Name_Hin_Additional =
        event.target["Variant Name Additonal(Hindi)"].value;
      product.Size = StringCaseUI(event.target["Size"].value);
      product.Brand = StringCaseUI(event.target["Brand"].value);
      product.Image = StringCaseUI(event.target["Image"].value);
      if (type === "add") {
        product.Catg_Id = event.target["catgdropdownlist"].value;
        product.SubCatg_Id = parseInt(
          event.target["subcatgdropdownlist"].value
        );
        product.HSN = event.target["HSN"].value;
        product.SndCgstCode = scgstValue === "" ? null : parseFloat(scgstValue);
        product.IgstCode = igstValue === "" ? null : parseFloat(igstValue);
        addProduct(product);
        event.target["catgdropdownlist"].value = "select";
        event.target["subcatgdropdownlist"].value = "select";
        event.target["Product Name"].value = "";
        event.target["Variant Name"].value = "";
        event.target["Variant Name Additional"].value = "";
        event.target["Product Name(Hindi)"].value = "";
        event.target["Variant Name(Hindi)"].value = "";
        event.target["Variant Name Additonal(Hindi)"].value = "";
        event.target["Size"].value = "";
        event.target["Brand"].value = "";
        event.target["Image"].value = "";
        event.target["HSN"].value = "";
        event.target["SndCgstCode"].value = "";
        event.target["IgstCode"].value = "";
      } else if (type === "edit") {
        editProduct(product);
      }
    }
  };

  const addProduct = async (product: any) => {
    loader(true);
    await query.Product.add(product).then((response) => {
      console.log("products: ", response);
      if (response.statusText === "OK") {
        loader(false);
        message("Product Added Successfully!!!");
      } else {
        message("there is some error!!");
      }
    });
  };

  const editProduct = async (product: any) => {
    loader(true);
    product.SndCgstCode = scgstValue !== "" ? parseFloat(scgstValue) : null;
    product.IgstCode = igstValue !== "" ? parseFloat(igstValue) : null;
    await query.Product.edit(product.Id, product).then((response) => {
      if (response.statusText === "OK") {
        loader(false);
        message("Product updated successfully!!!");
      } else {
        message("some error occurred!!");
      }
      return;
    });
  };

  const catgListHandler = (value: any) => {
    // console.log("submitform: ", value);
    if (value === "select") {
      setSubCatgListVisibility(false);
      return;
    }
    query.SubCategory.detail(value, "catg").then((response) =>
      setSubcatgList(response.data)
    );
    setSubCatgListVisibility(true);
  };

  const subcatgListHandler = (value: any) => {
    // setSubCatgSelectedCatgValue(value);
  };

  return (
    <div>
      <form onSubmit={submitHndler}>
        <table>
          <tbody>
            <tr>
              <th>Category</th>
              <td>
                {dropdownListVisibility && (
                  <Dropdown
                    onClick={catgListHandler}
                    list={catgList}
                    required={true}
                    initialValue="0"
                    name="catgdropdownlist"
                    clicked={true}
                    disable={false}
                  />
                )}
                {!dropdownListVisibility && (
                  <input
                    disabled={true}
                    defaultValue={product.Category_Name}
                    required={true}
                    type="string"
                    name={"catgdropdownlist"}
                  />
                )}
              </td>
            </tr>
            {subCatgListVisibility && (
              <tr>
                <th>Subcategory</th>
                <td>
                  {dropdownListVisibility && (
                    <Dropdown
                      onClick={subcatgListHandler}
                      list={subCatgList}
                      required={true}
                      initialValue="0"
                      name="subcatgdropdownlist"
                      clicked={true}
                      disable={false}
                    />
                  )}
                  {!dropdownListVisibility && (
                    <input
                      disabled={true}
                      defaultValue={product.Subcategory_Name}
                      required={true}
                      type="string"
                      name={"subcatgdropdownlist"}
                    />
                  )}
                </td>
              </tr>
            )}
            <tr key={2}>
              <td>Product Name</td>
              <td>
                <input
                  defaultValue={product.Product_Name}
                  required={true}
                  type="string"
                  name={"Product Name"}
                />
              </td>
            </tr>
            <tr key={3}>
              <td>Variant Name</td>
              <td>
                <input
                  defaultValue={product.Variant_Name}
                  required={true}
                  type="string"
                  name={"Variant Name"}
                />
              </td>
            </tr>
            <tr key={4}>
              <td>Variant Name Additional</td>
              <td>
                <input
                  defaultValue={product.Variant_Name_Additional}
                  required={false}
                  type="string"
                  name={"Variant Name Additional"}
                />
              </td>
            </tr>
            <tr key={5}>
              <td>Product Name(Hindi)</td>
              <td>
                <input
                  defaultValue={product.Product_Name_Hin}
                  required={true}
                  type="string"
                  name="Product Name(Hindi)"
                />
              </td>
            </tr>
            <tr key={6}>
              <td>Variant Name(Hindi)</td>
              <td>
                <input
                  defaultValue={product.Variant_Name_Hin}
                  required={true}
                  type="string"
                  name={"Variant Name(Hindi)"}
                />
              </td>
            </tr>
            <tr key={7}>
              <td>Variant Name Additonal(Hindi)</td>
              <td>
                <input
                  defaultValue={product.Variant_Name_Hin_Additional}
                  required={false}
                  type="string"
                  name={"Variant Name Additonal(Hindi)"}
                />
              </td>
            </tr>
            <tr key={8}>
              <td>Size</td>
              <td>
                <input
                  defaultValue={product.Size}
                  required={false}
                  type="string"
                  name={"Size"}
                />
              </td>
            </tr>

            <tr key={9}>
              <td>Brand</td>
              <td>
                <input
                  defaultValue={product.Brand}
                  required={false}
                  type="string"
                  name={"Brand"}
                />
              </td>
            </tr>
            <tr key={10}>
              <td>HSN</td>
              <td>
                <input
                  defaultValue={product.HSN === null ? "" : product.HSN}
                  required={false}
                  type="string"
                  name="HSN"
                />
              </td>
            </tr>
            <tr key={11}>
              <td>S-CGST</td>
              <td>
                <Droplist
                  value={scgstValue}
                  name="SndCgstCode"
                  list={scgstData}
                  onChange={(val: any) => setSCgstValue(val)}
                />
              </td>
            </tr>
            <tr key={12}>
              <td>IGST</td>
              <td>
                <Droplist
                  value={igstValue}
                  name="IgstCode"
                  list={igstData}
                  onChange={(val: any) => setIgstValue(val)}
                />
              </td>
            </tr>
            <tr key={13}>
              <td>Image</td>
              <td>
                <input
                  defaultValue={product.Image}
                  required={false}
                  typeof="string"
                  name={"Image"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <button name="submit" type="submit">
                  {submit_button_name}
                </button>
              </td>
              <td>
                <button onClick={() => cancel(false)} type="button">
                  Cancel
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default SubmitForm;
