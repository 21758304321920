import style from "./Navigation.module.css";
import { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { MdManageAccounts } from "react-icons/md";

function Navigation(props: any) {
  console.log("navigation width left 1: " + props.left);

  const [navigationMenuWidth, setNavigationMenuWidth] = useState<string>(
    props.left
  );
  const [backdropWidth, setBackdropWidth] = useState<string>(
    props.backdropWidth
  );

  useEffect(() => {
    setNavigationMenuWidth(props.left);
    setBackdropWidth(props.backdropWidth);
  }, [props.left]);

  console.log("navigation width state: 2: " + navigationMenuWidth);

  return (
    <>
      {console.log("return navigate state 4: " + navigationMenuWidth)}
      <div className={style["backdrop"]} style={{ width: backdropWidth }}></div>
      <div
        id={style["navigation-menus-wrapper-background-div"]}
        style={{ left: navigationMenuWidth }}
      >
        {/* <a href="#">home</a> <br />
        <a href="#">about</a> <br />
        <a href="#">contact</a> <br />
        <a href="#">info</a> */}
        <ul className={style["navigation-menus-wrapper-ul"]}>
          <li className={style["user-image-name-li"]}>
            <div>
              <IoCloseOutline
                onClick={() => {
                  props.setleft();
                }}
                className={style["navigation-menus-cross"]}
              />
            </div>
            <div className={style["user-image-name-li-div"]}>
              <div className={style["profile-pic-div"]}>
                <MdManageAccounts className={style["profile-pic"]} />
              </div>
              <div className={style["name-mob-div"]}>
                <p className={style["name"]}>Vaibhav Dixit</p>
                <p className={style["mobile"]}>7983185247</p>
              </div>
            </div>
          </li>
          <li>Home</li>
          <li>Profile</li>
          <li>Cart</li>
          <li>Orders</li>
          <li>Invoices</li>
          <li>Slots Availability</li>
          <li>Track Order</li>
          <li>Complaints</li>
          <li>Logout</li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
